import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramBuffers, ProgramInfo, UniformLocations } from './GPURunner';
import { PageChange, PageChangeEntry } from './PageChange';

export interface DryUniformLocations extends UniformLocations {
    factor: WebGLUniformLocation,
    pageAgeTexNum: WebGLUniformLocation,
    pageDepthTexNum: WebGLUniformLocation,
    pageVisLatentCTexNum: WebGLUniformLocation,
    pageVisLatentMTexNum: WebGLUniformLocation,
    pageDryLatentCTexNum: WebGLUniformLocation,
    pageDryLatentMTexNum: WebGLUniformLocation,
    pageWetLatentCTexNum: WebGLUniformLocation,
    pageWetLatentMTexNum: WebGLUniformLocation,
};

export interface DryProgramInfo extends ProgramInfo {
    uniformLocations: DryUniformLocations
};

export interface DryProgramBuffers extends ProgramBuffers {
    decrementedAgeTexture: WebGLTexture | null,
    decrementedAgeFramebuffer: WebGLFramebuffer | null,
    finalAgeTexture: WebGLTexture | null,
    finalAgeFramebuffer: WebGLFramebuffer | null,
    finalDepthTexture: WebGLTexture | null,
    finalDepthFramebuffer: WebGLFramebuffer | null,
    finalDryLatentCTexture: WebGLTexture | null,
    finalDryLatentMTexture: WebGLTexture | null,
    finalWetLatentCTexture: WebGLTexture | null,
    finalWetLatentMTexture: WebGLTexture | null,

};

export class DryPageChange extends PageChange {
    
    static startupProgram: DryProgramInfo = {uniformLocations:{}} as DryProgramInfo;
    static processProgram: DryProgramInfo = {uniformLocations:{}} as DryProgramInfo;
    static finishProgram: DryProgramInfo = {uniformLocations:{}} as DryProgramInfo;

    static dryBuffers: DryProgramBuffers | undefined;

static fsSourceStartup = `#version 300 es
#line 32
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
uniform highp usampler2D u_pageAgeTexNum;
layout(location = 0) out highp uint outputAge;

void main() {
    outputAge = texture(u_pageAgeTexNum, v_texCoordRendered).r;
    if (outputAge > 1u) {
        outputAge --;
    }
}
`;

static fsSourceProcess = `#version 300 es
#line 61
uniform highp vec2 u_resolution;
uniform highp float u_factor;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
uniform highp usampler2D u_pageAgeTexNum;
uniform highp usampler2D u_pageDepthTexNum;
uniform highp usampler2D u_pageWetLatentCTexNum;
uniform highp usampler2D u_pageWetLatentMTexNum;
uniform highp usampler2D u_pageDryLatentCTexNum;
uniform highp usampler2D u_pageDryLatentMTexNum;
uniform highp usampler2D u_pageVisLatentCTexNum;
uniform highp usampler2D u_pageVisLatentMTexNum;
layout(location = 0) out highp uint outputAge;
layout(location = 1) out highp uint outputDepth;
layout(location = 2) out highp uvec4 outputWetLatentC;
layout(location = 3) out highp uvec4 outputWetLatentM;
layout(location = 4) out highp uvec4 outputDryLatentC;
layout(location = 5) out highp uvec4 outputDryLatentM;
//highp uint paintDepthScale = 4u;
//highp uint maxCoronaDepth = 256u * paintDepthScale;
highp uint maxPaintDepth = 10u * 256u * 4u; 

void main() {
    highp uint inputAge = texture(u_pageAgeTexNum, v_texCoordRendered).r;
    outputAge = inputAge;
    outputDepth = texture(u_pageDepthTexNum, v_texCoordRendered).r;
    outputWetLatentC = texture(u_pageWetLatentCTexNum, v_texCoordRendered);
    outputWetLatentM = texture(u_pageWetLatentMTexNum, v_texCoordRendered);
    outputDryLatentC = texture(u_pageDryLatentCTexNum, v_texCoordRendered);
    outputDryLatentM = texture(u_pageDryLatentMTexNum, v_texCoordRendered);
    if (inputAge == 1u) {
        outputDryLatentC = texture(u_pageVisLatentCTexNum, v_texCoordRendered);
        outputDryLatentM = texture(u_pageVisLatentMTexNum, v_texCoordRendered);
        highp uint finalDepth = outputDepth;
        if (finalDepth > maxPaintDepth) {
            finalDepth = maxPaintDepth;
        }
        highp uint reduce = max(1u, uint(floor(float(finalDepth) * u_factor)));
        if (reduce > finalDepth) {
            finalDepth = 0u;
        } else {
            finalDepth -= reduce;
        }
        outputDepth = finalDepth;
        if (finalDepth == 0u) {
            outputWetLatentC = uvec4(0u, 0u, 0u, 0u);
            outputWetLatentM = uvec4(0u, 0u, 0u, 1u);

            outputAge = 0u;
        }
    }
}
`;


    static getPrograms(): Array<[string, ProgramInfo]> {
        //return [[DryPageChange.fsSourceInit, DryPageChange.initProgram], [DryPageChange.fsSourceProcess, DryPageChange.processProgram], [DryPageChange.fsSourceFinish, DryPageChange.finishProgram]];
        return [[DryPageChange.fsSourceStartup, DryPageChange.startupProgram], [DryPageChange.fsSourceProcess, DryPageChange.processProgram]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        DryPageChange.startupProgram.uniformLocations = {} as DryUniformLocations;
        DryPageChange.addStartupProgramLocations(gl, DryPageChange.startupProgram);

        DryPageChange.processProgram.uniformLocations = {} as DryUniformLocations;
        DryPageChange.addProcessProgramLocations(gl, DryPageChange.processProgram);

        DryPageChange.finishProgram.uniformLocations = {} as DryUniformLocations;
        DryPageChange.addFinishProgramLocations(gl, DryPageChange.finishProgram);

    }

    static addStartupProgramLocations(gl: WebGL2RenderingContext, programInfo: DryProgramInfo) {
        programInfo.uniformLocations.pageAgeTexNum = gl.getUniformLocation(programInfo.program, 'u_pageAgeTexNum')!;
        PageChange.addProgramLocations(gl, programInfo);
    }

    static addProcessProgramLocations(gl: WebGL2RenderingContext, programInfo: DryProgramInfo) {
        programInfo.uniformLocations.pageAgeTexNum = gl.getUniformLocation(programInfo.program, 'u_pageAgeTexNum')!;
        programInfo.uniformLocations.pageDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_pageDepthTexNum')!;
        programInfo.uniformLocations.pageWetLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_pageWetLatentCTexNum')!;
        programInfo.uniformLocations.pageWetLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_pageWetLatentMTexNum')!;
        programInfo.uniformLocations.pageDryLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_pageDryLatentCTexNum')!;
        programInfo.uniformLocations.pageDryLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_pageDryLatentMTexNum')!;
        programInfo.uniformLocations.pageVisLatentCTexNum = gl.getUniformLocation(programInfo.program, 'u_pageVisLatentCTexNum')!;
        programInfo.uniformLocations.pageVisLatentMTexNum = gl.getUniformLocation(programInfo.program, 'u_pageVisLatentMTexNum')!;

        programInfo.uniformLocations.factor = gl.getUniformLocation(programInfo.program, 'u_factor')!;

        PageChange.addProgramLocations(gl, programInfo);
    }
    static addFinishProgramLocations(gl: WebGL2RenderingContext, programInfo: DryProgramInfo) {
        // programInfo.uniformLocations.pageAgeTexNum = gl.getUniformLocation(programInfo.program, 'u_pageAgeTexNum')!;

        // PageChange.addProgramLocations(gl, programInfo);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        DryPageChange.dryBuffers = {} as DryProgramBuffers;
        const depthTex = GPURunner.initTexture(gl, 'depthTex');
        const decAgeTex = GPURunner.initTexture(gl, 'decAgeTex');
        const finalAgeTex = GPURunner.initTexture(gl, 'finalAgeTex');
        const finalWetLatentCTex = GPURunner.initTexture(gl, 'finalWetLatentCTex');
        const finalWetLatentMTex = GPURunner.initTexture(gl, 'finalWetLatentMTex');
        const finalDryLatentCTex = GPURunner.initTexture(gl, 'finalDryLatentCTex');
        const finalDryLatentMTex = GPURunner.initTexture(gl, 'finalDryLatentMTex');

        DryPageChange.dryBuffers!.decrementedAgeTexture = decAgeTex;
        DryPageChange.dryBuffers!.decrementedAgeFramebuffer = GPURunner.initTextureFramebuffer(gl, 'decrementedAgeFramebuffer', decAgeTex, undefined);
        DryPageChange.dryBuffers!.finalDepthTexture = depthTex;
        DryPageChange.dryBuffers!.finalDepthFramebuffer = GPURunner.initTextureFramebuffer(gl, 'finalDepthFramebuffer', depthTex, undefined);
        DryPageChange.dryBuffers!.finalAgeTexture = finalAgeTex;
        DryPageChange.dryBuffers!.finalWetLatentCTexture = finalWetLatentCTex;
        DryPageChange.dryBuffers!.finalWetLatentMTexture = finalWetLatentMTex;
        DryPageChange.dryBuffers!.finalDryLatentCTexture = finalDryLatentCTex;
        DryPageChange.dryBuffers!.finalDryLatentMTexture = finalDryLatentMTex;
        DryPageChange.dryBuffers!.finalAgeFramebuffer = GPURunner.initTextureFramebuffer(gl, 'finalAgeFramebuffer', finalAgeTex, depthTex, finalWetLatentCTex, finalWetLatentMTex, finalDryLatentCTex, finalDryLatentMTex);

        DryPageChange.addPermanentStorage(gl, DryPageChange.dryBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: DryProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        PageChange.addPermanentStorage(gl, buffers, textureCallback);
        if (DryPageChange.dryBuffers! === undefined) {
        }

        //buffers.kmLookupTexture = DryPageChange.dryBuffers!.kmLookupTexture;
    }

    static ageTexNum = 0;
    static depthTexNum = 1;
    static dryLatentCTexNum = 2;
    static dryLatentMTexNum = 3;
    static wetLatentCTexNum = 4;
    static wetLatentMTexNum = 5;
    static visLatentCTexNum = 6;
    static visLatentMTexNum = 7;

    factor: number;

    constructor(page: Page, factor: number) {
        super(page);
        this.factor = factor;
    }
    override setViewport(): void {
        // set the resolution
        let gl = GPURunner.gl!;
        let width = this.page.cellsWidth;
        let height = this.page.cellsHeight;
        gl.canvas.width = width;
        gl.canvas.height = height;
        gl.viewport(0, 0, width, height);
    }
    override preparePageForStartup(): void {
        GPURunner.initDryPageChange(this.page, this);
    }
    override applyStartupToPage(): void {
    }
    override preparePageForProcess(): void {
    }
    override applyProcessToPage(): void {
    }
    override preparePageForFinish(): void {
    }
    override applyFinishToPage(): void {
        GPURunner.applyDryPageChange(this.page, this);
    }

    override selectStartupProgram(): DryProgramInfo {
        let gl = GPURunner.gl!;
        let pi = DryPageChange.startupProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeStartupProgram(pi: DryProgramInfo) {
        let gl = GPURunner.gl!;
        let buffers = DryPageChange.dryBuffers!;

        if (GPURunner.doInitializeProgram(pi, 'dry')) {

            gl.uniform1i(pi.uniformLocations.pageAgeTexNum, DryPageChange.ageTexNum);
        }
    }
    override specializeStartupRoutine(pi: DryProgramInfo) {
        let gl = GPURunner.gl!;
        let buffers = DryPageChange.dryBuffers!;

        // set the resolution
        let width = this.page.cellsWidth;
        let height = this.page.cellsHeight;

        gl.bindTexture(gl.TEXTURE_2D, buffers.decrementedAgeTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI,
            width, height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_BYTE, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.finalAgeTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R8UI,
            width, height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_BYTE, null);
    }

    override runStartupRoutine(pi: DryProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = DryPageChange.dryBuffers!;
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.ageTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageWetAgeTexture);
        gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.decrementedAgeFramebuffer);
        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    }

    override finalizeStartup(pi: DryProgramInfo): void {
    }

    override selectProcessProgram(): DryProgramInfo {
        let gl = GPURunner.gl!;
        let pi = DryPageChange.processProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeProcessProgram(pi: DryProgramInfo): void {
        let buffers = DryPageChange.dryBuffers!;
        let gl = GPURunner.gl!;
        if (GPURunner.doInitializeProgram(pi, 'dry')) {
            gl.uniform1i(pi.uniformLocations.pageAgeTexNum, DryPageChange.ageTexNum);
            gl.uniform1i(pi.uniformLocations.pageDepthTexNum, DryPageChange.depthTexNum);
            gl.uniform1i(pi.uniformLocations.pageWetLatentCTexNum, DryPageChange.wetLatentCTexNum);
            gl.uniform1i(pi.uniformLocations.pageWetLatentMTexNum, DryPageChange.wetLatentMTexNum);
            gl.uniform1i(pi.uniformLocations.pageDryLatentCTexNum, DryPageChange.dryLatentCTexNum);
            gl.uniform1i(pi.uniformLocations.pageDryLatentMTexNum, DryPageChange.dryLatentMTexNum);
            gl.uniform1i(pi.uniformLocations.pageVisLatentCTexNum, DryPageChange.visLatentCTexNum);
            gl.uniform1i(pi.uniformLocations.pageVisLatentMTexNum, DryPageChange.visLatentMTexNum);
            gl.uniform1f(pi.uniformLocations.factor, this.factor);
        }
    }

    override specializeProcessRoutine(pi: DryProgramInfo): void {
        let buffers = DryPageChange.dryBuffers!;
        let gl = GPURunner.gl!;

        let width = this.page.cellsWidth;
        let height = this.page.cellsHeight;

        gl.bindTexture(gl.TEXTURE_2D, buffers.finalDepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            width, height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.finalWetLatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            width, height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.finalWetLatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            width, height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.finalDryLatentCTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            width, height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.finalDryLatentMTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            width, height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);
    
    
    }


    override runProcessRoutine(pi: DryProgramInfo): void {
        let buffers = DryPageChange.dryBuffers!;
        let gl = GPURunner.gl!;

        let width = this.page.cellsWidth;
        let height = this.page.cellsHeight;

        gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.finalAgeFramebuffer);
        gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1, gl.COLOR_ATTACHMENT2, gl.COLOR_ATTACHMENT3, gl.COLOR_ATTACHMENT4, gl.COLOR_ATTACHMENT5]);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.ageTexNum);
        gl.bindTexture(gl.TEXTURE_2D, buffers.decrementedAgeTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.depthTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageWetDepthTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.dryLatentCTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageDryLatentCTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.dryLatentMTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageDryLatentMTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.wetLatentCTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageWetLatentCTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.wetLatentMTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageWetLatentMTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.visLatentCTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageVisibleLatentCTexture);
        gl.activeTexture(gl.TEXTURE0 + DryPageChange.visLatentMTexNum);
        gl.bindTexture(gl.TEXTURE_2D, this.pageVisibleLatentMTexture);

        gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

        let blitReadX0 = 0;
        let blitReadY0 = 0;
        let blitReadX1 = this.page.cellsWidth;
        let blitReadY1 = this.page.cellsHeight;
        let blitDrawX0 = 0;
        let blitDrawY0 = 0;
        let blitDrawX1 = this.page.cellsWidth;
        let blitDrawY1 = this.page.cellsHeight;

        // put the combined age into the buffer
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, buffers.finalAgeFramebuffer);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetAgeFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, buffers.finalDepthFramebuffer);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetDepthFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        let fbTemp = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.finalWetLatentCTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetLatentCFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.finalWetLatentMTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageWetLatentMFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.finalDryLatentCTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageDryLatentCFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.finalDryLatentMTexture, 0);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, this.pageDryLatentMFramebufferW);
        gl.blitFramebuffer(blitReadX0, blitReadY0, blitReadX1, blitReadY1, blitDrawX0, blitDrawY0, blitDrawX1, blitDrawY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);


        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fbTemp);

        

    }

    override finalizeProcess(pi: DryProgramInfo): void {
    }

    override selectFinishProgram(): DryProgramInfo {
        return undefined as any as DryProgramInfo;
    }

    override initializeFinishProgram(pi: DryProgramInfo): void {
    }
    override specializeFinishRoutine(pi: DryProgramInfo): void {
    }
    override runFinishRoutine(pi: DryProgramInfo): void {
    }
    override finalizeFinish(pi: DryProgramInfo): void {
    }
   

}

