import React, { Component } from 'react';
import { App } from '../App'
import {CloseButton} from './iconButton';
import FingerTip from './smash/fingertip';
import Toggle from 'react-toggle'
import { Link, createSearchParams } from 'react-router-dom';
import { Footer } from '../smesshy';
import Smessage from './smessage';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import { ArtistProfile, PaintingInfo, SmesshyUserPreferences, UserRewardSummary } from '../storageManager';
import { JsxElement, UserPreferences } from 'typescript';
import { SmesshySubButton } from './smesshyButton';
import GamePage from './gamePage';
import PaintingFrame from './paintingFrame';
import { tutorialKeys } from './tutorialStep';
import Env from '../envInst';

export interface SettingsPageState extends SmesshyCommonState {
    useMouse: boolean;
    showSize: boolean;
    hapticButton: boolean;
    allPaintingsPublic: boolean;
    autoAcceptFollows: boolean;
    notifications: Map<string, boolean>;
    notificationsPossible: boolean;
    notificationsAllowed: boolean;
    notificationsSubscribed: boolean;
    accountType: string | undefined;
    accountName: string | undefined;
    selfPortraitId: string | undefined;
    selfPortraitUrl: string | undefined;
    paintings: number;
    rewardSummary: UserRewardSummary | undefined;
    useGPU: boolean;
}
export interface SettingsPageProps extends SmesshyCommonProps {
    KnownUseMouse: boolean;
    KnownShowSize: boolean;
    KnownHapticButton: boolean;
    NewSelfPortraitId?: string;
}

class SettingsPage extends SmesshyCommon(Component<SettingsPageProps, SettingsPageState>) {

    listRef : React.RefObject<HTMLTextAreaElement> | null = null;
    validateRef : React.RefObject<HTMLInputElement> | null = null;

    constructor(props: SettingsPageProps) {
        super(props);
        try {
            this.initCommon(props.AppObject);

            this.listRef = React.createRef();
            this.validateRef = React.createRef();
            let useGPU = false;
            let localUseGPU = localStorage.getItem('smesshy-app-GPU');
            if (localUseGPU !== null) {
                if (localUseGPU === 'trust' || localUseGPU === '3' || localUseGPU === '2' || localUseGPU === '1' || localUseGPU === '0') {
                    useGPU = true;
                }
            }

            this.state = {
                showWaitSpin: false,
                useMouse: props.KnownUseMouse,
                showSize: props.KnownShowSize,
                hapticButton: props.KnownHapticButton,
                authenticated: false,
                navigateTo: undefined,
                accountType: 'nobody',
                accountName: 'whodis? new phone',
                selfPortraitId: 'invalid',
                selfPortraitUrl: 'invalid',
                allPaintingsPublic: true,
                autoAcceptFollows: false,
                paintings: 0,
                notifications: new Map<string, boolean>(),
                notificationsPossible: this._app!.notificationsPossible(),
                notificationsAllowed: false,
                notificationsSubscribed: false,
                rewardSummary: undefined,
                useGPU: useGPU,

            };
        } catch (e: any) {
            props.AppObject.reportException(`settings, constructor`, 'ex', '', e)
        }
    }

    componentDidMount() {
        STrace.addStep('settings', 'didMound', '');

        let controlThis = this;

        if (controlThis.props.NewSelfPortraitId !== undefined && controlThis.props.NewSelfPortraitId !== null) {
            controlThis.setState({ selfPortraitUrl:'loading', selfPortraitId: 'none'});
        }
    }


    public static stringToNotificationMap(str: string): Map<string, boolean> {
        const result = new Map<string, boolean>();
        if (str === undefined || str === null || str === '') {
            return result;
        }
        const nots = str.split(';');
        nots.forEach(n=>{
            const parts = n.split(':');
            if (parts.length === 2) {
                result.set(parts[0], parts[1] === 'true');
            }
        });
        return result;
    }
    public static notificationMapToString(map: Map<string, boolean>): string {
        let result = '';
        map.forEach((v,k)=> {
            if (result !== '') {
                result += ';';
            }
            result += `${k}:${v}`;
        });
        return result;
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('settings', 'populateAuth', authenticated.toString());

        try {
            let controlThis = this;
            let accountType = 'nobody';
            let accountName = 'whodis? new phone';
            let selfPortraitId = 'none';
            let selfPortraitUrl = 'none';
            let autoAcceptFollows = false;
            let defaultPublic = false;
            let notifications = new Map<string, boolean>();
            let paintings = 0;
            let notificationsAllowed = this.state.notificationsAllowed;
            let notificationsSubscribed = this.state.notificationsSubscribed;
            let rewardSummary: UserRewardSummary | undefined = undefined;
            if (authenticated) {
                const user = await this.getUser();
                if (user !== null && user !== undefined && user.profile !== null && user.profile !== undefined ) {
                    
                    accountName = user.profile.preferred_username!;
                    accountType = user.profile.website!;
                    STrace.addStep('settings', 'getUserArtistProfileAsync', '');
                    const prof = await this.storageManager!.getUserArtistProfileAsync()
                    STrace.addStep('settings', 'getUserStatsAsync', 'basic');
                    const userStats = await this.storageManager!.getUserStatsAsync('basic', 1);
                    if (userStats !== undefined && userStats.length > 0) {
                        paintings = userStats[0].totalPaintings;
                    }
                    STrace.addStep('settings', 'getUserRewardSummaryAsync', '');
                    rewardSummary = await this.storageManager!.getUserRewardSummaryAsync();

                    if (prof !== null && prof !== undefined) {
                        if (prof.autoApprove !== undefined) {
                            autoAcceptFollows = prof.autoApprove;
                        }
                        if (prof.defaultPublic !== undefined) {
                            defaultPublic = prof.defaultPublic;
                        }
                        if (prof.notifications !== undefined) {
                            notifications = SettingsPage.stringToNotificationMap(prof.notifications);
                        }
                        if (this.state.notificationsPossible) {
                            notificationsAllowed = Notification.permission === 'granted';
                            if (notificationsAllowed) {
                                notificationsSubscribed = await this._app!.getNotificationSubscription() !== null;
                            }
                        }

                        let selfPortraitId = prof.artistImageId;
                        if (this.props.NewSelfPortraitId !== undefined && this.props.NewSelfPortraitId !== null) {
                            STrace.addStep('settings', 'putUserPortraitAsync', this.props.NewSelfPortraitId);
                            await this.storageManager!.putUserPortraitAsync(this.props.NewSelfPortraitId);
                            selfPortraitId = this.props.NewSelfPortraitId;
                        }
        
                        if (selfPortraitId !== null && selfPortraitId !== undefined) {
                            selfPortraitUrl =  'loading...';
                            const fakeInfs: Array<PaintingInfo> = [{visibilityKind:'publ', paintingId: selfPortraitId, artistProfile: {} as ArtistProfile}];
                            const token =  (await controlThis.getAccessToken())!;
                            STrace.addStep('settings', 'getPaintingPreviews', selfPortraitId);
                            this.storageManager!.getPaintingPreviews(token, fakeInfs, (pins=>{
                                if (pins.length === 1) {
                                    selfPortraitUrl = pins[0].imageDetail!.preview;
                                    controlThis.setState({ selfPortraitUrl: selfPortraitUrl});
                                }
                            }))
                        }
                    }
                }
            }
            this.setState({authenticated: authenticated, accountName: accountName, accountType: accountType, paintings: paintings,
                selfPortraitId: selfPortraitId, selfPortraitUrl: selfPortraitUrl, autoAcceptFollows: autoAcceptFollows, allPaintingsPublic: defaultPublic,
                notifications: notifications, notificationsAllowed: notificationsAllowed, notificationsSubscribed: notificationsSubscribed,
                rewardSummary: rewardSummary
            });
        } catch (e: any) {
            this.props.AppObject.reportException(`Settings, check authentication`, 'ex', '', e)
        }

    }

    async refreshRewardSummary() {
        STrace.addStep('settings', 'getUserRewardSummaryAsync', '');
        let rewardSummary = await this.storageManager!.getUserRewardSummaryAsync();
        this.setState({rewardSummary: rewardSummary});
    }
   

    localPressWeight = FingerTip.minFingerRadius;

    async setPreferences(prefs: SmesshyUserPreferences)
    {
        const newState: SettingsPageState = {} as any;
        if (prefs.autoApprove !== undefined) {
            newState.autoAcceptFollows = prefs.autoApprove;
        } else {
            newState.autoAcceptFollows = this.state.autoAcceptFollows;
        }
        if (prefs.defaultPublic !== undefined) {
            newState.allPaintingsPublic = prefs.defaultPublic;
        } else {
            newState.allPaintingsPublic = this.state.allPaintingsPublic;
        }
        if (prefs.notifications !== undefined) {
            newState.notifications = SettingsPage.stringToNotificationMap(prefs.notifications);
        } else {
            newState.notifications = this.state.notifications;
        }
        this.setState(newState);
        STrace.addStep('settings', 'putUserPreferencesAsync', '');
        this.pushWaitingFrame(this);
        await this.storageManager!.putUserPreferencesAsync(prefs);
        this.popWaitingFrame();
    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`settings, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let env = Env;

        let controlThis = this;

        const termsAndPrivacy = <>
            <SmesshySubButton AppObject={this._app!}
                Display={<span className='h-padding-large text-small'>Terms</span>}
                Haptic = {this.doButtonHaptic()}
                StartPushedState = {false}
                OnPressFinish={async (): Promise<boolean>=>{
                    STrace.addStep('setting', 'terms', '');
                    controlThis.setState({ navigateTo: {To:{pathname:'/termsOfServiceApp'}}});
                    return true;
                }}
            />
            <SmesshySubButton AppObject={this._app!}
                Display={<span className='h-padding-large text-small'>Privacy</span>}
                Haptic = {this.doButtonHaptic()}
                StartPushedState = {false}
                OnPressFinish={async (): Promise<boolean>=>{
                    STrace.addStep('setting', 'privacy', '');
                    controlThis.setState({ navigateTo: {To:{pathname:'/privPolApp'}}});
                    return true;
                }}
            />
        </>;

        const termsPrivacyDelete = <>
            {termsAndPrivacy}
            <SmesshySubButton AppObject={this._app!}
                Display={<span className='h-padding-large'>Delete My Account</span>}
                Haptic = {this.doButtonHaptic()}
                StartPushedState = {false}
                Disabled = {false}
                OnPressFinish={async (): Promise<boolean>=>{
                    let inputRef = React.createRef() as React.RefObject<HTMLInputElement>;

                    STrace.addStep('setting', 'delete', 'pushed');
                    controlThis.setState({askQuestion:{
                        Top: controlThis._app!.GetScaledPxHeight(64),
                        Title: <span>Delete Your Account ?!?!</span>,
                        Prompts: [<span key={'l1'}>Are you sure you want to delete your account ?</span>,
                        <span key={'l2'}>That means all paintings, followers, etc.</span>,
                        <span key={'l3'}>Gone!</span>,
                        <span key={'l4'}>To be safe, type in your artist name here:</span>,
                        <div  key={'in'} className='width-100p v-items v-padding-large h-padding-large'  >
                            <input ref={inputRef} type="text" className='smesshy-input text-medium text-black'></input>
                        </div>],
                        Options: [{Key:'delete', Option:<span>Bye Bye Smesshy</span>, OnOption:async ():Promise<boolean>=>{
                            try {
                                if (inputRef.current === null || inputRef.current === undefined || inputRef.current.value === null || inputRef.current.value === '') {
                                    return false;
                                }
                                STrace.addStep('setting', 'delete', 'confirmed');
                                controlThis.setState({askQuestion:undefined});
                                controlThis.pushWaitingFrame(controlThis);
                                if (await controlThis.storageManager!.deleteUserAccountAsync(inputRef.current.value) === true) {
                                    controlThis.setState({ navigateTo: controlThis.getAuthenticationNavigate('/settings', 'logout')});
                                }
                                controlThis.popWaitingFrame();
                            } catch (e: any) {
                                this.props.AppObject.reportException(`Settings,sign out`, 'ex', '', e)
                            }
                            return true;
                        }}],
                        OnCancel:()=>{controlThis.setState({askQuestion:undefined});}                                        
                        }});
                        return true;
                    }}
                    
            />
        </>;

        let iOSButton = <></>;
        if (env.getDeviceName() === 'iOS') {
            let inApp = env.isInPWA();
            let iOSNote = <></>;
            let doButton = false;
            if (this.state.notificationsPossible === false) {
                if (inApp) {
                    iOSNote = <span key={'l1'}>
                        {`You have installed Smesshy and yet, notifications support is not detected.`}<br/>
                        {`If you are using iOS 16.4 or higher, please report this as a problem`}<br/>
                        </span>
                } else {
                    iOSNote = <span key={'l1'} >
                                {`On iOS 16.4 or higher, you can get notifications IF you have 'installed' Smesshy as an app.`}<br/>
                                {env.getBrowserName() !== 'Safari' ? <>Open this page in Safari<br/></> : <></>}
                                {`Tap the Action button (often called the Share button).`}<br/>
                                {`Scroll down the share sheet past the rows of contacts and apps, then select 'Add to Home Screen'`}<br/>
                                {`Open Smesshy from that fancy new Home Screen icon.`}
                            </span>
                }
                doButton = true;
            } else {
                if (this.state.notificationsAllowed === false) {
                    iOSNote = <span key={'l1'}>
                            {`On iOS you can get notifications if you turn them on here but you might also need to turn them on in the system settings.`}<br/>
                            {`So, flip the toggle here and then...`}<br/>
                            {`Go to Settings > Smesshy > Notifications and turn on 'Allow Notifications'.`}
                        </span>
                    doButton = true;
                }
            }
            if (doButton) {
                iOSButton = <SmesshySubButton AppObject={this._app!}
                    Display={<span className='h-padding-small'>iOS note</span>}
                    Haptic = {this.doButtonHaptic()}
                    StartPushedState = {false}
                    OnPressFinish={async (): Promise<boolean>=>{
                        STrace.addStep('setting', 'iosAsk', '');
                        controlThis.setState({askQuestion:{
                            Top: controlThis._app!.GetScaledPxHeight(64),
                            Title: <span>iOS Notifications</span>,
                            Prompts: [iOSNote],
                            Options: [{Key:'yep', Option:<span>Close</span>, OnOption:async ():Promise<boolean>=>{
                                                controlThis.setState({askQuestion:undefined});
                                                return true;
                                            }}],
                            OnCancel:()=>{controlThis.setState({askQuestion:undefined});}                                        
                            }});
                        return true;

                    }}
                />
            }
        }   

        let payoutLine = <></>;
        let payoutRequestCancelButton = <SmesshySubButton AppObject={this._app!}
            Display={<span className='h-padding-small'>Cancel Request</span>}
            Haptic = {this.doButtonHaptic()}
            StartPushedState = {false}
            OnPressFinish={async (): Promise<boolean>=>{
                STrace.addStep('setting', 'payoutRequestCancel', '');
                controlThis.pushWaitingFrame(controlThis);
                await controlThis.storageManager!.requestRewardCancelAsync();
                await controlThis.refreshRewardSummary();
                controlThis.popWaitingFrame();
                return true;
            }}
        />;
        let payoutRequestRepeatButton = <SmesshySubButton AppObject={this._app!}
            Display={<span className='h-padding-small'>Repeat Request</span>}
            Haptic = {this.doButtonHaptic()}
            StartPushedState = {false}
            OnPressFinish={async (): Promise<boolean>=>{
                STrace.addStep('setting', 'payoutRequestRepeat', '');
                controlThis.pushWaitingFrame(controlThis);
                await controlThis.storageManager!.requestRewardRepeatAsync();
                await controlThis.refreshRewardSummary();
                controlThis.popWaitingFrame();
                return true;
            }}
        />;

        if (this.state.rewardSummary !== undefined) {
            let status = this.state.rewardSummary.pendingPayoutRequestStatus;
            switch (status)
            {
                case 'none':
                case 'paymentMade':
                    break;
                case "rewardGrant":
                case 'systemCancel':
                case "userCancel":
                    payoutLine = <div className='h-items each-space-between'>
                                    <SmesshySubButton AppObject={this._app!}
                                        Display={<span className='h-padding-small'>Send me those dollars!</span>}
                                        Haptic = {this.doButtonHaptic()}
                                        StartPushedState = {false}
                                        OnPressFinish={async (): Promise<boolean>=>{
                                            STrace.addStep('setting', 'payoutRequest', '');
                                            controlThis.pushWaitingFrame(controlThis);
                                            await controlThis.storageManager!.requestRewardPayoutAsync();
                                            await controlThis.refreshRewardSummary();
                                            controlThis.popWaitingFrame();
                                            return true;
                                        }}
                                    />
                                </div>;
                    break;
                case "error":
                case "testPaymentInvalid":
                    payoutLine = <div className='h-items each-space-between'>
                                    <span>Please email smesshyhelp@wrongsaw.com</span>
                                </div>;
                    break;
                case "userRequest":
                case "instructSent":
                    payoutLine = <div className='v-items'>
                                    <span>A very 'spammy looking' email was sent to the address from your signed-in identity.</span>
                                    <span>We look forward to hearing from you.</span>
                                    <div className='h-items each-space-between'>
                                        {payoutRequestCancelButton}
                                       {payoutRequestRepeatButton}
                                    </div>
                                </div>;
                    break;
                case "processingInstruct":
                    payoutLine = <div className='v-items'>
                                    <span>Thanks for sending us your payment instructions, we are working on the next step.</span>
                                    {payoutRequestCancelButton}
                                </div>;
                    break;
                case "testPaymentFailed":
                    payoutLine = <div className='v-items'>
                                    <span>We followed your instructions and tried to transfered a small amount of money to your account as a test trasaction.</span>
                                    <span>This transaction FAILED. Please contact smesshyhelp@wrongsaw.com</span>
                                    {payoutRequestCancelButton}
                                </div>;
                    break;
                case "testPaymentMade":
                    payoutLine = <div className='v-items'>
                                    <span>We followed your instructions and transfered a small amount of money to your account as a test trasaction.</span>
                                    <span>We did this to ensure that the instructions were from you, typed correctly, understood by us and actionable.</span>
                                    <span className='text-small'>(If you did not receive a transfer from smesshy_rewards, or if you have no idea why you are seeing this message, please contact smesshyhelp@wrongsaw.com) </span>
                                    <span>Otherwise, please enter the exact amount in USD of the transfer we sent and then 'Validate.'</span>
                                    <div className='h-items each-space-between'>
                                        <div className='h-items h-medium-gap'>
                                            <input ref={this.validateRef} type='number' className='smesshy-input text-medium text-black' style={{width: controlThis._app!.GetScaledPxWidth(64)}}></input>
                                            <SmesshySubButton AppObject={this._app!}
                                                Display={<span className='h-padding-small'>Validate</span>}
                                                Haptic = {this.doButtonHaptic()}
                                                StartPushedState = {false}
                                                OnPressFinish={async (): Promise<boolean>=>{
                                                    let userAmt = 0;
                                                    if (controlThis.validateRef !== null && controlThis.validateRef.current !== null) {
                                                        userAmt = Number.parseFloat(controlThis.validateRef.current.value);
                                                    }
                                                    if (userAmt > 0) {
                                                        STrace.addStep('setting', 'testRewardValidateAmountAsync', '');
                                                        controlThis.pushWaitingFrame(controlThis);
                                                        await controlThis.storageManager!.testRewardValidateAmountAsync(userAmt);
                                                        await controlThis.refreshRewardSummary();
                                                        controlThis.popWaitingFrame();
                                                        return true;
                                                    }
                                                    return false;
                                                }}
                                            />
                                        </div>

                                        {payoutRequestCancelButton}
                                    </div>

                                </div>;
                    
                    break;
                case "testPaymentConfirmed":
                    payoutLine = <div className='h-items each-space-between'>
                                    <span>Test amount validated, now we are working of transfering the rest.</span>
                                </div>;
                    break;
            }


        }

        //let testFailButton = <></>;
        let testFailButton = <SmesshySubButton AppObject={this._app!}
            Display={<span className='h-padding-large '>DO NOT PUSH</span>}
            Haptic = {this.doButtonHaptic()}
            StartPushedState = {false}
            OnPressFinish={async ()=>{
                STrace.addStep('setting', 'testAuthFail', '');
                await controlThis.storageManager!.testAuthFail();
                return true;
            }}
        />
      
        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={false}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Title = {<span>Smesshy - Settings</span>}
                CloseNav = {'/'}
                Body={

                    <div className='game-page-mid v-scroll-region text-medium text-black' >
                    <div className='v-items v-medium-gap v-padding-large h-padding-medium height-100p width-100p v-scroll-container'>
                        <div className='v-items v-medium-gap width-100p smesshy-group'>
                            <div className='h-items h-medium-gap width-100p'>
                                <SmesshySubButton AppObject={this._app!}
                                    Display={<span className='h-padding-large'>Adjust to Your Fingertip</span>}
                                    Haptic = {this.doButtonHaptic()}
                                    StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        STrace.addStep('setting', 'calibrate', '');
                                        controlThis.setState({navigateTo: {To: '/calibrate'}});
                                        return true;
                                    }}
                                />
                            </div>

                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    checked={this.state.useMouse}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'use mouse', evt.target.checked.toString());
                                        controlThis.SetAppState('useMouse', evt.target.checked, true);
                                        controlThis.setState({useMouse: evt.target.checked});
                                        }} />
                                <span>I use a mouse, no screen touching</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    checked={this.state.showSize}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'show size', evt.target.checked.toString());
                                        controlThis.SetAppState('showSize', evt.target.checked, true);
                                        controlThis.setState({showSize: evt.target.checked});
                                        }} />
                                <span>Let me manually set the finger size</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    checked={this.state.hapticButton}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'use haptic', evt.target.checked.toString());
                                        const checked = env.getVibrateSupport() ? evt.target.checked : false;
                                        controlThis.SetAppState('hapticButton', checked, true);
                                        controlThis.setState({hapticButton: checked});
                                        }} />
                                <span>Vibrate on button presses</span>
                            </label>

                        </div>
                        <div className='v-items v-medium-gap width-100p smesshy-group'>
                            {!this.state.authenticated ?
                                <div className='h-items h-medium-gap width-100p'>
                                    <SmesshySubButton AppObject={this._app!}
                                        Display={<span className=' h-padding-large'>Sign In</span>}
                                        Haptic = {this.doButtonHaptic()}
                                        StartPushedState = {false}
                                        OnPressFinish={async (): Promise<boolean>=>{
                                            try {
                                                STrace.addStep('setting', 'sign in', '');
                                                controlThis.setState({ navigateTo: controlThis.getAuthenticationNavigate('/settings', 'login')});
                                            } catch (e: any) {
                                                this.props.AppObject.reportException(`Settings,sign in`, 'ex', '', e)
                                            }
                                            return true;
                                        }}
                                    />
                                   {termsAndPrivacy}
                            </div>
                             :
                                <SmesshySubButton AppObject={this._app!}
                                    Display={<span className=' h-padding-large'>Sign Out</span>}
                                    Haptic = {this.doButtonHaptic()}
                                    StartPushedState = {false}
                                    OnPressFinish={async (): Promise<boolean>=>{
                                        try {
                                            STrace.addStep('setting', 'sign out', '');
                                            controlThis.setState({ navigateTo: controlThis.getAuthenticationNavigate('/settings', 'logout')});
                                        } catch (e: any) {
                                            this.props.AppObject.reportException(`Settings,sign out`, 'ex', '', e)
                                        }
                                        return true;
                                    }}
                                />  
                            }
                            <label className='h-items h-medium-gap each-cross-center'>
                                <span>Smesshy Calls Me:</span>
                                <span className='text-bold text-large'>{this.state.accountName}</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <span>Authenticated by:</span>
                                <span className='text-bold text-large'>{this.state.accountType}</span>
                            </label>
                            {this.state.authenticated ?
                            <>
                                {this.state.paintings > 0 ?
                                <div className='h-items h-medium-gap'>
                                    <div className='v-items v-medium-gap'>
                                        <SmesshySubButton AppObject={this._app!}
                                            Display={<span className='h-padding-large '>Self Portrait</span>}
                                            Haptic = {this.doButtonHaptic()}
                                            StartPushedState = {false}
                                            ExtraStyleClass='width-content height-content'
                                            OnPressFinish={async (): Promise<boolean>=>{
                                                STrace.addStep('setting', 'nav self portrait', '');
                                                controlThis.setState(
                                                    {navigateTo: {To: {pathname:'/painting-selection', search:`?${createSearchParams({title: 'Select your self-portrait:', scope: 'my', filter:'recent', callback:'/settings', current:this.state.selfPortraitId!})}`}}
                                                });
                                                return true;
                                            }}
                                        />
                                        {termsPrivacyDelete}
                                    </div>
                                    {this.state.selfPortraitUrl === 'none' ? 
                                        <div className='self-cross-center'>Pick one.</div> : 
                                        (this.state.selfPortraitUrl=== 'loading' ? <div className='self-cross-center'>Loading it...</div> :
                                        <img style={{height:controlThis._app?.GetScaledPxHeightVh(200), width:controlThis._app?.GetScaledPxWidthVw(100)}} src={this.state.selfPortraitUrl} />)
                                    }
                                    
                                </div> :
                                <div className='h-items h-medium-gap'>
                                    <div className='v-items v-medium-gap'>
                                        <SmesshySubButton AppObject={this._app!}
                                            Display={<span className='h-padding-large '>Self Portrait</span>}
                                            Haptic = {this.doButtonHaptic()}
                                            StartPushedState = {false}
                                            ExtraStyleClass='width-content height-content'
                                            OnPressFinish={async (): Promise<boolean>=>{
                                                STrace.addStep('setting', 'paint self portrait', '');

                                                controlThis.setState(
                                                    {navigateTo: {To: '/painting'}}
                                                );
                                                return true;
                                            }}
                                        />
                                        {termsPrivacyDelete}
                                    </div>
                                    {
                                        <div className='self-cross-center text-large'>Paint one.</div> 
                                    }
                                </div>
                                } 

                                <label className='h-items h-medium-gap each-cross-center'>
                                    <Toggle
                                        checked={this.state.autoAcceptFollows}
                                        value='yes'
                                        onChange={(evt)=>{
                                            STrace.addStep('setting', 'accept follow', evt.target.checked.toString());
                                            controlThis.setPreferences({autoApprove: evt.target.checked});
                                            }} />
                                    <span>Automatically accept followers</span>
                                </label>
                                <label className='h-items h-medium-gap each-cross-center'>
                                    <Toggle
                                        checked={this.state.allPaintingsPublic}
                                        value='yes'
                                        onChange={(evt)=>{
                                            STrace.addStep('setting', 'follow are public', evt.target.checked.toString());
                                            controlThis.setPreferences({defaultPublic: evt.target.checked});
                                            }} />
                                    <span>Paintings for followers are also public</span>
                                </label>
                            </>
                             : <></>
                            }
                        </div>
                        {this.state.authenticated && this.state.rewardSummary !== undefined ?
                        <div className='v-items v-medium-gap width-100p smesshy-group'>
                            <div className='h-items each-space-between'>
                                <span>Lifetime Awards (USD):</span>
                                <span>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(this.state.rewardSummary.totalRewardGranted)}</span>
                                
                            </div>
                            <div className='h-items each-space-between'>
                                <span>Current Award Balance (USD):</span>
                                <span>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(this.state.rewardSummary.currentRewardBalance)}</span>
                            </div>
                            {payoutLine}

                        </div> : <></>
                        }

                        {this.state.authenticated ?
                        <div className='v-items v-medium-gap width-100p smesshy-group'>
                            <div className='h-items each-space-between'>
                                <label className='h-items h-medium-gap each-cross-center'>
                                    <Toggle
                                        checked={this.state.notifications.get('any') === true && this.state.notificationsAllowed === true && this.state.notificationsSubscribed === true}
                                        disabled={!this.state.notificationsPossible}
                                        value='yes'
                                        onChange={(evt)=>{
                                            STrace.addStep('setting', 'any notify', evt.target.checked.toString());
                                            controlThis.executeAsync(async ()=> {
                                                if (evt.target.checked === true) {
                                                    let allowed = this.state.notificationsAllowed;
                                                    if (!allowed) {
                                                        allowed = await controlThis._app!.getNotificationPermission(true);
                                                    }
                                                    if (allowed) {
                                                        const sub = await controlThis._app!.getNotificationSubscription();
                                                        if (sub === null) {
                                                            STrace.addStep('setting', 'createNotificationRegistration', '');
                                                            if (await controlThis._app!.createNotificationRegistration() === true) {
                                                                controlThis.state.notifications.set('any', true);
                                                                controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                                                controlThis.setState({notificationsAllowed:true, notificationsSubscribed: true});
                                                            }
                                                        } else {
                                                            controlThis.state.notifications.set('any', true);
                                                            controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                                            controlThis.setState({notificationsAllowed:true, notificationsSubscribed: true});
                                                        }
                                                    }

                                                } else {
                                                    if (await controlThis._app!.deleteNotificationRegistration() === true) {
                                                        controlThis.state.notifications.set('any', false);
                                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                                        controlThis.setState({notificationsSubscribed: false});
                                                    }
                                                }
                                            });
                                        }}
                                    />
                                    <span>Receive any notifications</span>
                                </label>
                                {iOSButton}
                            </div>

                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    disabled={this.state.notifications.get('any') === false}
                                    checked={this.state.notifications.get('favorite') === true}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'notify other favor', evt.target.checked.toString());
                                        controlThis.state.notifications.set('favorite', evt.target.checked);
                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                        }} />
                                <span className='text-small'>When others 'favor' one of your paintings</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    disabled={this.state.notifications.get('any') === false}
                                    checked={this.state.notifications.get('lunchbox') === true}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'notify other lunchbox', evt.target.checked.toString());
                                        controlThis.state.notifications.set('lunchbox', evt.target.checked);
                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                        }} />
                                <span className='text-small'>When someone hides a painting in your lunchbox</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    disabled={this.state.notifications.get('any') === false}
                                    checked={this.state.notifications.get('pokeLunchbox') === true}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'notify other poke', evt.target.checked.toString());
                                        controlThis.state.notifications.set('pokeLunchbox', evt.target.checked);
                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                        }} />
                                <span className='text-small'>When someone wishes to remind you about a lunchbox streak.</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    disabled={this.state.notifications.get('any') === false}
                                    checked={this.state.notifications.get('followRequest') === true}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'notify other follow', evt.target.checked.toString());
                                        controlThis.state.notifications.set('followRequest', evt.target.checked);
                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                        }} />
                                <span className='text-small'>When someone wishes to follow your shared paintings</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    disabled={this.state.notifications.get('any') === false}
                                    checked={this.state.notifications.get('offensive') === true}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'notify other offended', evt.target.checked.toString());
                                        controlThis.state.notifications.set('offensive', evt.target.checked);
                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                        }} />
                                <span className='text-small'>When someone finds one of your painting 'offensive'</span>
                            </label>
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    disabled={this.state.notifications.get('any') === false}
                                    checked={this.state.notifications.get('suspect') === true}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'notify other suspect', evt.target.checked.toString());
                                        controlThis.state.notifications.set('suspect', evt.target.checked);
                                        controlThis.setPreferences({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                        }} />
                                <span className='text-small'>When someone finds one of your painting 'suspect'</span>
                            </label>

                        </div> : <></>
                        }
                        <div className='h-items h-medium-gap width-100p smesshy-group'>
                            <SmesshySubButton AppObject={this._app!}
                                Display={<span className='h-padding-large '>Reset Tutorial</span>}
                                Haptic = {this.doButtonHaptic()}
                                StartPushedState = {false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    STrace.addStep('setting', 'reset tutorial', '');
                                    controlThis._app!.ResetTutorialKeys(tutorialKeys);
                                    return true;
                                }}
                            />
                            <SmesshySubButton AppObject={this._app!}
                                Display={<span className='h-padding-large '>Repeat Setup</span>}
                                Haptic = {this.doButtonHaptic()}
                                StartPushedState = {false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    STrace.addStep('setting', 'reset setup', '');
                                    localStorage.setItem('smesshy-app-setup-complete', 'false');
                                    localStorage.setItem('smesshy-app-setup-notifications', 'false');
                                    localStorage.setItem('smesshy-app-setup-cookies', 'false');
                                    controlThis.setState({navigateTo: {To: '/'}});
                                    return true;
                                }}
                            />

                        </div>
                        <div className='v-items h-medium-gap width-100p smesshy-group'>
                            Experiments:
                            <label className='h-items h-medium-gap each-cross-center'>
                                <Toggle
                                    checked={controlThis.state.useGPU}
                                    value='yes'
                                    onChange={(evt)=>{
                                        STrace.addStep('setting', 'try gpu', evt.target.checked.toString());
                                        if (evt.target.checked) {
                                            controlThis.setState({useGPU: true});
                                            localStorage.setItem('smesshy-app-GPU', '3');
                                        } else {
                                            controlThis.setState({useGPU: false});
                                            localStorage.removeItem('smesshy-app-GPU');
                                        }
                                        }} />
                                <span className='text-small'>Use GPU painting after restart</span>
                            </label>

                            {testFailButton}

                        </div>




                    </div>
                </div>
                }
            />, this.state
        );
    }
}

export default SettingsPage;