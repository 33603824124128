import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';


export interface KnifeTouchUniformLocations extends TouchUniformLocations {
   
    workingDepthTexNum: WebGLUniformLocation,
    workingColorTexNum: WebGLUniformLocation,
};

export interface KnifeTouchProgramInfo extends TouchProgramInfo {
    uniformLocations: KnifeTouchUniformLocations
};

export interface KnifeTouchProgramBuffers extends TouchProgramBuffers {

    workingADepthTexture: WebGLTexture | null,
    workingAColorTexture: WebGLTexture | null,
    workingAFramebuffer: WebGLFramebuffer | null,
    workingBDepthTexture: WebGLTexture | null,
    workingBColorTexture: WebGLTexture | null,
    workingBFramebuffer: WebGLFramebuffer | null,

};


export class KnifePageChange extends TouchPageChange {

    static startupProgram: KnifeTouchProgramInfo = {tag:'KnifestartupProgram', uniformLocations:{}} as KnifeTouchProgramInfo;
    static KnifeBuffers: KnifeTouchProgramBuffers | undefined;

    static fsSourceStartup = `#version 300 es
    #line 3046
    uniform highp usampler2D u_workingDepthTexNum;
    uniform highp sampler2D u_workingColorTexNum;
    in highp vec2 v_texCoordRendered;
    in highp vec2 v_texCoordExternal;
    layout(location = 0) out highp vec4 outputColor;
    layout(location = 1) out highp uint outputDepth;
    
    void main() {
        outputDepth = texture(u_workingDepthTexNum, v_texCoordExternal).r;
        outputColor = texture(u_workingColorTexNum, v_texCoordExternal);
        if (outputDepth != 0u) {
            outputColor.b = 1.0;
        }
    }
    `;
    

    static getPrograms(): Array<[string, ProgramInfo]> {
        return [[KnifePageChange.fsSourceStartup, KnifePageChange.startupProgram]];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        KnifePageChange.startupProgram.uniformLocations = {} as KnifeTouchUniformLocations;
        KnifePageChange.addProgramLocations(gl, KnifePageChange.startupProgram);

    }

    static addProgramLocations(gl: WebGL2RenderingContext, programInfo: KnifeTouchProgramInfo) {
        programInfo.uniformLocations.workingDepthTexNum = gl.getUniformLocation(programInfo.program, 'u_workingDepthTexNum')!;
        programInfo.uniformLocations.workingColorTexNum = gl.getUniformLocation(programInfo.program, 'u_workingColorTexNum')!;
        PageChange.addProgramLocations(gl, programInfo);
    }

    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        KnifePageChange.KnifeBuffers = {} as KnifeTouchProgramBuffers;
        KnifePageChange.addPermanentStorage(gl, KnifePageChange.KnifeBuffers, textureCallback);
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: KnifeTouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        //PageChange.addPermanentStorage(gl, buffers, textureCallback);

        if (KnifePageChange.KnifeBuffers!.workingADepthTexture === undefined)
        {
            KnifePageChange.KnifeBuffers!.workingAColorTexture = GPURunner.initTexture(gl, 'workingAColorTexture');
            KnifePageChange.KnifeBuffers!.workingADepthTexture = GPURunner.initTexture(gl, 'workingADepthTexture');
            KnifePageChange.KnifeBuffers!.workingAFramebuffer = GPURunner.initTextureFramebuffer(gl, 'workingAFramebuffer', KnifePageChange.KnifeBuffers!.workingAColorTexture, KnifePageChange.KnifeBuffers!.workingADepthTexture);
            KnifePageChange.KnifeBuffers!.workingBColorTexture = GPURunner.initTexture(gl, 'workingBColorTexture');
            KnifePageChange.KnifeBuffers!.workingBDepthTexture = GPURunner.initTexture(gl, 'workingBDepthTexture');
            KnifePageChange.KnifeBuffers!.workingBFramebuffer = GPURunner.initTextureFramebuffer(gl, 'workingBFramebuffer', KnifePageChange.KnifeBuffers!.workingBColorTexture, KnifePageChange.KnifeBuffers!.workingBDepthTexture);

        }
        buffers.workingADepthTexture = KnifePageChange.KnifeBuffers!.workingADepthTexture;
        buffers.workingAColorTexture = KnifePageChange.KnifeBuffers!.workingAColorTexture;
        buffers.workingAFramebuffer = KnifePageChange.KnifeBuffers!.workingAFramebuffer;
        buffers.workingBDepthTexture = KnifePageChange.KnifeBuffers!.workingBDepthTexture;
        buffers.workingBColorTexture = KnifePageChange.KnifeBuffers!.workingBColorTexture;
        buffers.workingBFramebuffer = KnifePageChange.KnifeBuffers!.workingBFramebuffer;

    }


    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication,
        setWetDry: boolean, blendDried: boolean) {
        super(page, pageX, pageY, width, height, tipsAndLocations, tipApplication, setWetDry, blendDried);
    }

    override preparePageForStartup(): void {
        GPURunner.initTouchWetPageChange(this.page, this);
        GPURunner.initTouchVisiblePageChange(this.page, this);
    }
    override applyStartupToPage(): void {
    }
    override preparePageForProcess(): void {
    }
    override applyProcessToPage(): void {
    }
    override preparePageForFinish(): void {
    }
    override applyFinishToPage(): void {
        GPURunner.applyTouchWetPageChange(this.page, this);
        GPURunner.applyTouchVisiblePageChange(this.page, this);
    }

    override selectStartupProgram(): KnifeTouchProgramInfo {
        let gl = GPURunner.gl!;
        let pi = KnifePageChange.startupProgram;

        gl.useProgram(pi.program);
        return pi;
    }

    override initializeStartupProgram(pi: KnifeTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = KnifePageChange.KnifeBuffers!;

        if (GPURunner.doInitializeProgram(pi, 'Knife')) {
        }
    }
    override specializeStartupRoutine(pi: KnifeTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = KnifePageChange.KnifeBuffers!;

        gl.canvas.width = this.width;
        gl.canvas.height = this.height;

        // set up the working textures
        gl.bindTexture(gl.TEXTURE_2D, buffers.workingADepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.workingAColorTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA8,
            this.width, this.height, 0,
            gl.RGBA, gl.UNSIGNED_BYTE, null);

        gl.bindTexture(gl.TEXTURE_2D, buffers.workingBDepthTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.R16UI,
            this.width, this.height, 0,
            gl.RED_INTEGER, gl.UNSIGNED_SHORT, null);
        gl.bindTexture(gl.TEXTURE_2D, buffers.workingBColorTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA8,
            this.width, this.height, 0,
            gl.RGBA, gl.UNSIGNED_BYTE, null);
    

        //this.workingFrameTextureNumbers = [TouchPageChange.externalColorTexNumVal, TouchPageChange.externalDepthTexNumVal];
    }

    override runStartupRoutine(pi: KnifeTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        let buffers = KnifePageChange.KnifeBuffers!;
        // set up the source color texture
        // this.bindWorkingFrame(null, this.pageWetColorTexture, this.pageWetDepthTexture);
        // gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.workingBFramebuffer);
        // gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1]);
        // gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);

        // gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        // this.bindWorkingFrame(null, buffers.workingBColorTexture, buffers.workingBDepthTexture);

        // gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
        //this.debugRepeatDraw();

    }
    override finalizeStartup(pi: KnifeTouchProgramInfo): void {
        let gl = GPURunner.gl!;
        //this.debugRepeatDraw();
        this.page.crcVisible.drawImage(gl.canvas, this.pageX, this.pageY);


    }

    override selectProcessProgram(): KnifeTouchProgramInfo {
        let gl = GPURunner.gl!;

        //gl.useProgram(pi.program);
        return undefined as any as KnifeTouchProgramInfo;
    }

    

    override initializeProcessProgram(pi: KnifeTouchProgramInfo): void {

    }
    override specializeProcessRoutine(pi: KnifeTouchProgramInfo): void {

    }

    override runProcessRoutine(pi: KnifeTouchProgramInfo): void {
    }


    override finalizeProcess(pi: KnifeTouchProgramInfo): void {

    }
    override selectFinishProgram(): KnifeTouchProgramInfo {
        return undefined as any as KnifeTouchProgramInfo;
   
    }

    override initializeFinishProgram(pi: KnifeTouchProgramInfo): void {
    }

    override specializeFinishRoutine(pi: KnifeTouchProgramInfo): void {

    }

    override runFinishRoutine(pi: KnifeTouchProgramInfo): void {
    }
    override finalizeFinish(pi: KnifeTouchProgramInfo): void {
    }

}

        