import React, { useEffect, ChangeEvent, Component } from 'react';
import { App } from '../App'
import Smear from './smash/smear';
import IconButton, {CloseButton, IconPhoto, IconPlay, IconTrash, IconEmpty, IconPlus, IconSample, IconFinger, IconPalette, IconPainting, IconSplotch, IconClose, IconUpload, IconRefFactory, IconBlow, IconKnife, IconStop} from './iconButton';
import tinycolor from 'tinycolor2';
import FingerTip from './smash/fingertip';
import { Navigate } from 'react-router-dom';
import SmesshyAsk, { SmesshyAskOption, SmesshyAskParams, SmesshyDone } from './smesshyAsk';
import AuthorizeRoute from './api-authorization/AuthorizeRoute'
import Smessage from './smessage';
import ImageLocate from './imageLocate';
import AnimationActionRecording, { AnimationActionKind } from './smash/animationActions';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState, SmesshyToolOptions } from '../smesshyCommon';
import GamePage from './gamePage';
import { LuchboxPicker } from './followingPicker';
import { tutorialPaintingBlow, tutorialPaintingCanvasManual, tutorialPaintingCanvasTouch, tutorialPaintingColor, tutorialPaintingInitial, tutorialPaintingKnife, tutorialPaintingPalette, tutorialPaintingPaletteSwap, tutorialPaintingReplay, tutorialPaintingSample, tutorialPaintingSave, tutorialPaintingSaveAnon, tutorialPaintingSmudge, tutorialPaintingStamp, tutorialPaintingTrash } from './tutorialStep';


export interface PaintingPageState extends SmesshyCommonState {
    color: string;
    sampleMode: boolean;
    paletteMode: boolean;
    pressWeight: number;
    fixedSize: number;
    avgLightRadius: number;
    avgHeavyRadius: number;
    useMouse: boolean;
    showSize: boolean;
    addedColors: Array<string>;
    pendingActionButton: string | undefined;
    allDisabled: boolean;
    replaying: boolean;
}
export interface PaintingPageProps extends SmesshyCommonProps {
    InteractionMode: string;
    SaveMode: string;
    KnownAvgLightRadius: number;
    KnownAvgHeavyRadius: number;
    KnownUseMouse: boolean;
    KnownShowSize: boolean;
    StampsAllowed: boolean;
}

interface PaintingPageBehaviors {
    needsCalibraton: boolean;
    fixedSize: number;
    showSize: boolean;
    restorePalette: boolean;
    restorePaintingActions: string | undefined;
    contextKey: string;
    canCacheCtx: boolean;
    saveStrokes: boolean;
    showReplay: boolean;
    forceReplay: boolean;
    showStamp: boolean;
    saveKind: string;
}

class PaintingPage extends SmesshyCommon(Component<PaintingPageProps, PaintingPageState>) {

    smearRef: React.RefObject<Smear> | null = null;
    mainRecording: AnimationActionRecording | undefined = undefined;    
    pendingReplay: string | undefined;
    timeoutEnableButtons: NodeJS.Timeout | undefined;
    behave: PaintingPageBehaviors = {} as PaintingPageBehaviors;

    constructor(props: PaintingPageProps) {
        super(props);

        try {
            this.initCommon(props.AppObject);

            const doCalibrateFirst =  ((this.GetAppState('calibrationVersion', false) as number) < App.calibrationVersion);
            const fixedSize = props.KnownShowSize ? FingerTip.standardFingerRadius : 0;


            if (props.SaveMode === 'any') {
                this.behave = {
                    needsCalibraton: doCalibrateFirst,
                    fixedSize: fixedSize,
                    showSize: props.KnownShowSize,
                    restorePalette: true,
                    restorePaintingActions : 'paintingActionsSelf',
                    contextKey: 'paintingPageSelf',
                    saveStrokes: true,
                    showReplay: true,
                    forceReplay: false,
                    showStamp: true,
                    canCacheCtx: true,
                    saveKind: 'any'
                } as PaintingPageBehaviors;
            } else if (props.SaveMode === 'chal') {
                this.behave = {
                    needsCalibraton: doCalibrateFirst,
                    fixedSize: fixedSize,
                    showSize: props.KnownShowSize,
                    restorePalette: true,
                    restorePaintingActions : 'paintingActionsChal',
                    contextKey: 'paintingPageChal',
                    saveStrokes: true,
                    showReplay: true,
                    forceReplay: false,
                    showStamp: props.StampsAllowed,
                    canCacheCtx: true,
                    saveKind: 'chal'
                } as PaintingPageBehaviors;
            } else if (props.SaveMode === 'foll') {
                this.behave = {
                    needsCalibraton: doCalibrateFirst,
                    fixedSize: fixedSize,
                    showSize: props.KnownShowSize,
                    restorePalette: true,
                    restorePaintingActions : 'paintingActionsSelf',
                    contextKey: 'paintingPageSelf',
                    saveStrokes: true,
                    showReplay: true,
                    forceReplay: false,
                    showStamp: true,
                    canCacheCtx: true,
                    saveKind: 'foll'
                } as PaintingPageBehaviors;
            } else if (props.SaveMode === 'lunc') {
                this.behave = {
                    needsCalibraton: doCalibrateFirst,
                    fixedSize: fixedSize,
                    showSize: props.KnownShowSize,
                    restorePalette: true,
                    restorePaintingActions : 'paintingActionsSelf',
                    contextKey: 'paintingPageSelf',
                    saveStrokes: true,
                    showReplay: true,
                    forceReplay: false,
                    showStamp: true,
                    canCacheCtx: true,
                    saveKind: 'lunc'
                } as PaintingPageBehaviors;
            } else if (props.InteractionMode === 'anon') {
                this.behave = {
                    needsCalibraton: false,
                    fixedSize: FingerTip.standardFingerRadius,
                    showSize: true,
                    restorePalette: false,
                    restorePaintingActions : undefined,
                    contextKey: 'paintingPageAnon',
                    saveStrokes: false,
                    showReplay: false,
                    forceReplay: false,
                    showStamp: true,
                    canCacheCtx: false,
                    saveKind: 'none'
                } as PaintingPageBehaviors;
            } else if (props.InteractionMode === 'watch') {
                this.behave = {
                    needsCalibraton: false,
                    fixedSize: doCalibrateFirst ? FingerTip.standardFingerRadius : fixedSize,
                    showSize: doCalibrateFirst ? true : props.KnownShowSize,
                    restorePalette: true,
                    restorePaintingActions : 'paintingActionsOther',
                    contextKey: 'paintingPageAnon',
                    saveStrokes: false,
                    forceReplay: true,
                    showReplay: true,
                    showStamp: true,
                    canCacheCtx: false,
                    saveKind: 'none'
                } as PaintingPageBehaviors;
            } else if (props.InteractionMode === 'edit') {
                this.behave = {
                    needsCalibraton: false,
                    fixedSize: doCalibrateFirst ? FingerTip.standardFingerRadius : fixedSize,
                    showSize: doCalibrateFirst ? true : props.KnownShowSize,
                    restorePalette: true,
                    restorePaintingActions : 'paintingActionsSelf',
                    contextKey: 'paintingPageSelf',
                    saveStrokes: true,
                    forceReplay: true,
                    showReplay: true,
                    showStamp: true,
                    canCacheCtx: true,
                    saveKind: 'any'
                } as PaintingPageBehaviors;
            } else {
                this.behave = {} as PaintingPageBehaviors;
                console.error('PaintingPage, unknown mode');
            }
    
    
            this.smearRef = React.createRef();
            this.mainRecording = new AnimationActionRecording();


            this.state = {
                showWaitSpin: false,
                color: '#000000',
                pressWeight: FingerTip.standardFingerRadius,
                sampleMode: false,
                paletteMode: false,
                fixedSize: this.behave.fixedSize,
                avgLightRadius: props.KnownAvgLightRadius,
                avgHeavyRadius: props.KnownAvgHeavyRadius,
                useMouse: props.KnownUseMouse,
                showSize: this.behave.showSize,
                addedColors: new Array<string>(),
                pendingActionButton: undefined,
                askQuestion: undefined,
                navigateTo: this.behave.needsCalibraton ? {To:'/calibrate'} : undefined,
                overlayTool: undefined,
                authenticated: false,
                allDisabled: false,
                replaying: false
            };
        } catch (e: any) {
            props.AppObject.reportException(`Paint, constructor`, 'ex', '', e)
        }
    }

    prevDefault = (ev:TouchEvent)=>{
        if (ev.touches.length === 1) {
            const touch = ev.touches[0];
            if (touch.clientX < 16 || touch.clientX > window.innerWidth - 16) {
                ev.preventDefault();
            }
        }
    };

    componentDidMount() {
        STrace.addStep('painting', 'didMound', '');

        window.addEventListener('touchstart', this.prevDefault, { passive: false })
        let controlThis = this;
        this.executeWhenSafe(()=>{
            controlThis.pushWaitingFrame(this);

            const persistedPaintingInfo = controlThis.getCurrentPainting();
            controlThis.pendingReplay = persistedPaintingInfo[0];
    
            // put the saved palette back if any
            if (persistedPaintingInfo[1] !== undefined) {
                controlThis.setState({ addedColors: persistedPaintingInfo[1]});
            }
            controlThis.popWaitingFrame();
    
        });
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('painting', 'populateAuth', authenticated.toString());
        this._app!.clearNotifications('pokeLunchbox');
        this.setState({authenticated: authenticated});
    }

    componentWillUnmount() {
        this.saveStrokes();
        window.removeEventListener('touchstart', this.prevDefault);
    }

    onDragStart(mainCanvas: boolean) : boolean {
        if (mainCanvas === true) {
            if (this._app!.DoShowTutorial('paintingCanvas')) {
                if (this.behave.showSize === true) {
                    this.setState({tutorialStep: tutorialPaintingCanvasManual})
                } else {
                    this.setState({tutorialStep: tutorialPaintingCanvasTouch})
                }
                return false;
            }
        }
        // small delay to prevent accidental button presses will happen when done, so reset if just starting while waiting
        if (this.timeoutEnableButtons !== undefined) {
            clearTimeout(this.timeoutEnableButtons);
            this.timeoutEnableButtons = undefined;
        }
        this.setState({allDisabled:true});
        return true;
    }
    onDragEnd() {
        // small delay to prevent accidental button presses.
        if (this.timeoutEnableButtons !== undefined) {
            clearTimeout(this.timeoutEnableButtons);
            this.timeoutEnableButtons = undefined;
        }
        let controlThis=this;
        this.timeoutEnableButtons = setTimeout(()=>{
                controlThis.setState({allDisabled:false}); 
                controlThis.timeoutEnableButtons = undefined;
                controlThis.saveStrokes()

            }, 500);
    }


    clearMainSmash(save: boolean) {
        if (this.smearRef!.current) {
            this.smearRef!.current!.clearCanvas(); 
        }
        this.mainRecording = new AnimationActionRecording();
        this.mainRecording.recordAction({kind:AnimationActionKind.color, color: this.state.color});
        this.mainRecording.recordAction({kind:AnimationActionKind.paintRadius, rad: this.state.pressWeight});
        if (save) {
            this.saveStrokes()
        }
    }

    stopAndChopReplay() {
        if (this.state.replaying === true) {
            let lastStep = this.smearRef!.current!.stopReplay();
            if (lastStep !== -1) {
                this.mainRecording!.chopAfter(lastStep);
                this.saveStrokes();
            }
            this.setState({replaying: false, allDisabled:false});
        }
    }

    addColorSample(rgb: tinycolor.ColorFormats.RGB) {
        let colorKey = new tinycolor(rgb).toHexString();
        this.mainRecording!.recordAction({kind:AnimationActionKind.color, color: colorKey});
        let newAdded = this.state.addedColors.concat(colorKey);
        this.saveStrokes();
        this.setState({sampleMode: false, addedColors: newAdded, color: colorKey, pendingActionButton: undefined});
        
    }

    removeColorSamples() {
        localStorage.removeItem('paletteCurrent');
        this.saveStrokes();
        this.setState({addedColors: []});
    }

    clearAsk() {
        this.setState({askQuestion: undefined, pendingActionButton:undefined});
    }

    getCurrentPainting() : [string | undefined, Array<string> | undefined] {

        let persistedRGBs: Array<string> | undefined;
        if (this.behave.restorePalette) {
            let persistedPalette:string | null = localStorage.getItem('paletteCurrent');
            if (persistedPalette !== null && persistedPalette !== undefined) {
                let persistedColorStr = JSON.parse(persistedPalette) as Array<string>
                if (persistedColorStr !== null && persistedColorStr !== undefined && persistedColorStr.length > 0) {
                    persistedRGBs = new Array<string>();
                    for (const clr of persistedColorStr) {
                        persistedRGBs.push(new tinycolor(clr).toHexString());
                    }
                }
            }
        }


        let paintingActionsCurrent:string | null | undefined;
        if (this.behave.restorePaintingActions !== undefined) {
            paintingActionsCurrent = localStorage.getItem(this.behave.restorePaintingActions);
        }
        if (paintingActionsCurrent === null) {
            paintingActionsCurrent = undefined;
        }
        return [paintingActionsCurrent, persistedRGBs];
    }


    stillSaving = false;
    
    saveStrokes() {

        if (this.behave.saveStrokes === false) {
            return;
        }

        // lots of things need to be up and running before we should try this
        if (this.stillSaving === true || this.state.showWaitSpin === true || !this.smearRef!.current || this.smearRef!.current.getPage() === undefined ) {
            return;
        }
        this.stillSaving = true;

        let saveActionNeeded = this.mainRecording!.getSerializeNeeded();
        if (saveActionNeeded === 'skip'){
            this.stillSaving = false;
            return;
        }
   
        let rec = this.mainRecording!.serialize();

        const persistedPalette = new Array<string>();
        for (const clr of this.state.addedColors) {
            persistedPalette.push(new tinycolor(clr).toHexString());
        }
        localStorage.setItem('paletteCurrent', JSON.stringify(persistedPalette));
        localStorage.setItem(this.behave.restorePaintingActions!, rec);

        this.stillSaving = false;
    }

    localPressWeight = FingerTip.minFingerRadius;

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`paint, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {

        let controlThis = this;
        const doHaptic = this.doButtonHaptic();

        const makeIconButton = (key: string | undefined, color: string|undefined, iconRefFactory: IconRefFactory, disabled: boolean, onPressAlso: (()=>void) | undefined) : JSX.Element=> {
            if (key === undefined) {
                key = color;
            }
            return (
                <IconButton  AppObject={this._app!}
                    key={key}
                    Tag={key!}
                    IconRef={iconRefFactory(color)}
                    Disabled={disabled}
                    PressTickInterval={color === undefined ? 0 : 50}
                    OnPressStart={()=>{
                        if (color !== undefined) {
                            controlThis.localPressWeight = FingerTip.minFingerRadius - 1;
                            controlThis.setState({color:color, sampleMode:false, pressWeight: FingerTip.minFingerRadius})
                            controlThis.mainRecording!.recordAction({kind:AnimationActionKind.color, color: color});
                            controlThis.mainRecording!.recordAction({kind:AnimationActionKind.paintRadius, rad: FingerTip.minFingerRadius});
                        } 
                    }}
                    OnPressTick={()=>{
                        if (color !== undefined) {
                            controlThis.localPressWeight ++;
                            if (doHaptic && controlThis.localPressWeight <= FingerTip.maxFingerRadius) {
                                let vibTime = Math.floor(2 + (3 * (controlThis.localPressWeight - FingerTip.minFingerRadius) / (FingerTip.maxFingerRadius - FingerTip.minFingerRadius)));
                                navigator.vibrate(vibTime);
                            }
                            controlThis.localPressWeight = Math.min(FingerTip.maxFingerRadius, controlThis.localPressWeight);
                            controlThis.setState({sampleMode:false, pressWeight: controlThis.localPressWeight})
                            controlThis.mainRecording!.recordAction({kind:AnimationActionKind.paintRadius, rad: controlThis.localPressWeight});
                        }
                    }}
                    OnPressFinish={async (): Promise<boolean>=>{
                        if (color !== undefined) {
                            let tutorialStep = tutorialPaintingColor;
                            if (iconRefFactory === IconFinger) {
                                tutorialStep = tutorialPaintingSmudge;
                            } else if (iconRefFactory === IconKnife) {
                                tutorialStep = tutorialPaintingKnife;
                            } else if (iconRefFactory === IconBlow) {
                                tutorialStep = tutorialPaintingBlow;
                            }
                            if (controlThis._app!.DoShowTutorial(tutorialStep.Key)) {
                                controlThis.setState({tutorialStep: tutorialStep})
                                return true;
                            } else {
                                controlThis.mainRecording!.recordAction({kind:AnimationActionKind.color, color: color});
                                controlThis.mainRecording!.recordAction({kind:AnimationActionKind.paintRadius, rad: controlThis.localPressWeight});
                                controlThis.saveStrokes();
                                return true;
                            }
                        } else {
                            controlThis.setState({pendingActionButton:key});
                            if (onPressAlso !== undefined) {
                                onPressAlso();
                            }
                            return true;
                        }
                    }}

                    StartPushedState={this.state.color === key || this.state.pendingActionButton === key}
                    Haptic = {doHaptic}
                    Height={this._app!.GetScaledPxHeight(31)}
                />);
        };

        const renderSizeBar = (): JSX.Element =>{
            let weightPct = Math.floor(100*(this.state.pressWeight) / (FingerTip.maxFingerRadius));

            return (
                <div className='smesshy-icon-bar h-items h-small-gap group-center ' >
                {this.state.showSize && this.state.paletteMode === false ? <img src='finger-light.svg' height={this._app!.GetScaledPxHeight(38)}/> : <span className='text-large'>dab</span>}
                
                
                <div className='v-items group-center'>
                    {
                        this.state.showSize && this.state.paletteMode === false ?
                        <input
                            type='range'
                            onChange={(val)=> {
                                controlThis.setState({showSize:true, fixedSize:Number.parseInt(val.target.value)})}
                            }
                            min={FingerTip.minFingerRadius}
                            max={FingerTip.maxFingerRadius}
                            step={1}
                            value={this.state.fixedSize === 0 ? FingerTip.standardFingerRadius : this.state.fixedSize}
                            className='smesshy-slider'
                        ></input>
                        : <></>
                    }
                    <div className='h-items smesshy-color-volume-host'>
                        <div style={{backgroundColor:this.state.color, height: '100%', width: `${weightPct}%`}}>
                        </div>

                    </div>
                </div>
                {this.state.showSize && this.state.paletteMode === false ? <img src='finger-smash.svg' height={this._app!.GetScaledPxHeight(38)}/> : <span className='text-large'>glop</span>}
            </div>);
        };

        const renderSubPalette = (key: string):JSX.Element => {
            let scaleX = this._app!.GetScaledPxWidth(1);
            let scaleY = this._app!.GetScaledPxHeight(1);
            return (
                <div className='smesshy-sub-palette' >
                    <Smear 
                        key={key}
                        contextKey={key}
                        AppObject={this.props.AppObject}
                        Color={this.state.color}
                        SampleMode = {this.state.sampleMode}
                        CanCacheCtx = {true}
                        OnClear = {(crc: CanvasRenderingContext2D)=> {
                            const grd = crc.createLinearGradient(160*scaleX, 0, 0, 160*scaleY);
                            grd.addColorStop(0, "darkgrey");
                            grd.addColorStop(1, "white");
                            crc.strokeStyle=grd;
                            crc.lineWidth=10;
                            crc.ellipse(80*scaleX,80*scaleY,70*scaleX,70*scaleY,0,0,2*Math.PI, false);
                            crc.stroke();
                            }
                        }
                        TextureSmash = {false}
                        CentralPull={(d:number):number=>{
                            return (d*d)/(60.0 * 60 * scaleX * scaleX);
                            return 0;
                        }}
                        DryInterval={0}
                        DryRate={0}
                        OnColorSample={(rgb)=>controlThis.addColorSample(rgb)}
                        UseMouse={this.state.useMouse}
                        FixedSize={FingerTip.standardFingerRadius}
                        AvgLightRadius={this.state.avgLightRadius}
                        AvgHeavyRadius={this.state.avgHeavyRadius}
                        PaintRadius={this.state.pressWeight}
                        OnDragStart = {()=>controlThis.onDragStart(false)}
                        OnDragEnd = {()=>controlThis.onDragEnd()}

                    />
                </div>);
        }

        const renderClearButton = ():JSX.Element => {
            return (<>
                {makeIconButton('clear', undefined, IconTrash, 
                this.state.replaying===false && (this.state.allDisabled || this.state.paletteMode), 
                ()=>{
                    STrace.addStep('paint', 'clear ask', '');
                    if (controlThis.state.paletteMode === true) {return;}
                    controlThis.setState({askQuestion:{
                        Top: 64,
                        Title: <span>Clear</span>,
                        Prompts: [<span key={'l1'}>Whats gone is gone.</span>],
                        Options: [{Key:'canvas', Option:<span>Clear Canvas</span>, OnOption:async ():Promise<boolean>=>{
                                                STrace.addStep('paint', 'clear canvas', '');
                                                controlThis.clearMainSmash(true); 
                                                if (controlThis.state.replaying === true) {
                                                    controlThis.setState({replaying: false, allDisabled:false});
                                                }
                                                controlThis.clearAsk();
                                                return true;
                                            }}, 
                                {Key:'palette', Option:<span>Clear Colors</span>, OnOption:async ():Promise<boolean>=>{
                                    STrace.addStep('paint', 'clear palette', '');
                                    controlThis.removeColorSamples(); controlThis.clearAsk();
                                    return true;
                                }}],
                        OnCancel:()=>{
                            STrace.addStep('paint', 'clear reject', '');
                            controlThis.clearAsk()}                                        
                        }});
                        
                        if (controlThis._app!.DoShowTutorial('paintingTrash')) {
                            controlThis.setState({tutorialStep: tutorialPaintingTrash})
                        };
                    })}

                </>
            );
        };
        const renderReplayButton = ():JSX.Element => {
            if (controlThis.behave.showReplay===false) {
                return <></>;
            }
            return (<>
                {makeIconButton('replay', undefined, this.state.replaying===false ? IconPlay : IconStop, 
                this.state.replaying===false && (this.state.allDisabled || this.state.paletteMode),
                ()=>{ 
                    STrace.addStep('paint', 'replay ask', '');
                    if (controlThis.state.paletteMode === true) {return;}
                    if (controlThis.state.replaying === true) {
                        controlThis.stopAndChopReplay();
                        return;
                    }
                    controlThis.setState({askQuestion:{
                        Top: 64,
                        Title: <span>Replay / Redraw</span>,
                        Options: [{Key:'replay', Option:<span>Replay and redraw the current painting</span>, OnOption:async ():Promise<boolean>=>{
                            STrace.addStep('paint', 'replay start', '');
                            controlThis.setState({replaying: true, allDisabled:true});
            controlThis.smearRef!.current!.profileReplay = true;
                            controlThis.smearRef!.current!.replayRecorded(3, 12, controlThis.mainRecording!.recordedAction, ()=>{
                                controlThis.setState({overlayTool: controlThis.smesshyDone(100), replaying: false, allDisabled:false});
                            }); 
                            controlThis.clearAsk();
                            return true;
                        }}],
                        OnCancel:()=>{
                            STrace.addStep('paint', 'replay reject', '');
                            controlThis.clearAsk()}
                        }});
                    if (controlThis._app!.DoShowTutorial('paintingReplay')) {
                        controlThis.setState({tutorialStep: tutorialPaintingReplay})
                    };

                    })}
                </>
            );
        };
        const renderImageButton = ():JSX.Element => {
            if (controlThis.behave.showStamp===false) {
                return <></>;
            }

            return (<>
                {makeIconButton('image', undefined, IconPhoto, this.state.allDisabled || this.state.paletteMode, ()=>{
                    STrace.addStep('paint', 'stamp ask', '');
                    if (controlThis.state.paletteMode === true) {return;}
                    let stampDepth = FingerTip.maxSmashTouchDepth * 2; 
                    const onImageSelect = (evt: ChangeEvent) => { 
                        STrace.addStep('paint', 'stamp select', '');
                        let targ = evt.target as HTMLInputElement;
                        if (targ && targ.files && targ.files.length === 1) {
                            let localUrl = URL.createObjectURL(targ.files[0]);
                            // because of abs position, we need to adjust the smear rect to be relative to the app
                            let smearRect = controlThis.smearRef!.current!.boundingClientRect()!;
                            smearRect = new DOMRect(smearRect.x - controlThis.props.AppShape!.appRectangle.x, 
                                smearRect.y - controlThis.props.AppShape!.appRectangle.y, 
                                smearRect.width, smearRect.height);

                            controlThis.setState({overlayTool: 
                                                    {Element: <ImageLocate
                                                            AppObject={controlThis.props.AppObject}
                                                            AppShape={controlThis.props.AppShape}
                                                            Url={localUrl}
                                                            Bounds={smearRect}
                                                            OnPlacement={(img: HTMLImageElement, offX: number, offY: number, width: number, height: number, scaleWidth: number, scaleHeight: number, rotation: number)=>{
                                                                STrace.addStep('paint', 'stamp place', '');
                                                                controlThis.setState({overlayTool: undefined});
                                                                controlThis.smearRef!.current!.stampImage(img, offX, offY, width, height, scaleWidth, scaleHeight, rotation, stampDepth);
                                                                controlThis.saveStrokes();
                                                            }}
                                                            />,
                                                        OnCancel: ()=>{
                                                            controlThis.setState({overlayTool: undefined});}}
                                                    });
                        }
                        controlThis.clearAsk()
                    };
                    let pressRef: React.RefObject<HTMLInputElement> = React.createRef();
                    controlThis.setState({askQuestion:{
                        Top: 64,
                        Title: <span>Apply a Stamp</span>,
                        Prompts: [<span key={'l1'}>A photo or image can be 'stamped' onto</span>,
                                <span key={'l2'}>the canvas with paint. How thick?</span>,
                                <div key={'l3'} className='h-items h-small-gap' >
                                    <span>dry&lt;</span>
                                    <input
                                        type='range'
                                        onChange={(val)=> {
                                            stampDepth = Number.parseInt(val.target.value);
                                        }}
                                        min={FingerTip.maxSmashTouchDepth/2}
                                        max={FingerTip.maxSmashTouchDepth * 4}
                                        step={2}
                                        defaultValue={stampDepth}
                                        className='smesshy-slider'
                                    ></input>
                                    <span>&gt;goopy</span>
                                </div>],
                        Options: [{Key:'camera', Option:<label>Take a photo
                                                                <input  ref = {pressRef}
                                                                    style={{display:'none'}}
                                                                    type='file'
                                                                    accept='image/*'
                                                                    capture='environment'
                                                                    onChange={onImageSelect}
                                                                    onAbort={ (e)=> controlThis.clearAsk()}
                                                                    />
                                                            </label>, OnOption:async ():Promise<boolean>=>{
                                                                return true;
                                                                //pressRef.current?.click();
                                                            }, PassiveOnly:true}, 
                                {Key:'image', Option:<label>Get from library
                                                                <input ref = {pressRef}
                                                                    style={{display:'none'}}
                                                                    type='file'
                                                                    accept='image/*'
                                                                    onChange={onImageSelect}
                                                                    onAbort={ (e)=>controlThis.clearAsk()}
                                                                    />
                                                            </label>, OnOption:async ():Promise<boolean>=>{
                                                                return true;
                                                                //pressRef.current?.click();
                                                            }, PassiveOnly:true}],
                        OnCancel:()=>{
                            STrace.addStep('paint', 'stamp ask reject', '');
                            controlThis.clearAsk()}
                        }});
                    if (controlThis._app!.DoShowTutorial('paintingStamp')) {
                        controlThis.setState({tutorialStep: tutorialPaintingStamp})
                    };
    
                    })}

                </>
            );
        };

        const onUploadPainting = async (visibilityKind: string, closeOnSave: boolean, lunchboxTo?: Array<string> ) : Promise<boolean> =>{
            let thumb = controlThis.smearRef!.current!.takeSnapshot(50,100)!;
            let preview = controlThis.smearRef!.current!.takeSnapshot(200,400)!;
            controlThis.saveStrokes();
            let actions = controlThis.mainRecording!.serialize();
            controlThis.pushWaitingFrame(controlThis);
            STrace.addStep('paint', 'putUserPaintingAsync', visibilityKind);
            const pi = await controlThis.storageManager!.putUserPaintingAsync(visibilityKind, thumb, preview, actions);
            if (pi !== undefined) {
                if (lunchboxTo !== undefined && lunchboxTo.length > 0 && visibilityKind === 'lunc') {
                    let utcDate = new Date();
                    let userDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
                    let userDays = userDate.getTime() / (1000 * 60 * 60 * 24);
                    STrace.addStep('paint', 'postLunchboxDeliveryAsync', pi.paintingId!);
                    await controlThis.storageManager!.postLunchboxDeliveryAsync({deliveryId: pi.visibilityId!,  toUsers: lunchboxTo, paintingId: pi.paintingId!, userDays: userDays});
                }
                controlThis.clearMainSmash(true); 
            }
            controlThis.popWaitingFrame();
            controlThis.setState({askQuestion: undefined, overlayTool: controlThis.smesshyDone(100)});
            if (closeOnSave) {
                controlThis.setState({navigateTo: {To: -1}});
            }
            return true;
        };

        const onSaveLunchbox = async (closeOnSave: boolean): Promise<boolean>=>{
            const smearRect = controlThis.smearRef!.current!.boundingClientRect()!;
            const dlgWidth = Math.min(280, smearRect.width-20);
            const dlHeight = Math.min(500, smearRect.height-20);
            const dlgRect = new DOMRect(smearRect.x + (smearRect.width - dlgWidth) / 2, smearRect.y + (smearRect.height - dlHeight) / 2, dlgWidth, dlHeight);

            controlThis.setState({askQuestion: undefined, overlayTool: {
                    Element: <LuchboxPicker
                        AppObject={controlThis.props.AppObject}
                        AppShape={controlThis.props.AppShape}
                        Bounds={dlgRect}
                        OnAccept={async (selectedFollowers: Array<string>): Promise<boolean> =>{
                            return await onUploadPainting('lunc', closeOnSave, selectedFollowers);
                        }}
                        OnReject={()=>{
                            controlThis.setState({overlayTool: undefined});
                        }}

                        />
                }});
            return true;
        }
       
        const renderSaveButton = ():JSX.Element => {
            if (controlThis.behave.saveKind === 'none') {
                return <></>;
            }
            let closeOnSave = controlThis.behave.saveKind !== 'any';
            return (<>
                {makeIconButton('upload', undefined, IconUpload, this.state.allDisabled || this.state.paletteMode, ()=>{
                    STrace.addStep('paint', 'upload ask', '');

                    const setUploadAsk = (challengeOpen: boolean, alreadyInChallenge: boolean, stampAllowed: boolean, hasStamp: boolean)=>{

                        if (controlThis._app!.DoShowTutorial('paintingSave')) {
                            controlThis.setState({tutorialStep: tutorialPaintingSave})
                        };

                        let disabled = true;
                        let challengeText = <div className='v-items'>
                            <span>Waiting for</span>
                            <span>the next</span>
                            <span>Challenge</span>
                        </div>;

                        if (challengeOpen) {
                            if (alreadyInChallenge) {
                                challengeText = <div className='v-items'>
                                    <span>Already</span>
                                    <span>in the</span>
                                    <span>Current</span>
                                    <span>Challenge</span>
                                </div>;
                            } else if (stampAllowed === false && hasStamp === true) {
                                challengeText = <div className='v-items'>
                                    <span>No Stamps</span>
                                    <span>Allowed in</span>
                                    <span>Current</span>
                                    <span>Challenge</span>
                                </div>;
                            } else {
                                disabled = false;
                                challengeText = <div className='v-items'>
                                    <span>My Entry</span>
                                    <span>Into the</span>
                                    <span>Current</span>
                                    <span>Challenge</span>
                                </div>
                            }
                        }
    
                        let options = new Array<SmesshyAskOption>();

                        if (controlThis.behave.saveKind === 'chal') {
                            options.push({Key:'challenge', Option:
                            challengeText, OnOption: async (): Promise<boolean>=>{
                                STrace.addStep('paint', 'upload', 'chal');
                                return await onUploadPainting('chal', closeOnSave);
                            }, Disabled: disabled});
                        }
                        if (controlThis.behave.saveKind === 'any' || controlThis.behave.saveKind === 'foll') {
                            options.push({Key:'followers', Option:
                            <div className='v-items'>
                                <span>For All</span>
                                <span>of My</span>
                                <span>Followers</span>
                            </div>, OnOption:async ():Promise<boolean>=>{
                                STrace.addStep('paint', 'upload', 'foll');
                                return await onUploadPainting('foll', closeOnSave);
                            }});
                        }
                        if (controlThis.behave.saveKind === 'any' || controlThis.behave.saveKind === 'lunc') {
                            options.push({Key:'lunchbox', Option:
                            <div className='v-items'>
                                <span>Hide it</span>
                                <span>in a</span>
                                <span>Lunchbox</span>
                            </div>, OnOption:async ():Promise<boolean>=>{
                                STrace.addStep('paint', 'upload', 'lunc');
                                return await onSaveLunchbox( closeOnSave)}});
                        }
                        options.push(
                            {Key:'personal', Option:
                                <div className='v-items'>
                                    <span>My</span>
                                    <span>Private</span>
                                    <span>Pile</span>
                                </div>, OnOption:async ():Promise<boolean>=>{
                                    STrace.addStep('paint', 'upload', 'priv');
                                    return await onUploadPainting('priv', false)}});

                        controlThis.setState({askQuestion:{
                            Top: 64,
                            Title: <span>Save</span>,
                            Prompts: [<span key={'l1'}>Who gets to see this painting?</span>,
                                        <span key={'l2'}></span>],
                            Options: options,
                                OnCancel:()=>{
                                    STrace.addStep('paint', 'upload ask reject', '');
                                    controlThis.clearAsk()}
                            }});

                    }

                    if (controlThis.state.paletteMode === true) {return;}
                    if (this.state.authenticated) {
                        let challengeOpen = false;
                        let hasStamp = controlThis.mainRecording!.stampSeen;
                        let stampAllowed = true;
                        let alreadyInChallenge = false;
                        
                        controlThis.executeAsync(async ()=> {
                            STrace.addStep('paint', 'getChallengeStateAsync', '');
                            controlThis.pushWaitingFrame(controlThis);
                            let challengeState = await controlThis.storageManager!.getChallengeStateAsync(undefined);
                            controlThis.popWaitingFrame();
                            if (challengeState !== undefined) {
                                challengeOpen = challengeState.state === 'paint';
                                stampAllowed = challengeState.stampAllowed;
                                if (challengeOpen) {
                                    STrace.addStep('paint', 'getUserInChallengeAsync', '');
                                    controlThis.pushWaitingFrame(controlThis);
                                    alreadyInChallenge = await controlThis.storageManager!.getUserInChallengeAsync();
                                    controlThis.popWaitingFrame();
                                }
                                setUploadAsk(challengeOpen, alreadyInChallenge, stampAllowed, hasStamp);
                            }
                        });

                        setUploadAsk(challengeOpen, alreadyInChallenge, stampAllowed, hasStamp);
                
                    } else {

                        if (controlThis._app!.DoShowTutorial('paintingSaveAnon')) {
                            controlThis.setState({tutorialStep: tutorialPaintingSaveAnon})
                            return;
                        };
                        
                        try {
                            controlThis.setState({ navigateTo: controlThis.getAuthenticationNavigate('/painting', 'login')});
                        } catch (e: any) {
                            this.props.AppObject.reportException(`PaintingPage upload auth`, 'ex', '', e)
                        }
                    }
                    })}
                </>
            );
        };


        const ReplayObserver = () => {
            useEffect(()=>{
                // if there is something to replay AND we are not loading or doing auth then
                const smear = controlThis.smearRef!.current;
                if (controlThis.pendingReplay !== undefined && controlThis.state.showWaitSpin === false && controlThis.state.navigateTo === undefined && smear) {
                    // if there is no render context, that means thing are still setting up in that child component. set our state to something dumb to trigger a re render
                    if (smear.getPage() === undefined) {
                        this.setState({color: this.state.color});
                    } else {
                        // replay could happen. restore the sequence
                        this.mainRecording!.restore(controlThis.pendingReplay); 
                        controlThis.pendingReplay = undefined;
                        // now, SHOULD we replay? 
                        if (!smear.usingCachedContext || controlThis.behave.forceReplay) {
                            controlThis.setState({replaying: true, allDisabled:true});
                            smear!.replayRecorded(.1, 1, this.mainRecording!.recordedAction, ()=>{
                                controlThis.setState({overlayTool: controlThis.smesshyDone(100), replaying: false, allDisabled:false}); 
                            });
                        }

                        let finalDrawProps = controlThis.mainRecording!.finalDrawPropsUsed()
                        controlThis.setState({color: finalDrawProps[0], pressWeight: finalDrawProps[1] });
                    }
                }
                if (controlThis._app!.DoShowTutorial('paintingInitial')) {
                    controlThis.setState({tutorialStep: tutorialPaintingInitial})
                }
    
            });
            return <></>;
        }
                 
        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowHeader={false}
                ShowFooter={true}
                ShowRefresh={false}
                RequireAuth={this._inPWA}
                OnPopulateAuthenticationState={async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) }}
                Body={
                <>
                <ReplayObserver/>
                <div className='h-items width-100p  smesshy-icon-bar' style={{padding:2}}>
                    <div className='h-items width-100p h-small-gap'>
                        {makeIconButton('palette', undefined, this.state.paletteMode ? IconPainting: IconPalette, this.state.allDisabled, ()=>{
                            STrace.addStep('paint', 'palette', controlThis.state.paletteMode.toString());
                            if (controlThis._app!.DoShowTutorial('paintingPaletteSwap')) {
                                controlThis.setState({tutorialStep: tutorialPaintingPaletteSwap})
                                return;
                            }
                            controlThis.setState({paletteMode: !controlThis.state.paletteMode, pendingActionButton: undefined});
                            if (controlThis._app!.DoShowTutorial('paintingPalette')) {
                                controlThis.setState({tutorialStep: tutorialPaintingPalette})
                            }
                        })}
                        {makeIconButton(undefined, (new tinycolor('rgb(1, 1, 1)')).toHexString(), IconFinger, this.state.allDisabled, undefined)}
                        {makeIconButton(undefined, (new tinycolor('rgb(1, 2, 3)')).toHexString(), IconKnife, this.state.allDisabled, undefined)}
                        {makeIconButton(undefined, (new tinycolor('rgb(254, 254, 254)')).toHexString(), IconBlow, this.state.allDisabled, undefined)}
                        {renderClearButton()}
                        {renderReplayButton()}
                        {renderImageButton()}
                        {renderSaveButton()}
                    </div>
                    <CloseButton AppObject={this._app!} navTo={-1} Haptic = {doHaptic} Height={this._app!.GetScaledPxHeight(31)}/>
                </div>
                <div className='game-page-mid'>
                    <div className='h-items width-100p height-100p'>
                        <div className='height-100p v-items v-small-gap smesshy-icon-bar h-padding-small'> 
                            {makeIconButton(undefined, '#000000', IconSplotch, this.state.allDisabled, undefined)}
                            {makeIconButton(undefined, '#ffffff', IconSplotch, this.state.allDisabled, undefined)}
                            {makeIconButton(undefined, (new tinycolor('rgb(253, 199, 12)')).toHexString(), IconSplotch, this.state.allDisabled, undefined)}
                            {makeIconButton(undefined, (new tinycolor('rgb(190, 12, 0)')).toHexString(), IconSplotch, this.state.allDisabled, undefined)}
                            {makeIconButton(undefined, (new tinycolor('rgb(20, 8, 65)')).toHexString(), IconSplotch, this.state.allDisabled, undefined)}
                            {
                                this.state.addedColors.map((c)=>
                                    makeIconButton(undefined, new tinycolor(c).toHexString(), IconSplotch, this.state.allDisabled, undefined)
                                )
                            }
                            {makeIconButton('sample', undefined, this.state.sampleMode ? IconSample: IconPlus, this.state.allDisabled, ()=>{
                                STrace.addStep('paint', 'samp', '');
                                if (controlThis._app!.DoShowTutorial('paintingSample')) {
                                    controlThis.setState({tutorialStep: tutorialPaintingSample})
                                    return;
                                }
                                if (controlThis.state.sampleMode === true) {
                                    controlThis.setState({sampleMode: false, pendingActionButton: undefined});
                                } else {
                                    controlThis.setState({sampleMode: true, pendingActionButton: 'sample'});
                                }
                            })}
                        </div>
                        { this.state.paletteMode === false ?
                        
                        <Smear 
                            ref={ this.smearRef }
                            key={this.behave.contextKey}
                            contextKey={this.behave.contextKey}
                            AppObject={this.props.AppObject}
                            CanCacheCtx = {this.behave.canCacheCtx}
                            Color={this.state.color}
                            SampleMode = {this.state.sampleMode}
                            TextureSmash = {true}
                            DryInterval={1000 * 2}
                            DryRate={0.025}
                            OnColorSample={(rgb)=>controlThis.addColorSample(rgb)}
                            UseMouse={this.state.useMouse}
                            FixedSize={this.state.fixedSize}
                            AvgLightRadius={this.state.avgLightRadius}
                            AvgHeavyRadius={this.state.avgHeavyRadius}
                            PaintRadius={this.state.pressWeight}
                            OnDragStart = {()=>controlThis.onDragStart(true)}
                            OnDragEnd = {()=>controlThis.onDragEnd()}
                            OnRecordAnimation={(ani)=>controlThis.mainRecording!.recordAction(ani)}

                        />

                        :
                        <div className='height-100p width-100p smesshy-icon-bar sub-pallette-grid'>
                            <div style={{gridRow:1, gridColumnStart: 1, gridColumnEnd:3}} >
                                <Smear 
                                    key={'smesshyMainPaletteContexts'}
                                    contextKey={'smesshyMainPaletteContexts'}
                                    AppObject={this.props.AppObject}
                                    CanCacheCtx = {true}
                                    Color={this.state.color}
                                    SampleMode = {this.state.sampleMode}
                                    OnClear = {(crc: CanvasRenderingContext2D)=> {
                                            crc.fillStyle='black';
                                            crc.fillText("Use this area and these palettes to mix up some new colors.", 10, 20);
                                            crc.fillText("Use the + button to sample the colors and save them.", 10, 40);
                                        }
                                    }
                                    TextureSmash = {false}
                                    DryInterval={0}
                                    DryRate={0}
                                    OnColorSample={(rgb)=>controlThis.addColorSample(rgb)}
                                    UseMouse={this.state.useMouse}
                                    FixedSize={FingerTip.standardFingerRadius}
                                    AvgLightRadius={this.state.avgLightRadius}
                                    AvgHeavyRadius={this.state.avgHeavyRadius}
                                    PaintRadius={this.state.pressWeight}
                                    OnDragStart = {()=>controlThis.onDragStart(false)}
                                    OnDragEnd = {()=>controlThis.onDragEnd()}
        
                                />
                            </div>
                            <div className='h-items group-center each-cross-center' style={{gridRow:2, gridColumn: 1}} >
                                {renderSubPalette('smesshySub1PaletteContexts')}
                            </div>
                            <div className='h-items group-center each-cross-center' style={{gridRow:2, gridColumn: 2}} >
                                {renderSubPalette('smesshySub2PaletteContexts')}
                            </div>
                            <div className='h-items group-center each-cross-center' style={{gridRow:3, gridColumn: 1}} >
                                {renderSubPalette('smesshySub3PaletteContexts')}
                            </div>
                            <div className='h-items group-center each-cross-center' style={{gridRow:3, gridColumn: 2}} >
                                {renderSubPalette('smesshySub4PaletteContexts')}
                            </div>

                        </div>
                        }
                    </div>
                </div>
                {renderSizeBar()}
                </> }
            />,
            this.state);
    }
}

export default PaintingPage;