import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipAction, TipApplication, TipInstrument, TipMotion} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { GPURunner, ProgramInfo } from './GPURunner';
import { TouchPageChange, TouchProgramBuffers, TouchProgramInfo, TouchUniformLocations } from './TouchPageChange';
import { PageChange, PageChangeEntry } from './PageChange';

export interface SmearTouchUniformLocations extends TouchUniformLocations {
    pressureTexNum: WebGLUniformLocation,
};

export interface SmearTouchProgramInfo extends TouchProgramInfo {
    uniformLocations: SmearTouchUniformLocations
};

export interface SmearTouchProgramBuffers  extends TouchProgramBuffers {
    maxDepthTextures: Array<WebGLTexture> | null,
    commitTextures: Array<WebGLTexture> | null,
    moveFromTextures: Array<WebGLTexture> | null,
    moveFracTextures: Array<WebGLTexture> | null,
    pressureTexture: WebGLTexture | null,
    pressureFB: WebGLFramebuffer | null,

};


export class SmearPageChange extends TouchPageChange {

    static processMeasurePressure: SmearTouchProgramInfo = {tag: 'processMeasurePressure', uniformLocations:{}} as SmearTouchProgramInfo;
    static processDistributePressure: SmearTouchProgramInfo = {tag: 'processDistributePressure', uniformLocations:{}} as SmearTouchProgramInfo;
    static processNOP: SmearTouchProgramInfo = {tag: 'processNOP', uniformLocations:{}} as SmearTouchProgramInfo;
    static processAccumulateScrape: SmearTouchProgramInfo = {tag: 'processAccumulateScrape', uniformLocations:{}} as SmearTouchProgramInfo;
    static processApplyAccumulateScrape: SmearTouchProgramInfo = {tag: 'processApplyAccumulateScrape', uniformLocations:{}} as SmearTouchProgramInfo;

    static smearBuffers: SmearTouchProgramBuffers | undefined;

    static fsSourceProcessMeasurePressure = 
    `#version 300 es
#line 5037
uniform highp usampler2D u_workingDepthTexNum;
uniform highp usampler2DArray u_maxDepthTexNum;
uniform highp int u_maxDepthTextureIndex;
in highp vec2 v_texCoordRendered;
in highp vec2 v_texCoordExternal;
layout(location = 0) out highp uvec4 outputGradients;
uniform highp ivec2 u_actionTextureOffset;
` 
    + TouchPageChange.fsActionPositionsSource 
    + TouchPageChange.fsColorMixLatentSource + 
`
#line 5052
void main() {
    // calculate a average pressure for each pixel and the surrounding pixels
    // the max depth is the neutral volume 
    highp uvec2 workingSize = uvec2(textureSize(u_workingDepthTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));
    highp uvec2 actionSize = uvec2(textureSize(u_maxDepthTexNum, 0));
    highp ivec3 actionPos = workingPositionToActionPosition(workingPos, workingSize, u_actionTextureOffset, actionSize, u_maxDepthTextureIndex);
    
    outputGradients = uvec4(0, 0, 0, 0);

    highp uint maxDepth0 = 1024u;
    highp uint maxDepth1 = 1024u;
    highp uint maxDepth2 = 1024u;
    highp uint maxDepth3 = 1024u;
    highp uint maxDepth4 = 1024u;
    highp uint maxDepth5 = 1024u;
    highp uint maxDepth6 = 1024u;
    highp uint maxDepth7 = 1024u;
    highp uint maxDepth8 = 1024u;
    highp uint curDepth0 = 0u;
    highp uint curDepth1 = 0u;
    highp uint curDepth2 = 0u;
    highp uint curDepth3 = 0u;
    highp uint curDepth4 = 0u;
    highp uint curDepth5 = 0u;
    highp uint curDepth6 = 0u;
    highp uint curDepth7 = 0u;
    highp uint curDepth8 = 0u;

    if (actionPos.x >= 0) {
        highp ivec3 actionPosLook = ivec3(actionPos.x, actionPos.y, actionPos.z);
        highp ivec2 workingPosLook = ivec2(workingPos.x, workingPos.y);
        maxDepth4 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
        curDepth4 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        if (maxDepth4 == 0u) {
            return;
        }

        actionPosLook = ivec3(actionPos.x-1, actionPos.y-1, actionPos.z);
        workingPosLook = ivec2(workingPos.x-1, workingPos.y+1);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth0 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth0 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x, actionPos.y-1, actionPos.z);
        workingPosLook = ivec2(workingPos.x, workingPos.y+1);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth1 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth1 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x+1, actionPos.y-1, actionPos.z);
        workingPosLook = ivec2(workingPos.x+1, workingPos.y+1);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth2 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth2 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x-1, actionPos.y, actionPos.z);
        workingPosLook = ivec2(workingPos.x-1, workingPos.y);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth3 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth3 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x+1, actionPos.y, actionPos.z);
        workingPosLook = ivec2(workingPos.x+1, workingPos.y);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth5 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth5 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x-1, actionPos.y+1, actionPos.z);
        workingPosLook = ivec2(workingPos.x-1, workingPos.y-1);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth6 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth6 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x, actionPos.y+1, actionPos.z);
        workingPosLook = ivec2(workingPos.x, workingPos.y-1);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth7 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth7 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }
        actionPosLook = ivec3(actionPos.x+1, actionPos.y+1, actionPos.z);
        workingPosLook = ivec2(workingPos.x+1, workingPos.y-1);
        if (actionPosLook.x >= 0 && actionPosLook.x < int(actionSize.x) && actionPosLook.y >= 0 && actionPosLook.y < int(actionSize.y)) {
            maxDepth8 = texelFetch(u_maxDepthTexNum, actionPosLook, 0).r;
            curDepth8 = texelFetch(u_workingDepthTexNum, workingPosLook, 0).r;
        }

        curDepth0 = maxDepth0 == 0u ? 0u:curDepth0;
        curDepth1 = maxDepth1 == 0u ? 0u:curDepth1;
        curDepth2 = maxDepth2 == 0u ? 0u:curDepth2;
        curDepth3 = maxDepth3 == 0u ? 0u:curDepth3;
        curDepth5 = maxDepth5 == 0u ? 0u:curDepth5;
        curDepth6 = maxDepth6 == 0u ? 0u:curDepth6;
        curDepth7 = maxDepth7 == 0u ? 0u:curDepth7;
        curDepth8 = maxDepth8 == 0u ? 0u:curDepth8;

        maxDepth0 = maxDepth0 == 1024u ? curDepth0 * 1u  : maxDepth0;
        maxDepth1 = maxDepth1 == 1024u ? curDepth1 * 1u : maxDepth1;
        maxDepth2 = maxDepth2 == 1024u ? curDepth2 * 1u : maxDepth2;
        maxDepth3 = maxDepth3 == 1024u ? curDepth3 * 1u : maxDepth3;
        maxDepth4 = maxDepth4 == 1024u ? curDepth4 * 1u : maxDepth4;
        maxDepth5 = maxDepth5 == 1024u ? curDepth5 * 1u : maxDepth5;
        maxDepth6 = maxDepth6 == 1024u ? curDepth6 * 1u : maxDepth6;
        maxDepth7 = maxDepth7 == 1024u ? curDepth7 * 1u : maxDepth7;
        maxDepth8 = maxDepth8 == 1024u ? curDepth8 * 1u : maxDepth8;

        highp float avgPressure = 0.0;
        highp float thisRatio = 0.0;
        highp float maxAreaVolume = float(maxDepth0 + maxDepth1 + maxDepth2 + maxDepth3 + maxDepth4 + maxDepth5 + maxDepth6 + maxDepth7 + maxDepth8);
        highp uint temp1 = 0u;
        highp uint temp2 = 0u;
        highp uint temp3 = 0u;
        highp uint temp4 = 0u;


        if (maxAreaVolume > 0.0) {
            avgPressure = float(curDepth0 + curDepth1 + curDepth2 + curDepth3 + curDepth4 + curDepth5 + curDepth6 + curDepth7 + curDepth8) / maxAreaVolume * 1.0;
            highp float thisGives = round(max(0.0, float(curDepth4) - (avgPressure * float(maxDepth4))));
            
            //temp1 =uint(avgPressure * 100.0);
            //temp2 = uint(thisGives);
            //temp3 = uint(curDepth4);
            //temp4 =uint(avgPressure * 100.0);

            if (thisGives > 0.0) {
                // needs of all around
                highp float need0 = round(max(0.0, avgPressure * float(maxDepth0) - float(curDepth0)));
                highp float need1 = round(max(0.0, avgPressure * float(maxDepth1) - float(curDepth1)));
                highp float need2 = round(max(0.0, avgPressure * float(maxDepth2) - float(curDepth2)));
                highp float need3 = round(max(0.0, avgPressure * float(maxDepth3) - float(curDepth3)));
                highp float need5 = round(max(0.0, avgPressure * float(maxDepth5) - float(curDepth5)));
                highp float need6 = round(max(0.0, avgPressure * float(maxDepth6) - float(curDepth6)));
                highp float need7 = round(max(0.0, avgPressure * float(maxDepth7) - float(curDepth7)));
                highp float need8 = round(max(0.0, avgPressure * float(maxDepth8) - float(curDepth8)));

                //temp1 = uint(maxDepth7);
                //temp2 = uint(curDepth7);
                //temp3 = uint(actionPos.y);
                //temp4 = uint(workingPos.y);

                // what ratio does this contribute to the total need?
                highp float totalNeed = need0 + need1 + need2 + need3 + need5 + need6 + need7 + need8;
                if (totalNeed > 0.0) {
                    thisRatio = thisGives / totalNeed;
                }
            }

        }

        // 3 values stored as shorts so mangle. 2 are floats, 1 is the max depth. give the two floats each 24 bits
        highp uint thisRatioInt = uint(round(thisRatio * 5000.0)) & 0xFFFFFFu;  // *5000 and 24 bits gives range of 3355 to 0.0002
        highp uint thisAvgPressureInt = uint(round(avgPressure * 5000.0)) & 0xFFFFFFu;
        highp uint i1 = (thisAvgPressureInt >> 8); // first 16
        highp uint i2 = ((thisAvgPressureInt & 0xFFu) << 8) | (thisRatioInt >> 16); // last 8 and first 8
        highp uint i3 = thisRatioInt & 0xFFFFu; // last 16
        highp uint i4 = maxDepth4;

        //i1 = temp1;
        //i2 = temp2;
        //i3 = temp3;
        //i4 = temp4;

        outputGradients = uvec4(i1, i2, i3, i4);
    }
    
    return;
}
`;

static fsSourceProcessDistributePressure = 
TouchPageChange.fsSourceProcessGlobals  
+ TouchPageChange.fsColorMixLerpLatentSource + 
`
#line 5191
uniform highp usampler2D u_pressureTexNum;

void main() {
    // output of previous step is the input to this step in the pressure texture
    // working is the current state of the page
    highp uvec2 workingSize = uvec2(textureSize(u_workingDepthTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));

    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    highp float inputDepth = float(outputDepth);
    if (outputDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
    } else {
        outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
        outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    }
    
    // upack the accumulated gradients for each surrounding cell to see how much we should take from them to make us even in their context
    highp uvec4 gradients4 = texelFetch(u_pressureTexNum, workingPos, 0);
    highp float maxDepthThis = float(gradients4.a);
    if (maxDepthThis == 0.0) {
        return;
    }
  
    // is this cell giving up paint?
    highp float avgPressureThere = float(gradients4.r << 8 | gradients4.g >> 8) / 5000.0;

    highp float giveHere = round(max(0.0, (inputDepth) - avgPressureThere * maxDepthThis));
    if (giveHere > 0.95) {
        outputDepth = uint(max(0.0, inputDepth - (giveHere)));
        if (outputDepth == 0u) {
            outputLatentC = emptyLatentPart;
            outputLatentM = smudgeLatentM;
        }
    }

    Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);

    highp uvec4 gradientsThere;
    highp float giveRatioThere;
    highp float thisTakeFromThere;
    highp float thisTakeFrom0 = 0.0;
    highp float thisTakeFrom1 = 0.0;
    highp float thisTakeFrom2 = 0.0;
    highp float thisTakeFrom3 = 0.0;
    highp float thisTakeFrom5 = 0.0;
    highp float thisTakeFrom6 = 0.0;
    highp float thisTakeFrom7 = 0.0;
    highp float thisTakeFrom8 = 0.0;

    highp ivec2 posThere = ivec2(workingPos.x-1, workingPos.y+1);
    highp uint depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos0 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom0 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x, workingPos.y+1);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos1 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom1 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x+1, workingPos.y+1);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos2 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom2 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x-1, workingPos.y);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos3 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom3 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x+1, workingPos.y);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos5 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom5 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x-1, workingPos.y-1);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos6 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom6 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x, workingPos.y-1);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos7 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom7 = thisTakeFromThere;
    }

    posThere = ivec2(workingPos.x+1, workingPos.y-1);
    depthThere = texelFetch(u_workingDepthTexNum, posThere, 0).r;
    highp ivec2 pos8 = posThere;
    if (depthThere > 3u) {
        gradientsThere = texelFetch(u_pressureTexNum, posThere, 0);
        avgPressureThere = float(gradientsThere.r << 8 | gradientsThere.g >> 8) / 5000.0;
        giveRatioThere = float((gradientsThere.g & 0xFFu) << 16 | gradientsThere.b) / 5000.0;
        thisTakeFromThere = max(0.0, avgPressureThere * maxDepthThis - inputDepth) * giveRatioThere;
        thisTakeFrom8 = thisTakeFromThere;
    }

    highp float totalTakeFrom = thisTakeFrom0 + thisTakeFrom1 + thisTakeFrom2 + thisTakeFrom3 + thisTakeFrom5 + thisTakeFrom6 + thisTakeFrom7 + thisTakeFrom8;
    highp uint totalTakeFromInt = uint(round(totalTakeFrom));

    highp float currentOutputDepth = float(outputDepth);

    outputDepth += totalTakeFromInt;
    if (outputDepth < 3u && inputDepth > 0.0) {
        outputDepth = 3u;   
    }


    posThere = pos0;
    highp float takeAmount = thisTakeFrom0;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos1;
    takeAmount = thisTakeFrom1;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos2;
    takeAmount = thisTakeFrom2;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos3;
    takeAmount = thisTakeFrom3;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos5;
    takeAmount = thisTakeFrom5;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos6;
    takeAmount = thisTakeFrom6;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos7;
    takeAmount = thisTakeFrom7;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }

    posThere = pos8;
    takeAmount = thisTakeFrom8;
    if (takeAmount > 0.0) {
        highp uvec4 latentCThere = texelFetch(u_workingLatentCTexNum, posThere, 0);
        highp uvec4 latentMThere = texelFetch(u_workingLatentMTexNum, posThere, 0);
        Latent latentThere = latentFromLatentCM(latentCThere, latentMThere);

        if (currentOutputDepth == 0.0) {
            outputLatent = latentThere;
            currentOutputDepth = takeAmount;
        } else {
            highp float blendWeight = takeAmount / (takeAmount + currentOutputDepth);
            outputLatent = lerpLatent(outputLatent, latentThere, blendWeight);
            currentOutputDepth += takeAmount;
        }
    }


    outputLatentC = latentToLatentC(outputLatent);
    outputLatentM = latentToLatentM(outputLatent);   

    return;
}
`;

static fsSourceProcessNOP = 
TouchPageChange.fsSourceProcessGlobals  
+ TouchPageChange.fsColorMixLerpLatentSource + 
`
#line 5169
void main() {
    highp uvec2 workingSize = uvec2(textureSize(u_workingDepthTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));

    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
    outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    return;
}
`;

static fsSourceProcessAccumulateScrape = 
    TouchPageChange.fsSourceProcessGlobals  
    + TouchPageChange.fsActionPositionsSource 
    + TouchPageChange.fsColorMixLatentSource + 
`
#line 5481
void main() {
    // calculate the amount of paint to scrape off from a cell given the parameter provided. 
    highp uvec2 workingSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));
    highp uvec2 actionSize = uvec2(textureSize(u_actionFromTexNum, 0));
    highp ivec3 actionPos = workingPositionToActionPosition(workingPos, workingSize, u_actionTextureOffset, actionSize, u_actionTextureIndex);
    
    if (actionPos.x < 0) {
        return;
    }
    
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    if (outputDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
        return;
    } else {
        outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
        outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    }

    highp uvec4 vCellInfo = texelFetch(u_actionFromTexNum, actionPos, 0);
    highp uint cellsMatch = vCellInfo.r;
    highp uint curMax = vCellInfo.g; // preferredScrapeDepth
    highp uint maxKindPushDepth = vCellInfo.b;
    highp uint minScrapeDepth = vCellInfo.a;

    if (maxKindPushDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
        outputDepth = uint(0);
        return;
    }
    highp uint moveFwd = 0u;
    if (outputDepth > maxKindPushDepth && cellsMatch > 0u) {
        // when build-up happens, let it ride but not too far
        // some gets left behind because of this little lie
        outputDepth = maxKindPushDepth;
    }

    if (outputDepth > curMax) {
        moveFwd = (outputDepth - curMax);
    } else if (outputDepth > minScrapeDepth) {
        moveFwd = uint(float(outputDepth - minScrapeDepth) * .25);
    } 
    if (moveFwd >= 1u) {
        outputDepth = moveFwd;
    } else {
        outputDepth = uint(0);
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
    }
    return;
}`;

    static fsSourceProcessApplyScrapeAccumulator = 
    TouchPageChange.fsSourceProcessGlobals  
    + TouchPageChange.fsColorMixLerpLatentSource  
    + TouchPageChange.fsActionPositionsSource + 
`
#line 5541

void main() {

    // apply the commit texture. the accumulator adds to the source and removes down to the max depth
    highp uvec2 workingSize = uvec2(textureSize(u_workingLatentCTexNum, 0));
    highp ivec2 workingPos = ivec2(v_texCoordRendered * vec2(workingSize));
    highp uvec2 actionSize = uvec2(textureSize(u_actionFromTexNum, 0));
    highp ivec3 actionPos = workingPositionToActionPosition(workingPos, workingSize, u_actionTextureOffset, actionSize, u_actionTextureIndex);
    
    outputDepth = texture(u_workingDepthTexNum, v_texCoordRendered).r;
    if (outputDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
    } else {
        outputLatentC = texture(u_workingLatentCTexNum, v_texCoordRendered);
        outputLatentM = texture(u_workingLatentMTexNum, v_texCoordRendered);
    }

    if (actionPos.x >= 0) {
        highp uvec4 vMovements = texelFetch(u_actionFromTexNum, actionPos, 0);
        if (vMovements.r != 0u) {
            highp ivec2 workingSrcPos = actionIndexToWorkingPosition(vMovements.r, u_actionTextureOffset, actionSize, workingSize);
            highp uint addDepth = texelFetch(u_accumDepthTexNum, workingSrcPos, 0).r;
            if (addDepth > uint(0)) {
                highp uvec4 addLatentC = texelFetch(u_accumLatentCTexNum, workingSrcPos, 0);
                highp uvec4 addLatentM = texelFetch(u_accumLatentMTexNum, workingSrcPos, 0);
                // blend
                highp float blendWeight = float(addDepth) / (float(addDepth) + float(outputDepth));
                Latent outputLatent = latentFromLatentCM(outputLatentC, outputLatentM);
                outputLatent = lerpLatent(outputLatent, latentFromLatentCM(addLatentC, addLatentM), blendWeight);
                outputLatentC = latentToLatentC(outputLatent);
                outputLatentM = latentToLatentM(outputLatent);
                outputDepth += addDepth;
            }
        }
    }

    // take the accumulator away from the depth
    highp uint accumDepth = texture(u_accumDepthTexNum, v_texCoordRendered).r;
    if (accumDepth > uint(0)) {
        if (outputDepth > accumDepth) {
            outputDepth -= accumDepth;
        } else {
            outputDepth = uint(0);
        }
    } 

    if (outputDepth == 0u) {
        outputLatentC = emptyLatentPart;
        outputLatentM = smudgeLatentM;
    }
}
`;


    static getPrograms(): Array<[source: string, pi: ProgramInfo]> {
        return [[SmearPageChange.fsSourceProcessMeasurePressure, SmearPageChange.processMeasurePressure],
        [SmearPageChange.fsSourceProcessDistributePressure, SmearPageChange.processDistributePressure],
        [SmearPageChange.fsSourceProcessNOP, SmearPageChange.processNOP],
        [SmearPageChange.fsSourceProcessAccumulateScrape, SmearPageChange.processAccumulateScrape],
        [SmearPageChange.fsSourceProcessApplyScrapeAccumulator, SmearPageChange.processApplyAccumulateScrape]
    ];
    }

    static initProgramLocations(gl: WebGL2RenderingContext) {
        SmearPageChange.processMeasurePressure.uniformLocations = {
        } as SmearTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, SmearPageChange.processMeasurePressure);
        SmearPageChange.processDistributePressure.uniformLocations = {
            pressureTexNum: gl.getUniformLocation(SmearPageChange.processDistributePressure.program, 'u_pressureTexNum')!,
        } as SmearTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, SmearPageChange.processDistributePressure);

        SmearPageChange.processNOP.uniformLocations = {
        } as SmearTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, SmearPageChange.processNOP);
        SmearPageChange.processAccumulateScrape.uniformLocations = {
        } as SmearTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, SmearPageChange.processAccumulateScrape);
        SmearPageChange.processApplyAccumulateScrape.uniformLocations = {
        } as SmearTouchUniformLocations;
        TouchPageChange.addProcessProgramLocations(gl, SmearPageChange.processApplyAccumulateScrape);

    }


    static initPermanentStorage(gl: WebGL2RenderingContext, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {
        SmearPageChange.smearBuffers = {} as SmearTouchProgramBuffers;
        SmearPageChange.addPermanentStorage(gl, SmearPageChange.smearBuffers, textureCallback);

        // get the static change textures
    }
    static addPermanentStorage(gl: WebGL2RenderingContext, buffers: SmearTouchProgramBuffers, textureCallback: (radius:number, application: TipApplication) => ChangeTextureSet) {

        if (SmearPageChange.smearBuffers!.maxDepthTextures === undefined) {
            TouchPageChange.addPermanentStorage(gl, SmearPageChange.smearBuffers!, textureCallback);
            SmearPageChange.smearBuffers!.maxDepthTextures = new Array<WebGLTexture>();
            SmearPageChange.smearBuffers!.moveFromTextures = new Array<WebGLTexture>();
            SmearPageChange.smearBuffers!.moveFracTextures = new Array<WebGLTexture>();
            SmearPageChange.smearBuffers!.commitTextures = new Array<WebGLTexture>();


            for (let radius = FingerTip.minFingerRadius; radius <= FingerTip.maxFingerRadius; radius ++) {
                const maxDepthTexture = GPURunner.initTexture(gl, `maxDepthTexture${radius}`, true);
                const moveFromTexture = GPURunner.initTexture(gl, `moveFromTexture${radius}`, true);
                const moveFracTexture = GPURunner.initTexture(gl, `moveFracTexture${radius}`, true);
                const commitTexture = GPURunner.initTexture(gl, `commitTexture${radius}`, true);
                SmearPageChange.smearBuffers!.maxDepthTextures.push(maxDepthTexture);
                SmearPageChange.smearBuffers!.moveFromTextures.push(moveFromTexture);
                SmearPageChange.smearBuffers!.moveFracTextures.push(moveFracTexture);
                SmearPageChange.smearBuffers!.commitTextures.push(commitTexture);

                const smashChangeTextureSet = textureCallback(radius, {instrument: TipInstrument.finger, motion: TipMotion.drag, action: TipAction.unknown, modifier: 0});
    
                gl.bindTexture(gl.TEXTURE_2D_ARRAY, moveFromTexture);
                gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.RGBA16UI, 
                smashChangeTextureSet.width, smashChangeTextureSet.height, smashChangeTextureSet.moveEntries, 0, 
                gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, smashChangeTextureSet.texFrom!);
    
                gl.bindTexture(gl.TEXTURE_2D_ARRAY, moveFracTexture);
                gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.RGBA32F, 
                smashChangeTextureSet.width, smashChangeTextureSet.height, smashChangeTextureSet.moveEntries, 0, 
                gl.RGBA, gl.FLOAT, smashChangeTextureSet.texFrac!);
    
                gl.bindTexture(gl.TEXTURE_2D_ARRAY, commitTexture);
                gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.R32F, 
                smashChangeTextureSet.width, smashChangeTextureSet.height, smashChangeTextureSet.moveEntries, 0, 
                gl.RED, gl.FLOAT, smashChangeTextureSet.texCommit!);
    
                gl.bindTexture(gl.TEXTURE_2D_ARRAY, maxDepthTexture);
                gl.texImage3D(gl.TEXTURE_2D_ARRAY, 0, gl.R16UI, 
                smashChangeTextureSet.width, smashChangeTextureSet.height, smashChangeTextureSet.maxEntries, 0, 
                gl.RED_INTEGER, gl.UNSIGNED_SHORT, smashChangeTextureSet.texMax!);
            }
            SmearPageChange.smearBuffers!.pressureTexture = GPURunner.initTexture(gl, 'pressureTexture');
            SmearPageChange.smearBuffers!.pressureFB = GPURunner.initTextureFramebuffer(gl, 'pressureFB', SmearPageChange.smearBuffers!.pressureTexture);

        }
        buffers.maxDepthTextures = SmearPageChange.smearBuffers!.maxDepthTextures;
        buffers.moveFromTextures = SmearPageChange.smearBuffers!.moveFromTextures;
        buffers.moveFracTextures = SmearPageChange.smearBuffers!.moveFracTextures;
        buffers.commitTextures = SmearPageChange.smearBuffers!.commitTextures;
        buffers.pressureTexture = SmearPageChange.smearBuffers!.pressureTexture;
        buffers.pressureFB = SmearPageChange.smearBuffers!.pressureFB;
    
    }

    static pressureTexNumVal = 15;

    constructor(page: Page, pageX: number, pageY: number, width: number, height: number,
        tipsAndLocations: Array<PageChangeEntry>, tipApplication: TipApplication,
        setWetDry: boolean, blendDried: boolean) {
        super(page, pageX, pageY, width, height, tipsAndLocations, tipApplication, setWetDry, blendDried);
    }


    override initializeStartupProgram(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._initializeStartupProgram(pi, buffers);

    }
    override specializeStartupRoutine(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._specializeStartupRoutine(pi, buffers);
    }

    override runStartupRoutine(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._runStartupRoutine(pi, buffers);

    }
    override finalizeStartup(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._finalizeStartup(pi, buffers);

    }
    override initializeProcessProgram(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._initializeProcessProgram(pi, buffers);
    }
    override specializeProcessRoutine(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        let gl = GPURunner.gl!;
        gl.activeTexture(gl.TEXTURE0 + SmearPageChange.pressureTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D, buffers.pressureTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA16UI,
            this.width, this.height, 0,
            gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, null);

        super._specializeProcessRoutine(pi, buffers);
    }
    specializeProcessRoutineRadius(radius:number): void {
        let idx = radius - FingerTip.minFingerRadius;
        let gl = GPURunner.gl!;
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFromTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, SmearPageChange.smearBuffers!.moveFromTextures![idx]);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionFracTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, SmearPageChange.smearBuffers!.moveFracTextures![idx]);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.actionCommitTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, SmearPageChange.smearBuffers!.commitTextures![idx]);
        gl.activeTexture(gl.TEXTURE0 + TouchPageChange.maxDepthTexNumVal);
        gl.bindTexture(gl.TEXTURE_2D_ARRAY, SmearPageChange.smearBuffers!.maxDepthTextures![idx]);
    }


    override runProcessRoutine(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        let gl = GPURunner.gl!;
        let lastRadius = -1;

        this.accumulateForEntries(
            (tipLoc: PageChangeEntry): boolean => {
                let tip = tipLoc.tip
                let center = tipLoc.center;

                if (tip.radius !== lastRadius) {
                    lastRadius = tip.radius;
                    this.specializeProcessRoutineRadius(tip.radius);
                }
               
                const specialApplication = {instrument: this.tipApplication.instrument, motion: this.tipApplication.motion, action: this.tipApplication.action, modifier: tipLoc.modifier};
                const textures = tip.getChangeTextureIndexes(specialApplication);

                let offsetX = center.x - (textures.width)/2;
                let offsetY = center.y - (textures.height)/2;
        

                let pi = SmearPageChange.processMeasurePressure;

                //let d1 = this.debugMeasureDepthTexture(this.outputDepthTexture!, false)[0];

                let doSpew = false;
                if (doSpew) {
                    doSpew = false;
                }
                if (doSpew) {
                    let d = this.debugMeasureDepthTexture(this.outputDepthTexture!, false)[1];
                    let width = this.width;
                    let height = this.height;
                    for (let i=height-1; i >= 0; i--) {
                        let spew = '';
                        for (let j = 0; j < width; j++) {
                            if (j > 0) {
                                spew += ',';
                            }
                            let idx = i*width + j;
                            let val = d[idx];
                            spew += val.toString();
                        }
                        console.log(spew);
                    }
                }

                pi = SmearPageChange.processMeasurePressure;
                gl.useProgram(pi.program);
                this.initializeProcessProgram(pi);
                gl.uniform2i(pi.uniformLocations.actionTextureOffset, offsetX, offsetY);
                gl.uniform1i(pi.uniformLocations.actionTextureIndex, textures.idxsAction[0]);
                gl.uniform1i(pi.uniformLocations.maxDepthTextureIndex, textures.idxMax);

                //console.log(this.debugMeasureDepthTexture(buffers.initialWetDepthTexture!, false)[0]);


                // special frame buffer for the pressure measurement
                this.stashOutput();
                gl.bindFramebuffer(gl.FRAMEBUFFER, buffers.pressureFB);
                this.bindStashAsWorking();
                gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
                //this.debugSpewLatentTexture(buffers.pressureTexture!);
                //this.debugRenderLatentOutput(pi);

                pi = SmearPageChange.processDistributePressure;
                gl.useProgram(pi.program);
                this.initializeProcessProgram(pi);
                gl.activeTexture(gl.TEXTURE0 + SmearPageChange.pressureTexNumVal);
                gl.bindTexture(gl.TEXTURE_2D, buffers.pressureTexture);
                gl.uniform1i(pi.uniformLocations.pressureTexNum, SmearPageChange.pressureTexNumVal);
                this.useAvailableOutputFrame();
                gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
                
                //this.debugSpewDepthTexture(this.outputDepthTexture!);
                //this.debugRenderLatentOutput(pi);

                //console.log(this.debugMeasureDepthTexture(this.outputDepthTexture!, false)[0]);

            
                // scrape
                pi = SmearPageChange.processAccumulateScrape;
                gl.useProgram(pi.program);
                this.initializeProcessProgram(pi);
                gl.uniform2i(pi.uniformLocations.actionTextureOffset, offsetX, offsetY);
                gl.uniform1i(pi.uniformLocations.actionTextureIndex, textures.idxsAction[0]);

                //this.debugSpewDepthTexture(this.outputDepthTexture!);

                this.stashOutput();
                this.bindStashAsWorking();
                this.directOutputTowardAccumulator();
                gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
                //this.debugRenderLatentOutput(pi);
                //this.debugSpewDepthTexture(this.outputDepthTexture!);

                pi = SmearPageChange.processApplyAccumulateScrape;
                gl.useProgram(pi.program);
                this.initializeProcessProgram(pi);
                gl.uniform2i(pi.uniformLocations.actionTextureOffset, offsetX, offsetY);
                gl.uniform1i(pi.uniformLocations.actionTextureIndex, textures.idxsAction[1]);
                this.useAccumulator();
                this.useAvailableOutputFrame();
                gl.drawArrays(gl.TRIANGLES, 0, PageChange.verts);
                //this.debugRenderLatentOutput(pi);
                //this.debugSpewDepthTexture(this.outputDepthTexture!);

                // let d2 = this.debugMeasureDepthTexture(this.outputDepthTexture!, false)[0];
                // if (d2 !== d1) {
                //     console.log('different', d2!-d1!);
                // }

                if (doSpew) {
                    let width = this.width;
                    let height = this.height;
                    const gl = GPURunner.gl!;
                    let sampFb = gl.createFramebuffer();
                    gl.bindFramebuffer(gl.FRAMEBUFFER, sampFb);
            
                    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, buffers.pressureTexture, 0);
                    let texLat = new Uint16Array(width * height * 4);
                    gl.readPixels(0, 0, this.width, this.height, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, texLat);
                    // for (let i=height-1; i >= 0; i--) {
                    //     let spew = '';
                    //     for (let j = 0; j < width; j++) {
                    //         if (j > 0) {
                    //             spew += ',';
                    //         }
                    //         let idx = (i*width + j) * 4;
                    //         let val = texLat[idx];
                    //         spew += val.toString();
                    //     }
                    //     console.log(spew);
                    // }
                    // for (let i=height-1; i >= 0; i--) {
                    //     let spew = '';
                    //     for (let j = 0; j < width; j++) {
                    //         if (j > 0) {
                    //             spew += ',';
                    //         }
                    //         let idx = (i*width + j) * 4;
                    //         let val = texLat[idx+1];
                    //         spew += val.toString();
                    //     }
                    //     console.log(spew);
                    // }
                    // for (let i=height-1; i >= 0; i--) {
                    //     let spew = '';
                    //     for (let j = 0; j < width; j++) {
                    //         if (j > 0) {
                    //             spew += ',';
                    //         }
                    //         let idx = (i*width + j) * 4;
                    //         let val = texLat[idx+2];
                    //         spew += val.toString();
                    //     }
                    //     console.log(spew);
                    // }
                    // for (let i=height-1; i >= 0; i--) {
                    //     let spew = '';
                    //     for (let j = 0; j < width; j++) {
                    //         if (j > 0) {
                    //             spew += ',';
                    //         }
                    //         let idx = (i*width + j) * 4;
                    //         let val = texLat[idx+3];
                    //         spew += val.toString();
                    //     }
                    //     console.log(spew);
                    // }

                    for (let i=height-1; i >= 0; i--) {
                        let spew = '';
                        for (let j = 0; j < width; j++) {
                            if (j > 0) {
                                spew += ',';
                            }
                            let idx = (i*width + j) * 4;
                            let val = texLat[idx+3];
                            spew += val.toString();
                        }
                        console.log(spew);
                    }
                    for (let i=height-1; i >= 0; i--) {
                        let spew = '';
                        for (let j = 0; j < width; j++) {
                            if (j > 0) {
                                spew += ',';
                            }
                            let idx = (i*width + j) * 4;
                            let val0 = texLat[idx];
                            let val1 = texLat[idx+1];
                            let val = (val0 << 8 | val1 >> 8) / 5000.0;

                            spew += val.toString();
                        }
                        console.log(spew);
                    }
                    for (let i=height-1; i >= 0; i--) {
                        let spew = '';
                        for (let j = 0; j < width; j++) {
                            if (j > 0) {
                                spew += ',';
                            }
                            let idx = (i*width + j) * 4;
                            let val1 = texLat[idx+1];
                            let val2 = texLat[idx+2];
                            let val = ((val1 & 0xFF) << 16 | val2) / 5000.0;

                            spew += val.toString();
                        }
                        console.log(spew);
                    }



                    let d = this.debugMeasureDepthTexture(this.outputDepthTexture!, false)[1];
                    for (let i=height-1; i >= 0; i--) {
                        let spew = '';
                        for (let j = 0; j < width; j++) {
                            if (j > 0) {
                                spew += ',';
                            }
                            let idx = i*width + j;
                            let val = d[idx];
                            spew += val.toString();
                        }
                        console.log(spew);
                    }
          
                }

                return true

            }
        );
    }
    override finalizeProcess(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._finalizeProcess(pi, buffers);

    }
    override initializeFinishProgram(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._initializeFinishProgram(pi, buffers);

    }
    override specializeFinishRoutine(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._specializeFinishRoutine(pi, buffers);

    }

    override runFinishRoutine(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._runFinishRoutine(pi, buffers);

    }
    override finalizeFinish(pi: SmearTouchProgramInfo): void {
        let buffers = SmearPageChange.smearBuffers!;
        super._finalizeFinish(pi, buffers);

    }

}

        