import tinycolor from 'tinycolor2';
import {colorMixKM, getLookUpTable} from './kubelkaMonk';
import {Point2D, SeededRandom} from './utils';
import {ChangeTextureSet, FingerTip, FlowCell, FlowCellMove, TipApplication} from './fingertip';
import { CrcOff, Page } from './page';
import { initialize } from 'workbox-google-analytics';
import { TouchPageChange, TouchProgramInfo } from './TouchPageChange';
import { CirclePageChange } from './circlePageChange';
import { DryPageChange } from './dryPageChange';
import { BlowPageChange } from './blowPageChange';
import { RefreshPageChange } from './refreshPageChange';
import { PageChange } from './PageChange';
import { SmearPageChange } from './smearPageChange';
import smearGenerator from './smearGen';
import { KnifePageChange } from './knifePageChange';

export interface UniformLocations {
    resolution: WebGLUniformLocation,
};

export interface ProgramInfo {
    tag: string,
    program: WebGLProgram,
    attribLocations: {
        vertexPosition: GLint,
        texCoordLocation: GLint,
    },
    uniformLocations: UniformLocations,
};

export interface ProgramBuffers {
    vertexPositionBuffer: WebGLBuffer,
    texCoordBuffer: WebGLBuffer
};


export class GPURunner {
    constructor() {
    }

    static gl: WebGL2RenderingContext | undefined;
    static initializedPrograms: Map<ProgramInfo, Set<string>> ;
  
    static initializeGPU(textureCallback: (radius: number, application: TipApplication) => ChangeTextureSet) {
        if (!GPURunner.gl) {
            console.time('initializing GPU: done');
            console.log('initializing GPU');
            let cvs = document.createElement('canvas');
            
            const gl = cvs.getContext("webgl2", {antialias: false, powerPreference:'high-performance', failIfMajorPerformanceCaveat:true})!;
            if (!gl) {
                throw new Error('WebGL not supported');
            }
            GPURunner.gl = gl;

            GPURunner.initializedPrograms = new Map<ProgramInfo, Set<string>>();
            //let dbg = gl.getExtension('WEBGL_debug_shaders');

            // load vertex and fragment shaders. compile and link
            const vertexShader = GPURunner.loadShader(gl, gl.VERTEX_SHADER, PageChange.vsSource);
            const fragmentShaderPrograms = TouchPageChange.getPrograms().concat(CirclePageChange.getPrograms()).concat(SmearPageChange.getPrograms())
                        .concat(BlowPageChange.getPrograms()).concat(DryPageChange.getPrograms()).concat(RefreshPageChange.getPrograms()).concat(KnifePageChange.getPrograms());

            for (const codeInfo of fragmentShaderPrograms) {
                const shaderProgram = gl.createProgram();
                if (shaderProgram === null) {
                    throw new Error("Failed to create shader program");
                }
                const fragmentShader = GPURunner.loadShader(gl, gl.FRAGMENT_SHADER, codeInfo[0]);

                gl.attachShader(shaderProgram, vertexShader);
                gl.attachShader(shaderProgram, fragmentShader);
                gl.linkProgram(shaderProgram);
    
                if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
                    console.error(`Link failed: ${gl.getProgramInfoLog(shaderProgram)}`);
                    console.error(`vs info-log: ${gl.getShaderInfoLog(vertexShader)}`);
                    console.error(`fs info-log: ${gl.getShaderInfoLog(fragmentShader)}`);
                    throw new Error(`Unable to initialize the shader program`);
                }
                (shaderProgram as any).tag = codeInfo[1].tag;
                codeInfo[1].program = shaderProgram;    
            }


            //let spew = dbg?.getTranslatedShaderSource(fragmentShaderFinish);
            //console.log(spew);

            // get the attribute locations per program
            TouchPageChange.initProgramLocations(gl);
            CirclePageChange.initProgramLocations(gl);
            SmearPageChange.initProgramLocations(gl);
            BlowPageChange.initProgramLocations(gl);
            DryPageChange.initProgramLocations(gl);
            RefreshPageChange.initProgramLocations(gl);
            KnifePageChange.initProgramLocations(gl);

            // set up the permanent textures and buffers 
            PageChange.initPermanentStorage(gl, textureCallback);
            TouchPageChange.initPermanentStorage(gl, textureCallback);
            CirclePageChange.initPermanentStorage(gl, textureCallback);
            SmearPageChange.initPermanentStorage(gl, textureCallback);
            BlowPageChange.initPermanentStorage(gl, textureCallback);
            DryPageChange.initPermanentStorage(gl, textureCallback);
            RefreshPageChange.initPermanentStorage(gl, textureCallback);
            KnifePageChange.initPermanentStorage(gl, textureCallback);

            // attribute pointers
            gl.bindBuffer(gl.ARRAY_BUFFER, PageChange.vertexBuffers!.texCoordBuffer);
            // all programs share these, so use the first one
            gl.vertexAttribPointer(TouchPageChange.externalToLatentProgram.attribLocations.texCoordLocation, 2, gl.FLOAT, false, 0, 0);
            gl.enableVertexAttribArray(TouchPageChange.externalToLatentProgram.attribLocations.texCoordLocation);

            gl.bindBuffer(gl.ARRAY_BUFFER, PageChange.vertexBuffers!.vertexPositionBuffer);
            gl.vertexAttribPointer(TouchPageChange.externalToLatentProgram.attribLocations.vertexPosition, 2, gl.FLOAT, false, 0, 0);
            gl.enableVertexAttribArray(TouchPageChange.externalToLatentProgram.attribLocations.vertexPosition);
    
            gl.flush();
            GPURunner.gl = gl;
            console.timeEnd('initializing GPU: done');
        }
    }

    static doInitializeProgram(program: ProgramInfo, fromCtx: string) : Boolean {
        let initCtxSet = GPURunner.initializedPrograms.get(program);
        if (initCtxSet === undefined) {
            initCtxSet = new Set<string>();
            GPURunner.initializedPrograms.set(program, initCtxSet);
        }
        if (initCtxSet.has(fromCtx)) {
            return false;
        }
        initCtxSet.add(fromCtx);
        return true;
    }

    static initTexture(gl: WebGL2RenderingContext, tag:string, array: boolean = false) : WebGLTexture {
        // create to render to
        const targetTexture = gl.createTexture();
        (targetTexture! as any).tag = tag;
        if (array) {
            gl.bindTexture(gl.TEXTURE_2D_ARRAY, targetTexture);
            // Set the parameters so we can render any size image.
            gl.texParameteri(gl.TEXTURE_2D_ARRAY, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D_ARRAY, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D_ARRAY, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
            gl.texParameteri(gl.TEXTURE_2D_ARRAY, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        }
        else {
            gl.bindTexture(gl.TEXTURE_2D, targetTexture);
            // Set the parameters so we can render any size image.
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        }
        return targetTexture!;
    }

    static initTextureFramebuffer(gl: WebGL2RenderingContext, tag:string, tex0: WebGLTexture, tex1: WebGLTexture | undefined = undefined,
                                    text2: WebGLTexture | undefined = undefined, text3: WebGLTexture | undefined = undefined,
                                    text4: WebGLTexture | undefined = undefined, text5: WebGLTexture | undefined = undefined) : WebGLFramebuffer {
        const fb = gl.createFramebuffer();
        (fb! as any).tag = tag;

        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        // attach the texture as the first color attachment
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, tex0, 0);
        if (tex1) {
            gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT1, gl.TEXTURE_2D, tex1, 0);
            if (text2) {
                gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT2, gl.TEXTURE_2D, text2, 0);
                if (text3) {
                    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT3, gl.TEXTURE_2D, text3, 0);
                    if (text4) {
                        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT4, gl.TEXTURE_2D, text4, 0);
                        if (text5) {
                            gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT5, gl.TEXTURE_2D, text5, 0);
                            gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1, gl.COLOR_ATTACHMENT2, gl.COLOR_ATTACHMENT3, gl.COLOR_ATTACHMENT4, gl.COLOR_ATTACHMENT5]);
                        } else {
                            gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1, gl.COLOR_ATTACHMENT2, gl.COLOR_ATTACHMENT3, gl.COLOR_ATTACHMENT4]);
                        }
                    }
                    gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1, gl.COLOR_ATTACHMENT2, gl.COLOR_ATTACHMENT3]);
                } else {
                    gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1, gl.COLOR_ATTACHMENT2]);
                }
            } else {
                gl.drawBuffers([gl.COLOR_ATTACHMENT0, gl.COLOR_ATTACHMENT1]);
            }
        } else {
            gl.drawBuffers([gl.COLOR_ATTACHMENT0]);
        }

        return fb!;
    }

    static loadShader(gl: WebGL2RenderingContext, type: GLenum, source: string): WebGLShader {
        const shader = gl.createShader(type);
        if (shader === null) {
            throw new Error('Error creating shader');
        }
        // Send the source to the shader object
        gl.shaderSource(shader, source);

        // Compile the shader program
        gl.compileShader(shader);

        return shader;
    }

    static allocateRenderBuffer(tag: string, internalFormat: GLenum, width: number, height: number) : [WebGLRenderbuffer, WebGLFramebuffer, WebGLFramebuffer] {
        let gl = GPURunner.gl!;
        const rb = gl.createRenderbuffer();
        (rb! as any).tag = `${tag}RB`;
        gl.bindRenderbuffer(gl.RENDERBUFFER, rb);
        gl.renderbufferStorage(gl.RENDERBUFFER, internalFormat, width, height);

        let fbR = gl.createFramebuffer();
        (fbR! as any).tag = `${tag}FBR`;
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbR);
        gl.framebufferRenderbuffer(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, rb!);

        let fbW = gl.createFramebuffer();
        (fbW! as any).tag = `${tag}FBW`;
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbW);
        gl.framebufferRenderbuffer(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, rb!);

        return [rb!, fbR!, fbW!];
    }
    static allocatePage(page: Page) {
        if (!GPURunner.gl) {
            throw new Error('GPU not initialized');
        }
        let gl = GPURunner.gl;

        let rbfbrfbw = GPURunner.allocateRenderBuffer('pageVisibleLatentC', gl.RGBA16UI, page.cellsWidth, page.cellsHeight);
        page.buffVisibleLatentC = rbfbrfbw[0];
        page.visibleLatentCFramebufferR = rbfbrfbw[1];
        page.visibleLatentCFramebufferW = rbfbrfbw[2];
        
        rbfbrfbw = GPURunner.allocateRenderBuffer('pageVisibleLatentM', gl.RGBA16UI, page.cellsWidth, page.cellsHeight);
        page.buffVisibleLatentM = rbfbrfbw[0];
        page.visibleLatentMFramebufferR = rbfbrfbw[1];
        page.visibleLatentMFramebufferW = rbfbrfbw[2];

        rbfbrfbw = GPURunner.allocateRenderBuffer('pageDryLatentC', gl.RGBA16UI, page.cellsWidth, page.cellsHeight);
        page.buffDryLatentC = rbfbrfbw[0];
        page.dryLatentCFramebufferR = rbfbrfbw[1];
        page.dryLatentCFramebufferW = rbfbrfbw[2];

        rbfbrfbw = GPURunner.allocateRenderBuffer('pageDryLatentM', gl.RGBA16UI, page.cellsWidth, page.cellsHeight);
        page.buffDryLatentM = rbfbrfbw[0];
        page.dryLatentMFramebufferR = rbfbrfbw[1];
        page.dryLatentMFramebufferW = rbfbrfbw[2];

        rbfbrfbw = GPURunner.allocateRenderBuffer('pageWetLatentC', gl.RGBA16UI, page.cellsWidth, page.cellsHeight);
        page.buffWetLatentC = rbfbrfbw[0];
        page.wetLatentCFramebufferR = rbfbrfbw[1];
        page.wetLatentCFramebufferW = rbfbrfbw[2];

        rbfbrfbw = GPURunner.allocateRenderBuffer('pageWetLatentM', gl.RGBA16UI, page.cellsWidth, page.cellsHeight);
        page.buffWetLatentM = rbfbrfbw[0];
        page.wetLatentMFramebufferR = rbfbrfbw[1];
        page.wetLatentMFramebufferW = rbfbrfbw[2];

        rbfbrfbw = GPURunner.allocateRenderBuffer('pageWetDepth', gl.R16UI, page.cellsWidth, page.cellsHeight);
        page.buffWetDepth = rbfbrfbw[0];
        page.wetDepthFramebufferR = rbfbrfbw[1];
        page.wetDepthFramebufferW = rbfbrfbw[2];

        rbfbrfbw = GPURunner.allocateRenderBuffer('pageWetAge', gl.R8UI, page.cellsWidth, page.cellsHeight);
        page.buffWetAge = rbfbrfbw[0];
        page.wetAgeFramebufferR = rbfbrfbw[1];
        page.wetAgeFramebufferW = rbfbrfbw[2];

        gl.bindRenderbuffer(gl.RENDERBUFFER, null);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);

    }

    static getCurrentViewport() : [number, number] {
        if (!GPURunner.gl || !GPURunner.gl.canvas) {
            throw new Error('GPU not initialized');
        }
        return [GPURunner.gl.canvas.width, GPURunner.gl.canvas.height];
    }

    static initChangeTexture(tag: string, pageFrame: WebGLFramebuffer, internalFormat: GLint, format: GLenum, type: GLenum,
            readX0: number, readY0: number, readX1: number, readY1: number, writeX0: number, writeY0: number, writeX1: number, writeY1: number) : [WebGLTexture, WebGLFramebuffer] {
        let gl = GPURunner.gl!;
        let width = readX1 - readX0;
        let height = readY1 - readY0;

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, pageFrame);
        
        let changeTexture = gl.createTexture()!;
        (changeTexture as any).tag = tag;
        gl.bindTexture(gl.TEXTURE_2D, changeTexture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
        gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat,
            width, height, 0,
            format, type, null);
        let changeFramebuffer = gl.createFramebuffer()!;
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, changeFramebuffer );
        gl.framebufferTexture2D(gl.DRAW_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, changeTexture, 0);

        gl.blitFramebuffer(readX0, readY0, readX1, readY1, writeX0, writeY0, writeX1, writeY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        return [changeTexture, changeFramebuffer];
    }

    static initTouchWetPageChange(page: Page, change: TouchPageChange) {
        let gl = GPURunner.gl!;

        let pageReadX0 = change.pageX;
        let changeWriteX0 = 0;
        let pageReadX1 = change.pageX + change.width;
        let changeWriteX1 = change.width;

        let pageReadY0 = change.pageY;
        let changeWriteY0 = change.height;
        let pageReadY1 = change.pageY + change.height;
        let changeWriteY1 = 0;

        // setup the wet drying time mask
        let changeBufs = GPURunner.initChangeTexture('pageWetAgeTexture', page.wetAgeFramebufferR!, gl.R8UI, gl.RED_INTEGER, gl.UNSIGNED_BYTE, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetAgeTexture = changeBufs[0];
        change.pageWetAgeFramebufferW = changeBufs[1];
        
        // set up the wet color and depth textures and copy from the page
        changeBufs = GPURunner.initChangeTexture('pageWetLatentCTexture', page.wetLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetLatentCTexture = changeBufs[0];
        change.pageWetLatentCFramebufferW = changeBufs[1];

        changeBufs = GPURunner.initChangeTexture('pageWetLatentMTexture', page.wetLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetLatentMTexture = changeBufs[0];
        change.pageWetLatentMFramebufferW = changeBufs[1];

        // setup the depth texture and do the same
        changeBufs = GPURunner.initChangeTexture('pageWetDepthTexture', page.wetDepthFramebufferR!, gl.R16UI, gl.RED_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetDepthTexture = changeBufs[0];
        change.pageWetDepthFramebufferW = changeBufs[1];

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
    }
    static initTouchVisiblePageChange(page: Page, change: TouchPageChange) {
        let gl = GPURunner.gl!;

        let pageReadX0 = change.pageX;
        let changeWriteX0 = 0;
        let pageReadX1 = change.pageX + change.width;
        let changeWriteX1 = change.width;

        let pageReadY0 = change.pageY;
        let changeWriteY0 = change.height;
        let pageReadY1 = change.pageY + change.height;
        let changeWriteY1 = 0;

        // and the dry color texture
        let changeBufs = GPURunner.initChangeTexture('pageDryLatentCTexture', page.dryLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageDryLatentCTexture = changeBufs[0];
        change.pageDryLatentCFramebufferW = changeBufs[1];

        changeBufs = GPURunner.initChangeTexture('pageDryLatentMTexture', page.dryLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageDryLatentMTexture = changeBufs[0];
        change.pageDryLatentMFramebufferW = changeBufs[1];

        // and then the visible color texture
        changeBufs = GPURunner.initChangeTexture('pageVisibleLatentCTexture', page.visibleLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageVisibleLatentCTexture = changeBufs[0];
        change.pageVisibleLatentCFramebufferW = changeBufs[1];

        changeBufs = GPURunner.initChangeTexture('pageVisibleLatentMTexture', page.visibleLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageVisibleLatentMTexture = changeBufs[0];
        change.pageVisibleLatentMFramebufferW = changeBufs[1];

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
    }

    static maxDepth = 0;
    static applyTouchWetPageChange(page: Page, change: TouchPageChange) {
        let gl = GPURunner.gl!;

        let changeReadX0 = 0;
        let pageWriteX0 = change.pageX;
        let changeReadX1 = change.width;
        let pageWriteX1 = change.pageX + change.width;

        let changeReadY0 = change.height;
        let pageWriteY0 = change.pageY;
        let changeReadY1 = 0;
        let pageWriteY1 = change.pageY + change.height;

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetLatentCFramebufferW);
        let fbRead = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetLatentCTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetLatentMFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetLatentMTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetDepthFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetDepthTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.deleteFramebuffer(fbRead);

    }
    static applyTouchVisiblePageChange(page: Page, change: TouchPageChange) {
        let gl = GPURunner.gl!;

        let changeReadX0 = 0;
        let pageWriteX0 = change.pageX;
        let changeReadX1 = change.width;
        let pageWriteX1 = change.pageX + change.width;

        let changeReadY0 = change.height;
        let pageWriteY0 = change.pageY;
        let changeReadY1 = 0;
        let pageWriteY1 = change.pageY + change.height;

        let fbRead = gl.createFramebuffer();

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetAgeFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetAgeTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.visibleLatentCFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageVisibleLatentCTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.visibleLatentMFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageVisibleLatentMTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
        gl.deleteFramebuffer(fbRead);

    }

    static initDryPageChange(page: Page, change: DryPageChange) {
        let gl = GPURunner.gl!;

        let width = change.page.cellsWidth;
        let height = change.page.cellsHeight;
        let pageReadX0 = 0;
        let changeWriteX0 = 0;
        let pageReadX1 = width;
        let changeWriteX1 = width;

        let pageReadY0 = 0;
        let changeWriteY0 = height;
        let pageReadY1 = height;
        let changeWriteY1 = 0;

        // set up the wet depth texture and copy from the page
        let changeBufs = GPURunner.initChangeTexture('pageWetDepthTexture', page.wetDepthFramebufferR!, gl.R16UI, gl.RED_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetDepthTexture = changeBufs[0];
        change.pageWetDepthFramebufferW = changeBufs[1];

        // visible color
        changeBufs = GPURunner.initChangeTexture('pageVisibleLatentCTexture', page.visibleLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageVisibleLatentCTexture = changeBufs[0];
        change.pageVisibleLatentCFramebufferW = changeBufs[1];

        changeBufs = GPURunner.initChangeTexture('pageVisibleLatentMTexture', page.visibleLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageVisibleLatentMTexture = changeBufs[0];
        change.pageVisibleLatentMFramebufferW = changeBufs[1];

        // setup the wet color texture and copy from the page
        changeBufs = GPURunner.initChangeTexture('pageWetLatentCTexture', page.wetLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetLatentCTexture = changeBufs[0];
        change.pageWetLatentCFramebufferW = changeBufs[1];

        changeBufs = GPURunner.initChangeTexture('pageWetLatentMTexture', page.wetLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetLatentMTexture = changeBufs[0];
        change.pageWetLatentMFramebufferW = changeBufs[1];

        // setup the dry color texture and copy from the page
        changeBufs = GPURunner.initChangeTexture('pageDryLatentCTexture', page.dryLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageDryLatentCTexture = changeBufs[0];
        change.pageDryLatentCFramebufferW = changeBufs[1];

        changeBufs = GPURunner.initChangeTexture('pageDryLatentMTexture', page.dryLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageDryLatentMTexture = changeBufs[0];
        change.pageDryLatentMFramebufferW = changeBufs[1];

        // setup the wet drying time mask
        changeBufs = GPURunner.initChangeTexture('pageWetAgeTexture', page.wetAgeFramebufferR!, gl.R8UI, gl.RED_INTEGER, gl.UNSIGNED_BYTE, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetAgeTexture = changeBufs[0];
        change.pageWetAgeFramebufferW = changeBufs[1];

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);

    }

    static applyDryPageChange(page: Page, change: DryPageChange) {
        let gl = GPURunner.gl!;

        let width = change.page.cellsWidth;
        let height = change.page.cellsHeight;

        let changeReadX0 = 0;
        let pageWriteX0 = 0;
        let changeReadX1 = width;
        let pageWriteX1 = width;

        let changeReadY0 = height;
        let pageWriteY0 = 0;
        let changeReadY1 = 0;
        let pageWriteY1 = height;


        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetDepthFramebufferW);
        let fbRead = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetDepthTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetAgeFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetAgeTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetLatentCFramebufferW);
        fbRead = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetLatentCTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.wetLatentMFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageWetLatentMTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);


        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.dryLatentCFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageDryLatentCTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, page.dryLatentMFramebufferW);
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbRead);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageDryLatentMTexture, 0);
        gl.blitFramebuffer(changeReadX0, changeReadY0, changeReadX1, changeReadY1, pageWriteX0, pageWriteY0, pageWriteX1, pageWriteY1, gl.COLOR_BUFFER_BIT, gl.NEAREST);

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);
        gl.deleteFramebuffer(fbRead);

    }

    static initRefreshPageChange(page: Page, change: RefreshPageChange) {
        let gl = GPURunner.gl!;

        let width = change.page.cellsWidth;
        let height = change.page.cellsHeight;

        let pageReadX0 = 0;
        let changeWriteX0 = 0;
        let pageReadX1 = width;
        let changeWriteX1 = width;

        let pageReadY0 = 0;
        let changeWriteY0 = height;
        let pageReadY1 = height;
        let changeWriteY1 = 0;

        // set up the visible color texture and copy from the page
        let changeBufs = GPURunner.initChangeTexture('pageVisibleLatentCTexture', page.visibleLatentCFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageVisibleLatentCTexture = changeBufs[0];
        change.pageVisibleLatentCFramebufferW = changeBufs[1];

        // let fbTemp = gl.createFramebuffer();
        // gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp);
        // gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, change.pageVisibleLatentCTexture, 0);
        // let intoBuff = new Uint16Array(20 * 20 * 4);
        // gl.readPixels(0, 0, 20, 20, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, intoBuff);


        changeBufs = GPURunner.initChangeTexture('pageVisibleLatentMTexture', page.visibleLatentMFramebufferR!, gl.RGBA16UI, gl.RGBA_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageVisibleLatentMTexture = changeBufs[0];
        change.pageVisibleLatentMFramebufferW = changeBufs[1];

        // setup the wet depth texture and copy from the page
        changeBufs = GPURunner.initChangeTexture('pageWetDepthTexture', page.wetDepthFramebufferR!, gl.R16UI, gl.RED_INTEGER, gl.UNSIGNED_SHORT, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetDepthTexture = changeBufs[0];
        change.pageWetDepthFramebufferW = changeBufs[1];

        // setup the wet age texture and copy from the page
        changeBufs = GPURunner.initChangeTexture('pageWetAgeTexture', page.wetAgeFramebufferR!, gl.R8UI, gl.RED_INTEGER, gl.UNSIGNED_BYTE, pageReadX0, pageReadY0, pageReadX1, pageReadY1, changeWriteX0, changeWriteY0, changeWriteX1, changeWriteY1);
        change.pageWetAgeTexture = changeBufs[0];
        change.pageWetAgeFramebufferW = changeBufs[1];

        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, null);
        gl.bindFramebuffer(gl.DRAW_FRAMEBUFFER, null);

    }

    static debugGPUGetBuffer(pageFB: WebGLFramebuffer, intoBuff: Uint16Array, internalFormat: GLint, format: GLenum, readX0: number, readY0: number, readX1: number, readY1: number)  {
        let gl = GPURunner.gl!;

        let width = readX1 - readX0;
        let height = readY1 - readY0;
        let writeX0 = 0;
        let writeY0 = 0;
        let writeX1 = width;
        let writeY1 = height;
        // blit the wet depth buffer to a texture and then readpixels
        let changeBufs = GPURunner.initChangeTexture('debug', pageFB!, internalFormat, format, gl.UNSIGNED_SHORT, readX0, readY0, readX1, readY1, writeX0, writeY0, writeX1, writeY1);
        let changeTexture = changeBufs[0];
        let fbTemp = gl.createFramebuffer();
        gl.bindFramebuffer(gl.READ_FRAMEBUFFER, fbTemp);
        gl.framebufferTexture2D(gl.READ_FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, changeTexture, 0);
        gl.readPixels(0, 0, width, height, format, gl.UNSIGNED_SHORT, intoBuff);
    }

    static debugGPUGetDepth(page: Page, readX0: number, readY0: number, readX1: number, readY1: number): Uint16Array {
        let gl = GPURunner.gl!;
        let width = readX1 - readX0;
        let height = readY1 - readY0;
        let buf = new Uint16Array(width * height);
        GPURunner.debugGPUGetBuffer(page.wetDepthFramebufferR!, buf, gl.R16UI, gl.RED_INTEGER, readX0, readY0, readX1, readY1);
        return buf;
    }
    static debugGPUGetLatent(pageFB: WebGLFramebuffer, readX0: number, readY0: number, readX1: number, readY1: number): Uint16Array {
        let gl = GPURunner.gl!;
        let width = readX1 - readX0;
        let height = readY1 - readY0;
        let buf = new Uint16Array(width * height * 4);
        GPURunner.debugGPUGetBuffer(pageFB, buf, gl.RGBA16UI, gl.RGBA_INTEGER, readX0, readY0, readX1, readY1);
        return buf;
    }

    static clearPage(page: Page) {

        if (!GPURunner.gl) {
            throw new Error('GPU not initialized');
        }
        let gl = GPURunner.gl;

        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffVisibleLatentC!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([0, 0, 0, 65535])); // white is max C3 + a bit of missing rgb
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffVisibleLatentM!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([43000, 33000, 27000, 0]));
        
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffDryLatentC!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([0, 0, 0, 65535]));
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffDryLatentM!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([43000, 33000, 27000, 0]));

        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffWetLatentC!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([0,0,0,0]));
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffWetLatentM!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([0,0,0,1]));

        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffWetDepth!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint16Array([0,0,0,0]));

        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.RENDERBUFFER, page.buffWetAge!);
        gl.clearBufferuiv(gl.COLOR, 0, new Uint8Array([0,0,0,0]));

        gl.deleteFramebuffer(fb);

    }


}

