import {DoublyLinkedPointList, DoublyLinkedPointListNode, Point2D} from './utils';
import {CellKind, Page} from './page';
import tinycolor from 'tinycolor2';
import { NumericLiteral } from 'typescript';

export enum TipInstrument {
    unknown,
    knife,
    finger,
    blow
}
export enum TipMotion {
    unknown,
    press,
    drag,
    lift
}
export enum TipAction {
    unknown,
    paint,
    smudge,
    dry,
    remove
}

export interface TipApplication {
    instrument: TipInstrument;
    motion: TipMotion;
    action: TipAction;
    modifier: number;
}

export interface ChangeTextureSet {
    tipApplication: TipApplication;
    texFrom: Uint16Array | undefined;
    texFrac: Float32Array | undefined;
    texMax: Uint16Array | undefined;
    texCommit: Float32Array | undefined;
    width: number;
    height: number;
    moveEntries: number;
    maxEntries: number;
}

export interface ChangeTextureIndexes {
    application: TipApplication;
    idxsAction: Array<number>;
    idxMax: number;
    width: number;
    height: number;
}

export interface FlowCellMove {
    fCellDest: FlowCell;
    fraction: number;
    removedDepth: number;
    diagonal: boolean;
    priority: number;
}
export interface FlowCell {
    finalKind: CellKind;
    ptOnPrint: Point2D;
    finalDepth: number;
    maxTouchDepth: number;
    maxKindPushDepth: number;
    minScrapeDepth: number;
    preferredScrapeDepth: number;
    maxScrapeDepth: number;
    stagedDepth: number;
    move1: FlowCellMove;
    move2: FlowCellMove;
    move3: FlowCellMove;
    move4: FlowCellMove;
    move5: FlowCellMove;
    modified: boolean;
    dvTipCells: Uint8Array;
    inBounds: boolean;
}

export interface CellDataRow {
    row: number;
    firstCell: number;
    printRelativeStart: number;
    length: number;
    pageRelativeStart: number;
    printRelativeModifiedStart: number;
    lengthModified: number;
}

export class FingerTip {

    // distance measurements are in scale units, number of pixels.
    static standardFingerRadius = 16;
    static maxFingerRadius = 30;
    static minFingerRadius = 5;
    static maxCoronaWidth = 5;
    static maxPrintRadius = FingerTip.maxFingerRadius + FingerTip.maxCoronaWidth;

    // depth measurement are relative to the deepest paint allowed=255 at maxPaintDepth.  
    //static maxMeniscusDepth = 96;
    static maxMeniscusTouchDepth = Math.round(75 * Page.paintDepthScale);
    static maxSmashTouchDepth = Math.round(10 * Page.paintDepthScale);
    
    // how well the paint spreads out when moving
    //static meniscusScrapeDepth = 16; 
    //static smashScrapeDepth = 2; 
    static minMeniscusScrapeDepth = Math.round(5 * Page.paintDepthScale); 
    static preferredMeniscusScrapeDepth = Math.round(8 * Page.paintDepthScale); 
    static maxMeniscusScrapeDepth = Math.round(24 * Page.paintDepthScale); 
    static maxSmashScrapeDepth = Math.round(3.25 * Page.paintDepthScale); 
    static preferredSmashScrapeDepth = Math.round(3 * Page.paintDepthScale); 
    static minSmashScrapeDepth = Math.round(.25 * Page.paintDepthScale); 

    static cellsWidth = FingerTip.maxPrintRadius * 2;
    static cellsTotal = FingerTip.cellsWidth * FingerTip.cellsWidth;

    static tipCenter = new Point2D((FingerTip.cellsWidth - 1)/2, (FingerTip.cellsWidth - 1)/2);

    static normalizeTouchRadius(inputRadius: number, useFixedSize: boolean, fixedSize: number, avgLightRadius: number, avgHeavyRadius: number) : number {
        // we model a radius of 22 as 'average' regular drag
        // at 22, the 'force' is average so with paint that means ...
        // 12 == lightest force, no smashing just smear.
        // 22 == medium, 11 gets used for smash print and the rest pushed out
        // 32 == max force, 32 is smash, rest spreads

        if (useFixedSize === true && fixedSize !== 0) {
            return fixedSize;
        }
        if (inputRadius === 0) {
            return FingerTip.standardFingerRadius;
        }

        if (inputRadius <= avgLightRadius) {
            return FingerTip.minFingerRadius;
        }
        if (inputRadius >= avgHeavyRadius) {
            return FingerTip.maxFingerRadius;
        }
        if (avgLightRadius === avgHeavyRadius) {
            return FingerTip.standardFingerRadius;
        }

        inputRadius -= avgLightRadius;
        let ratioToAvg = inputRadius / (avgHeavyRadius - avgLightRadius);

        return Math.floor(FingerTip.minFingerRadius + (FingerTip.maxFingerRadius - FingerTip.minFingerRadius) * ratioToAvg);
    }



    static coordinatesOfOffset(offset: number) : Point2D {
        let tipX = offset % FingerTip.cellsWidth;
        let tipY = Math.floor(offset / FingerTip.cellsWidth);
        tipX -= FingerTip.cellsWidth / 2;
        tipY -= FingerTip.cellsWidth / 2;
        return new Point2D(tipX, tipY);
    }
    static offsetOfCoordinates(pt: Point2D): number {
        if (pt.x < 0 || pt.y < 0 || pt.x >= FingerTip.cellsWidth || pt.y >= FingerTip.cellsWidth) {
            return -1;
        }
        return pt.y * FingerTip.cellsWidth + pt.x;
    }
    // this just is. 64x64 grid of alpha values (255-a actuall) to draw a realistic finger print in any color
    static printInvertedAlphas = [
        0,0,0,0,42,72,188,181,95,172,189,31,97,196,64,108,166,55,147,190,93,88,159,128,40,15,73,151,222,247,199,142,84,29,31,86,141,192,231,212,170,143,132,133,144,167,202,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,236,195,78,104,216,149,22,161,162,46,166,94,78,175,66,86,179,103,18,44,141,223,189,125,67,19,47,102,158,214,223,167,114,64,21,12,27,56,71,71,54,17,13,0,192,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,111,17,153,236,91,33,192,106,82,174,41,141,129,50,191,147,20,69,180,206,107,26,45,111,173,232,230,169,103,41,19,54,95,138,183,211,209,190,178,175,182,190,170,155,183,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,0,54,54,205,197,53,65,206,70,138,145,44,188,85,124,237,90,60,181,161,63,45,133,213,229,166,107,63,78,126,171,214,214,163,112,67,41,59,78,88,87,80,63,26,12,28,93,205,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,44,129,244,135,16,112,215,60,180,105,87,205,71,188,215,69,160,159,36,70,184,216,131,85,96,136,204,251,251,187,109,40,16,73,147,211,252,254,254,249,241,242,249,251,213,161,111,70,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,218,231,76,22,166,206,68,202,79,148,199,71,222,162,100,223,89,43,181,188,74,23,113,213,249,219,161,98,29,37,89,161,234,208,153,107,71,44,45,52,53,41,25,23,36,56,83,122,183,0,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,205,61,46,211,185,79,205,77,199,173,68,224,105,156,229,54,100,215,83,14,119,230,202,109,41,48,112,181,235,253,207,112,35,23,85,148,194,202,192,185,186,194,199,195,175,143,110,75,50,171,0,0,0,0,0,0,0,0,0,0,0,0,
        0,0,25,87,236,148,94,199,81,227,147,66,202,72,163,226,61,141,189,31,24,179,216,128,76,148,223,252,207,161,115,56,27,102,174,176,111,51,17,16,51,82,104,111,108,115,77,48,29,49,81,129,211,0,0,0,0,0,0,0,0,0,0,0,
        0,102,131,240,99,121,180,66,236,123,67,183,55,172,136,70,145,144,67,40,209,126,118,205,253,203,117,32,14,59,122,188,189,110,27,33,85,140,197,245,250,224,194,169,151,136,134,160,193,187,134,87,63,0,0,0,0,0,0,0,0,0,0,0,
        0,254,202,52,155,139,37,220,104,72,201,42,174,118,95,112,132,155,102,209,67,108,231,143,52,53,113,173,220,171,101,64,106,164,218,244,197,147,101,56,21,22,62,101,131,151,156,145,119,100,125,175,202,0,0,0,0,0,0,0,0,0,0,0,
        0,129,36,186,96,23,200,88,81,221,56,172,87,190,57,155,193,123,196,60,138,224,139,179,219,195,147,88,91,154,222,247,190,126,67,43,84,121,149,179,214,232,180,134,104,97,113,118,147,190,221,177,119,128,0,0,0,0,0,0,0,0,0,0,
        147,90,215,67,22,184,76,92,225,56,173,72,221,79,152,160,160,150,67,83,167,139,109,81,88,129,171,194,163,117,69,56,106,163,221,254,239,196,149,98,48,38,97,160,217,252,254,249,204,146,95,68,70,103,172,0,0,0,0,0,0,0,0,0,
        0,241,60,31,180,62,96,226,53,175,72,122,126,111,123,193,88,182,108,122,123,127,121,110,104,84,79,93,102,113,137,133,110,97,96,93,96,122,138,145,156,149,119,81,50,34,39,54,82,140,209,248,249,225,205,0,0,0,0,0,0,0,0,0,
        0,82,41,178,44,84,232,54,175,97,124,134,74,105,223,34,82,100,125,141,112,88,85,103,109,110,82,63,61,66,79,100,131,168,200,242,171,112,119,144,159,172,184,195,201,202,197,183,161,128,86,47,51,97,158,0,0,0,0,0,0,0,0,0,
        148,54,174,31,61,235,66,168,127,121,102,105,56,179,50,132,245,208,114,104,151,173,167,127,80,67,94,126,132,141,151,159,159,134,116,95,96,120,197,198,121,104,86,68,44,28,31,50,84,133,192,245,227,173,117,112,0,0,0,0,0,0,0,0,
        122,187,26,49,225,85,171,126,98,79,218,14,145,92,142,233,97,111,148,91,79,123,176,193,162,123,104,93,107,112,112,117,117,96,119,52,100,147,142,102,131,218,172,167,165,158,152,158,156,119,74,37,79,142,214,236,0,0,0,0,0,0,0,0,
        226,34,53,221,94,185,109,82,90,217,138,103,95,100,185,42,145,60,102,136,124,129,149,120,112,95,81,77,74,72,71,73,76,81,108,131,153,152,105,105,144,122,97,128,113,69,34,12,35,102,175,236,210,161,137,80,63,180,0,0,0,0,0,0,
        69,78,215,80,197,100,78,110,210,161,55,176,34,220,32,134,74,156,48,109,132,136,130,155,156,154,137,119,108,105,104,109,120,131,135,136,129,112,106,146,155,108,140,130,106,196,231,149,145,150,120,94,123,127,126,120,126,162,0,0,0,0,0,0,
        126,172,41,196,113,81,117,214,127,144,126,77,150,101,79,86,196,113,145,103,209,172,82,85,138,180,202,209,204,191,182,171,164,160,152,121,74,37,73,96,105,121,162,110,139,147,93,133,128,70,103,136,201,213,179,134,89,52,0,0,0,0,0,0,
        88,21,182,123,91,117,146,206,94,107,169,23,214,18,130,145,119,181,144,235,85,136,194,117,61,50,63,85,104,118,129,129,112,80,42,51,103,162,183,127,47,88,127,122,157,75,172,154,66,166,209,168,69,17,66,125,184,240,254,0,0,0,0,0,
        84,195,76,109,108,142,76,106,211,52,122,109,119,111,48,207,154,132,232,60,205,134,34,164,191,147,128,121,117,105,81,74,88,121,169,197,154,77,19,88,191,190,83,133,142,117,124,82,187,171,88,91,177,194,153,113,69,26,62,125,0,0,0,0,
        0,80,140,86,133,33,143,71,191,124,41,176,38,149,92,140,164,189,62,166,137,27,204,81,110,175,178,188,187,158,139,136,143,151,117,43,59,138,197,129,31,67,201,163,64,175,129,138,187,177,182,165,94,36,95,155,198,225,203,162,221,0,0,0,
        207,182,57,126,59,49,151,149,101,152,26,186,107,75,137,168,83,149,67,189,19,190,81,191,109,78,98,100,117,145,149,133,101,62,56,99,101,114,82,91,171,139,28,110,214,100,92,188,131,98,166,185,158,114,52,42,38,11,29,77,173,0,0,0,
        0,42,125,74,218,155,93,209,35,134,108,108,150,19,125,174,142,32,182,39,170,78,166,57,178,248,179,118,85,78,77,76,98,134,174,174,115,37,81,176,158,81,155,105,48,212,180,115,100,155,116,34,73,140,156,139,100,81,50,64,132,0,0,0,
        196,161,86,125,137,196,64,134,114,87,165,30,135,79,140,85,146,90,112,82,117,123,72,122,210,48,49,172,196,161,148,153,163,164,117,58,80,156,164,66,61,180,141,106,174,51,168,98,101,102,57,104,133,150,119,80,80,124,146,114,80,92,0,0,
        0,73,115,121,83,150,140,39,179,23,189,36,98,89,199,110,59,200,16,159,67,151,56,224,63,102,196,88,112,144,148,143,104,54,98,180,212,128,41,92,129,34,77,190,104,195,74,147,188,69,71,106,108,49,71,131,180,183,142,96,63,102,0,0,
        0,139,51,158,119,61,167,24,213,41,141,72,96,48,150,128,96,130,81,93,189,37,210,64,83,145,134,210,158,159,154,154,175,204,182,106,46,128,233,167,50,141,122,26,137,114,169,83,130,191,176,104,26,119,157,69,13,19,73,141,214,254,0,0,
        174,123,191,64,148,45,120,72,147,111,114,82,116,69,115,115,149,45,164,78,129,175,119,59,102,189,144,124,120,130,122,113,98,91,48,59,120,132,86,155,245,122,94,209,125,95,118,113,70,102,69,117,199,106,33,133,169,138,85,37,14,52,167,0,
        0,112,212,33,123,126,52,142,105,151,92,63,120,120,93,75,151,33,147,166,73,176,59,98,171,106,203,120,202,111,88,129,138,81,65,93,105,44,102,200,142,97,173,35,168,211,94,131,66,65,96,147,48,157,217,107,22,22,56,94,110,119,160,0,
        0,132,120,116,60,174,13,216,43,201,53,58,118,135,124,39,106,128,104,175,153,49,142,95,108,190,119,141,103,106,171,98,99,114,115,134,52,103,139,54,151,123,63,188,40,149,225,91,159,60,114,83,229,123,75,203,243,181,136,129,136,149,174,0,
        168,208,36,179,55,145,53,234,25,247,24,107,109,128,152,25,110,198,106,119,148,141,52,147,161,98,124,150,94,122,127,153,119,121,109,19,144,138,23,127,121,84,180,55,189,61,142,224,84,184,62,175,81,215,191,35,63,134,174,176,176,180,192,224,
        0,192,47,168,152,88,144,172,83,235,56,88,110,108,189,43,82,196,140,100,70,149,124,99,90,148,148,96,124,154,111,133,215,111,185,228,134,88,184,56,57,138,94,173,76,195,83,135,212,70,199,69,183,75,151,232,129,63,33,26,27,33,47,73,
        0,94,158,99,232,24,232,110,151,190,133,39,118,87,208,111,137,131,130,119,106,63,122,151,102,109,120,135,121,98,145,101,86,119,45,44,64,144,65,167,71,69,126,83,194,83,174,89,123,200,58,204,76,171,110,51,149,212,241,239,213,168,111,98,
        0,66,218,87,176,89,250,34,223,121,180,23,123,113,178,173,142,110,89,91,96,141,85,73,149,148,109,90,86,136,103,46,137,97,141,115,170,58,174,47,182,89,107,129,111,182,78,168,81,149,197,49,194,113,108,163,67,18,13,13,23,57,99,162,
        0,225,81,168,69,189,189,55,251,58,208,60,137,157,145,133,147,67,96,111,120,155,134,30,117,166,150,118,120,118,98,130,56,54,186,154,92,149,107,218,56,199,207,88,84,155,119,70,196,72,147,211,53,135,180,118,142,203,203,213,237,198,113,42,
        0,164,58,167,53,254,93,141,184,105,139,134,180,136,113,119,151,24,63,75,113,134,161,106,40,127,153,131,120,75,96,135,34,116,171,111,111,92,217,203,178,77,132,156,44,104,181,129,78,224,72,103,235,139,51,117,166,156,110,76,60,59,58,76,
        0,48,200,34,163,226,15,207,80,180,79,169,163,118,92,112,167,48,98,116,83,129,150,128,40,98,122,151,104,133,111,114,77,212,62,115,162,145,123,126,160,50,215,65,141,83,77,137,155,63,222,105,46,196,241,141,40,20,44,53,48,31,18,21,
        207,211,103,40,250,112,63,212,31,225,27,162,169,126,63,115,178,95,106,142,45,133,180,123,63,194,72,139,109,145,85,28,215,75,184,189,96,93,99,191,88,115,157,51,249,37,133,177,95,124,38,159,190,50,112,240,252,203,162,152,143,133,122,107,
        0,184,12,157,225,17,183,98,127,115,104,107,164,135,38,115,149,92,91,154,66,250,216,132,100,105,179,114,131,123,35,100,135,138,119,97,114,105,84,88,188,67,135,60,200,128,184,93,168,149,105,75,78,176,113,34,121,127,73,27,65,119,120,225,
        0,37,47,250,97,65,216,18,185,16,165,107,120,140,35,119,128,132,118,156,122,246,116,139,108,86,222,132,88,109,123,104,71,139,86,155,52,48,101,107,58,35,165,110,37,134,141,32,248,112,112,250,159,137,120,177,162,141,144,148,88,96,117,88,
        126,12,182,196,17,208,88,93,58,30,137,181,70,145,90,134,103,135,123,120,128,162,105,137,127,129,136,99,88,152,125,61,142,70,106,76,86,101,121,127,100,111,120,121,137,45,196,26,249,66,120,98,12,22,63,56,25,31,67,141,120,99,160,126,
        37,95,248,55,132,190,20,100,126,112,41,238,14,159,105,132,98,152,105,115,145,141,104,138,224,132,134,62,155,150,62,188,101,97,115,53,68,58,84,126,98,91,65,137,55,111,237,91,205,61,173,31,212,248,182,213,176,172,214,67,162,163,44,117,
        137,240,115,76,232,49,112,52,173,111,62,171,25,132,83,79,139,168,43,83,173,143,103,123,160,139,82,123,190,37,208,47,150,146,95,110,178,174,154,151,171,173,110,65,168,235,140,177,94,132,57,170,192,105,198,147,133,156,49,172,74,102,186,81,
        0,165,51,200,105,68,87,160,91,101,129,99,74,113,57,88,159,149,14,85,207,141,116,109,136,113,99,191,73,140,98,154,80,117,88,160,142,98,82,73,88,141,202,184,174,125,193,150,75,149,75,151,116,241,112,168,105,79,127,54,172,104,53,168,
        0,47,180,158,19,153,37,213,123,40,159,19,137,79,20,68,206,144,20,113,218,133,127,104,152,153,142,90,147,138,95,134,109,57,148,121,55,85,120,124,101,58,50,110,195,216,109,129,152,39,104,149,214,82,178,70,129,85,121,170,30,100,178,42,
        0,164,241,29,136,58,172,120,161,18,148,12,143,83,29,45,231,173,31,113,134,119,127,158,240,120,115,179,152,105,169,101,70,126,44,76,157,175,165,158,168,181,151,110,62,115,188,79,63,106,195,163,78,133,68,157,87,203,125,22,154,143,75,77,
        0,0,144,72,150,79,234,54,124,76,66,38,109,80,40,38,216,189,66,106,127,124,171,155,149,112,123,156,119,174,127,145,141,17,91,136,102,43,22,40,35,28,89,169,219,117,62,150,140,216,101,96,80,105,147,130,224,72,64,127,63,110,39,88,
        0,0,62,193,26,203,95,142,25,135,12,142,24,131,65,39,192,182,145,109,158,126,223,60,132,180,80,74,84,115,137,188,107,166,102,26,69,144,184,190,187,189,152,109,109,137,128,164,188,55,110,51,109,104,162,130,72,110,43,83,149,70,185,157,
        0,0,184,65,102,143,91,91,116,73,53,154,35,141,97,52,154,164,140,97,227,139,150,108,155,114,118,113,108,72,121,66,175,166,120,135,150,153,148,139,136,128,131,132,110,155,200,92,47,58,44,139,89,132,50,167,122,37,151,136,101,239,142,18,
        0,0,169,47,142,121,131,93,167,19,201,47,121,121,119,82,129,152,156,86,135,135,130,202,73,72,210,92,70,177,76,130,133,76,111,160,162,160,149,152,158,181,219,215,149,86,94,61,35,147,143,131,132,51,198,72,92,174,84,141,240,121,28,186,
        0,0,170,137,150,123,103,198,24,168,143,18,216,66,113,172,83,142,141,118,61,118,152,234,67,161,51,207,93,59,174,123,48,169,201,118,45,17,40,58,61,51,40,95,157,130,26,99,142,94,180,85,99,183,58,152,124,97,191,194,51,50,194,94,
        0,0,224,162,100,133,190,28,153,179,17,157,200,52,147,163,51,220,209,129,32,131,230,129,191,85,164,54,199,100,80,166,199,66,37,107,159,166,142,125,115,118,116,63,17,72,118,71,162,146,52,163,119,106,160,68,137,215,111,20,122,155,77,124,
        0,0,245,89,172,157,33,170,117,19,160,235,100,177,69,142,65,129,212,143,35,198,151,114,227,105,152,157,35,179,129,149,167,120,134,132,114,93,82,85,99,121,150,176,150,101,157,165,68,128,128,78,172,96,65,204,168,44,74,184,81,110,99,60,
        0,0,216,204,114,105,178,48,47,201,202,90,171,143,164,36,160,47,147,177,77,161,71,221,76,247,72,158,197,41,101,176,102,141,186,153,123,100,107,121,121,101,79,99,162,147,120,165,133,54,151,151,66,148,187,96,35,163,160,90,176,64,128,189,
        0,0,0,200,211,130,19,120,189,101,134,201,123,153,58,89,146,127,85,163,127,100,130,163,148,90,240,105,103,212,128,30,111,165,120,92,119,166,175,165,155,150,131,87,119,185,122,38,127,145,39,93,212,136,78,126,206,85,140,126,51,195,125,29,
        0,0,0,0,105,71,201,122,126,208,149,73,142,40,72,228,37,202,31,139,211,19,207,40,240,152,60,213,202,83,73,152,128,32,52,117,140,120,99,80,63,53,66,91,51,61,116,115,35,62,177,198,99,125,213,135,60,182,67,115,213,65,74,224,
        0,0,0,0,225,212,92,221,197,96,117,120,15,86,227,62,124,132,121,125,172,144,83,179,79,242,214,81,84,163,196,96,37,123,135,114,73,53,50,60,80,105,139,157,139,78,17,82,185,246,146,120,213,182,48,125,190,63,191,160,33,147,238,94,
        0,0,0,0,0,102,241,196,92,177,113,14,131,199,42,84,224,38,209,51,75,210,111,101,196,84,143,205,218,161,107,171,218,110,26,25,56,96,142,170,163,120,57,20,72,145,153,111,195,126,185,222,88,63,206,141,104,193,73,60,214,200,44,94,
        0,0,0,0,0,0,169,135,238,105,43,187,127,15,99,223,58,145,86,164,145,98,136,130,38,133,163,94,32,30,63,104,145,175,189,153,126,110,105,110,104,94,108,243,191,81,127,98,89,234,142,35,149,206,100,170,113,17,128,249,162,24,150,229,
        0,0,0,0,0,0,0,252,103,110,199,55,19,152,175,50,187,163,129,71,84,130,211,209,169,62,31,127,177,176,158,147,126,102,91,88,88,91,101,118,138,149,152,77,117,172,35,123,195,53,90,191,114,146,180,40,53,205,252,125,46,207,212,61,
        0,0,0,0,0,0,0,158,174,156,20,63,187,92,84,150,66,50,148,186,128,64,62,77,105,250,204,142,103,72,47,27,38,62,81,88,80,70,62,64,74,106,159,216,124,44,167,105,57,158,104,112,222,110,19,132,248,219,72,83,239,170,47,207,
        0,0,0,0,0,0,0,253,148,48,147,123,85,136,63,49,174,194,80,58,156,188,149,134,88,72,67,61,60,77,110,133,136,136,133,125,114,112,120,127,114,102,79,43,135,150,82,161,123,63,202,188,43,73,216,236,120,20,133,243,109,52,214,93,
        0,0,0,0,0,0,0,0,245,183,50,147,95,32,156,209,80,81,167,121,32,68,138,168,170,172,178,169,176,182,179,170,165,166,162,131,105,91,87,94,113,145,182,160,101,178,149,43,145,225,95,38,169,250,146,27,42,194,206,51,88,217,73,183,
        0,0,0,0,0,0,0,0,0,207,187,33,105,225,119,41,179,135,23,105,217,194,94,56,93,120,106,68,43,46,65,82,85,79,84,112,120,152,238,237,212,158,111,153,156,43,99,215,131,33,132,244,201,56,16,120,233,124,37,159,183,88,219,255];
        
        static setCellDepths(fCellSrc: FlowCell) {
            if (fCellSrc.finalKind == CellKind.corona) {
                fCellSrc.maxTouchDepth = Page.maxCoronaDepth;
                fCellSrc.maxScrapeDepth = Page.maxCoronaDepth;
                fCellSrc.minScrapeDepth = Page.maxCoronaDepth;
                fCellSrc.preferredScrapeDepth = Page.maxCoronaDepth;
                fCellSrc.maxKindPushDepth = Page.maxCoronaDepth;
            } else if (fCellSrc.finalKind == CellKind.meniscus) {
                fCellSrc.maxTouchDepth = FingerTip.maxMeniscusTouchDepth;
                fCellSrc.maxScrapeDepth = FingerTip.maxMeniscusScrapeDepth;
                fCellSrc.minScrapeDepth = FingerTip.minMeniscusScrapeDepth;
                fCellSrc.preferredScrapeDepth = FingerTip.preferredMeniscusScrapeDepth;
                fCellSrc.maxKindPushDepth = Page.maxCoronaDepth;
            } else if (fCellSrc.finalKind == CellKind.smash) {
                let a = FingerTip.printInvertedAlphas[fCellSrc.ptOnPrint.x + fCellSrc.ptOnPrint.y * 64];
                // flip it, make it a float and darken a bit
                a = ((255-a)/255.0);
                fCellSrc.maxTouchDepth = FingerTip.maxSmashTouchDepth;
                fCellSrc.minScrapeDepth = FingerTip.minSmashScrapeDepth;
                fCellSrc.preferredScrapeDepth =  FingerTip.minSmashScrapeDepth + Math.round(a * FingerTip.preferredSmashScrapeDepth);
                fCellSrc.maxScrapeDepth = fCellSrc.preferredScrapeDepth + 1;
                fCellSrc.maxKindPushDepth = FingerTip.maxMeniscusScrapeDepth;
            } else if (fCellSrc.finalKind == CellKind.knife) {
                fCellSrc.maxTouchDepth = 0;
                fCellSrc.maxScrapeDepth = 0;
                fCellSrc.minScrapeDepth = 0;
                fCellSrc.preferredScrapeDepth = 0; 
            } else if (fCellSrc.finalKind == CellKind.background) {
                fCellSrc.maxTouchDepth = 0;
                fCellSrc.maxScrapeDepth = 0;
                fCellSrc.minScrapeDepth = 0;
                fCellSrc.preferredScrapeDepth = 0;
            } else {
                console.log("setCellDepths: unknown kind");
            }
            
        }        

    // there are a somewhat limited number of circles that matter, so pre compute them
    // these are the coordinates of the top left quadrant for filled pixels in a FingerTip.maxFingerRadius X FingerTip.maxFingerRadius grid.

    static circleQuad35= [29,34, 25,28, 22,25, 20,21, 18,19, 17,17, 15,16, 14,14, 13,13, 11,12, 10,11,  9,10,  9, 9,  8, 8,  7, 7,  6, 6,  6, 6,  5, 5,  4, 4,  4, 4,  3, 3,  3, 3,  2, 2,  2, 2,  2, 2,  1, 2,  1, 1,  1, 1,  1, 1,  0, 0,  0, 0,  0, 0,  0, 0,  0, 0,  0, 0];
    static circleQuad34= [-1,-1, 29,34, 26,28, 22,25, 20,21, 18,20, 17,18, 15,16, 14,15, 13,13, 12,12, 11,11, 10,10,  9, 9,  8, 8,  7, 8,  7, 7,  6, 6,  5, 6,  5, 5,  4, 5,  4, 4,  3, 3,  3, 3,  3, 3,  3, 3,  2, 2,  2, 2,  2, 2,  1, 1,  1, 1,  1, 1,  1, 1,  1, 1,  1, 1];
    static circleQuad33= [-1,-1, -1,-1, 29,34, 26,28, 22,25, 21,22, 19,20, 17,18, 16,16, 14,15, 13,14, 12,13, 11,12, 10,11,  9,10,  9, 9,  8, 8,  7, 7,  7, 7,  6, 6,  6, 6,  5, 5,  4, 5,  4, 4,  4, 4,  4, 4,  3, 3,  3, 3,  3, 3,  2, 2,  2, 2,  2, 2,  2, 2,  2, 2,  2, 2];
    static circleQuad32= [-1,-1, -1,-1, -1,-1, 29,34, 26,28, 23,25, 21,22, 19,20, 17,18, 16,17, 15,15, 14,14, 13,13, 12,12, 11,11, 10,10,  9, 9,  8, 9,  8, 8,  7, 7,  7, 7,  6, 6,  6, 6,  5, 5,  5, 5,  5, 5,  4, 4,  4, 4,  4, 4,  3, 3,  3, 3,  3, 3,  3, 3,  3, 3,  3, 3];
    static circleQuad31= [-1,-1, -1,-1, -1,-1, -1,-1, 29,34, 26,28, 23,25, 21,22, 19,20, 18,19, 16,17, 15,16, 14,15, 13,13, 12,12, 11,12, 10,11, 10,10,  9, 9,  8, 9,  8, 8,  7, 7,  7, 7,  6, 6,  6, 6,  6, 6,  5, 5,  5, 5,  5, 5,  4, 4,  4, 4,  4, 4,  4, 4,  4, 4,  4, 4];
    static circleQuad30= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 29,34, 26,28, 23,25, 21,22, 20,21, 18,19, 17,17, 16,16, 14,15, 13,14, 13,13, 12,12, 11,11, 10,10, 10,10,  9, 9, 8,  9,  8, 8,  7, 7,  7, 7,  7, 7,  6, 6,  6, 6,  6, 6,  5, 5,  5, 5,  5, 5,  5, 5,  5, 5,  5, 5];
    static circleQuad29= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 29,34, 26,28, 23,25, 22,23, 20,21, 18,19, 17,18, 16,16, 15,15, 14,14, 13,13, 12,12, 11,12, 11,11, 10,10, 10,10,  9, 9,  8, 9,  8, 8,  8, 8,  7, 7,  7, 7,  7, 7,  6, 6,  6, 6,  6, 6,  6, 6,  6, 6,  6, 6];
    static circleQuad28= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 29,34, 26,29, 24,25, 22,23, 20,21, 19,19, 17,18, 16,17, 15,15, 14,14, 13,14, 13,13, 12,12, 11,11, 11,11, 10,10, 10,10,  9, 9,  9, 9,  8, 8,  8, 8,  8, 8,  7, 8,  7, 7,  7, 7,  7, 7,  7, 7,  7, 7];
    static circleQuad27= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 30,34, 26,29, 24,26, 22,23, 20,21, 19,20, 18,18, 16,17, 15,16, 15,15, 14,14, 13,13, 12,13, 12,12, 11,11, 11,11, 10,10, 10,10,  9,10,  9, 9,  9, 9,  9, 9,  8, 8,  8, 8,  8, 8,  8, 8,  8, 8];
    static circleQuad26= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 30,34, 27,29, 24,26, 22,23, 21,22, 19,20, 18,19, 17,17, 16,16, 15,15, 14,15, 14,14, 13,13, 12,13, 12,12, 11,11, 11,11, 11,11, 10,10, 10,10, 10,10,  9, 9,  9, 9,  9, 9,  9, 9,  9, 9];
    static circleQuad25= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 30,34, 27,29, 24,26, 23,24, 21,22, 20,20, 18,19, 17,18, 16,17, 16,16, 15,15, 14,14, 14,14, 13,13, 12,13, 12,12, 12,12, 11,11, 11,11, 11,11, 10,10, 10,10, 10,10, 10,10, 10,10];
    static circleQuad24= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 30,34, 27,29, 25,26, 23,24, 21,22, 20,21, 19,19, 18,18, 17,17, 16,16, 15,16, 15,15, 14,14, 14,14, 13,13, 13,13, 12,12, 12,12, 12,12, 11,11, 11,11, 11,11, 11,11, 11,11];
    static circleQuad23= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 30,34, 27,29, 25,26, 23,24, 22,22, 20,21, 19,20, 18,19, 17,18, 17,17, 16,16, 15,15, 15,15, 14,14, 14,14, 13,13, 13,13, 13,13, 12,12, 12,12, 12,12, 12,12, 12,12];
    static circleQuad22= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 30,34, 27,30, 25,26, 23,24, 22,23, 21,21, 20,20, 19,19, 18,18, 17,17, 16,17, 16,16, 15,15, 15,15, 14,14, 14,14, 14,14, 13,14, 13,13, 13,13, 13,13, 13,13];
    static circleQuad21= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 27,30, 25,26, 24,25, 22,23, 21,22, 20,20, 19,19, 18,19, 18,18, 17,17, 16,17, 16,16, 15,15, 15,15, 15,15, 15,15, 14,14, 14,14, 14,14, 14,14];
    static circleQuad20= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 27,30, 26,27, 24,25, 23,23, 21,22, 20,21, 20,20, 19,19, 18,18, 18,18, 17,17, 16,17, 16,16, 16,16, 16,16, 15,15, 15,15, 15,15, 15,15];
    static circleQuad19= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 28,30, 26,27, 24,25, 23,24, 22,22, 21,21, 20,20, 19,20, 19,19, 18,18, 18,18, 17,17, 17,17, 17,17, 16,16, 16,16, 16,16, 16,16];
    static circleQuad18= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 28,30, 26,27, 25,25, 23,24, 22,23, 21,22, 21,21, 20,20, 19,19, 19,19, 18,18, 18,18, 18,18, 17,17, 17,17, 17,17, 17,17];
    static circleQuad17= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 28,30, 26,27, 25,26, 24,24, 23,23, 22,22, 21,21, 20,21, 20,20, 19,19, 19,19, 19,19, 18,18, 18,18, 18,18, 18,18];
    static circleQuad16= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 28,30, 27,28, 25,26, 24,25, 23,24, 22,23, 22,22, 21,21, 20,21, 20,20, 20,20, 19,19, 19,19, 19,19, 19,19];
    static circleQuad15= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 31,34, 29,31, 27,28, 26,26, 25,25, 24,24, 23,23, 22,22, 22,22, 21,21, 21,21, 20,21, 20,20, 20,20, 20,20];
    static circleQuad14= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 29,31, 27,28, 26,27, 25,25, 24,24, 23,24, 23,23, 22,22, 22,22, 22,22, 21,21, 21,21, 21,21];
    static circleQuad13= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 29,31, 28,28, 26,27, 25,26, 25,25, 24,24, 23,23, 23,23, 23,23, 22,22, 22,22, 22,22];
    static circleQuad12= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 29,31, 28,29, 27,27, 26,26, 25,25, 24,25, 24,24, 24,24, 23,23, 23,23, 23,23];
    static circleQuad11= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 30,31, 28,29, 27,28, 26,27, 26,26, 25,25, 25,25, 24,24, 24,24, 24,24];
    static circleQuad10= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 30,31, 29,29, 28,28, 27,27, 26,26, 26,26, 25,25, 25,25, 25,25];
    static circleQuad09= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 30,31, 29,30, 28,28, 27,28, 27,27, 26,26, 26,26, 26,26];
    static circleQuad08= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 31,31, 29,30, 29,29, 28,28, 27,27, 27,27, 27,27];
    static circleQuad07= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 32,34, 31,32, 30,30, 29,29, 28,29, 28,28, 28,28];
    static circleQuad06= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 33,34, 31,32, 30,31, 30,30, 29,29, 29,29];
    static circleQuad05= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 33,34, 32,32, 31,31, 30,30, 30,30];
    static circleQuad04= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 33,34, 32,33, 31,32, 31,31];
    static circleQuad03= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 34,34, 33,33, 32,32];
    static circleQuad02= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 34,34, 33,33];
    static circleQuad01= [-1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, -1,-1, 34,34];
    
    static circleQuads = [[], FingerTip.circleQuad01, FingerTip.circleQuad02, FingerTip.circleQuad03, FingerTip.circleQuad04, FingerTip.circleQuad05, FingerTip.circleQuad06, FingerTip.circleQuad07, FingerTip.circleQuad08, FingerTip.circleQuad09, FingerTip.circleQuad10, FingerTip.circleQuad11, FingerTip.circleQuad12, FingerTip.circleQuad13, FingerTip.circleQuad14, FingerTip.circleQuad15, FingerTip.circleQuad16, FingerTip.circleQuad17, FingerTip.circleQuad18, FingerTip.circleQuad19, FingerTip.circleQuad20, FingerTip.circleQuad21, FingerTip.circleQuad22, FingerTip.circleQuad23, FingerTip.circleQuad24, FingerTip.circleQuad25, FingerTip.circleQuad26, FingerTip.circleQuad27, FingerTip.circleQuad28, FingerTip.circleQuad29, FingerTip.circleQuad30, FingerTip.circleQuad31, FingerTip.circleQuad32, FingerTip.circleQuad33, FingerTip.circleQuad34, FingerTip.circleQuad35];
    
    static newPaintVolume(paintRadius: number, smashOnly: boolean) : number {

        // heuristics based on taste, smallest radius gives no corona
        // largest radius gives max
        let coronaGoal = (.5 * FingerTip.maxCoronaWidth) * (paintRadius - FingerTip.minFingerRadius) / (FingerTip.maxFingerRadius - FingerTip.minFingerRadius);

        let smashRadius = FingerTip.smashRadiusOf(paintRadius)
        let smashArea = smashRadius * smashRadius * Math.PI;
        let meniscusArea = (paintRadius * paintRadius * Math.PI) - smashArea;
        let coronaArea = ((paintRadius + coronaGoal) * (paintRadius + coronaGoal) * Math.PI) - (meniscusArea + smashArea);

        let avgSmash = FingerTip.minSmashScrapeDepth + FingerTip.preferredSmashScrapeDepth / 2;
        let totalPaint = smashArea * avgSmash + meniscusArea * FingerTip.maxMeniscusTouchDepth + coronaArea * Page.maxCoronaDepth;
        if (smashOnly) {
            totalPaint = smashArea * avgSmash;
        }
        return totalPaint;
    }

    static doFilledCircle(outerRadius: number, innerRadius: number, withAction: (i:number, pt: Point2D)=>void) {
        if (outerRadius === 0) {
            return;
        }
        // pattern for outside and inside edges of donut thing. inclusive. so when O==I we get just an outline
        let circleQuadO = FingerTip.circleQuads[outerRadius];
        let circleQuadI = FingerTip.circleQuads[innerRadius];

        // coordinates are reported for finger width grid, but size can go beyond that
        // so there are a bunch of +3 and -3 things here. sorry
        const fillQuad = (translate: (iX:number, iY:number)=>[x:number, y:number])=> {
            for(let iRowPair = 0; iRowPair < FingerTip.maxPrintRadius; iRowPair ++) {
                let startO = circleQuadO[iRowPair * 2];
                let endO = circleQuadO[iRowPair * 2 + 1];
                let startI = startO;
                if (startO !== -1) { // -1 means empty row
                    if (innerRadius !== 0) {
                        if (outerRadius === innerRadius) {
                            // same circle, so don't clip out overlap with self
                            startI = endO;
                        } else {
                            // go from start of outer to start of inner
                            startI = circleQuadI[iRowPair * 2];
                            if (startI === -1) { 
                                // no inside bound on that line, so go to middle
                                startI = FingerTip.maxPrintRadius - 1;
                            }
                        }
                    } else {
                        // inner radius is 0, so go to middle
                        startI = FingerTip.maxPrintRadius - 1;
                    }
                    // between the start outer and start inner inclusive
                    for(let iCol = startO; iCol <= startI; iCol ++) {
                        // let the caller translate from quad to actual
                        let tPair = translate(iCol, iRowPair);
                        let o = (tPair[1]) * FingerTip.cellsWidth + (tPair[0]);
                        // call the callback with these numbers
                        withAction(o, new Point2D(tPair[0], tPair[1]));
                    }

                }
            }
        }

        // fill each quadrant with the same shape
        fillQuad((iX:number, iY:number)=>{
            return [iX, iY];
        });
        fillQuad((iX:number, iY:number)=>{
            return [(FingerTip.maxPrintRadius * 2 -1) - iX , iY];
        });
        fillQuad((iX:number, iY:number)=>{
            return [iX, (FingerTip.maxPrintRadius * 2 -1) - iY];
        });
        fillQuad((iX:number, iY:number)=>{
            return [(FingerTip.maxPrintRadius * 2 -1) - iX, (FingerTip.maxPrintRadius * 2 - 1) - iY];
        });

    }

    static getConnectedCircle(outerRadius: number): Array<DoublyLinkedPointListNode> {

        let octantEntries = new Array<DoublyLinkedPointListNode>(8);
        // 0 1 2
        // 3 4 5
        // 6 7 8

        if (outerRadius === 1) {
            // special case for smallest circle
            let ll = new DoublyLinkedPointList();
            let x= FingerTip.maxPrintRadius - 1;
            let y= FingerTip.maxPrintRadius - 1;
            ll.addLast(new Point2D(x, y));
            const node0 = ll.tail!;
            ll.addLast(new Point2D(x+1, y));
            const node2 = ll.tail!;
            ll.addLast(new Point2D(x+1, y+1));
            const node8 = ll.tail!;
            ll.addLast(new Point2D(x, y+1));
            const node6 = ll.tail!;
            node6.next = node0;
            node0.prev = node6;
            return [node0, node0, node2, node6, node0, node2, node6, node8, node8];

        }

        // pattern for outside and inside edges
        let circleQuadO = FingerTip.circleQuads[outerRadius];
        let llBuildTop = new DoublyLinkedPointList();
        let llBuildBottom = new DoublyLinkedPointList();

        let iCenter = 0;
        let iFirst = -1;
        let iLast = -1;
        for(let iRowPair = 0; iRowPair < FingerTip.maxPrintRadius; iRowPair ++) {
            let startO = circleQuadO[iRowPair * 2];
            let endO = circleQuadO[iRowPair * 2 + 1];
            if (startO !== -1) { // -1 means empty row
                if (iFirst === -1) {
                    iFirst = iCenter;
                }
                iCenter += endO-startO+1;
                iLast = iCenter;
            }
        }
        iCenter = Math.floor(iCenter/2);

        // coordinates are reported for finger width grid, but size can go beyond that
        const traceQuad = (translate: (iX:number, iY:number)=>[x:number, y:number], 
                            addPoint: (pt: Point2D)=>DoublyLinkedPointListNode, 
                            results: (first: DoublyLinkedPointListNode, mid: DoublyLinkedPointListNode, last: DoublyLinkedPointListNode)=>void)=> {
            let firstNode: (DoublyLinkedPointListNode | undefined) = undefined;
            let lastNode: (DoublyLinkedPointListNode | undefined) = undefined;
            let centerNode: (DoublyLinkedPointListNode | undefined) = undefined;

            let cAdded = 0;
            for(let iRowPair = 0; iRowPair < FingerTip.maxPrintRadius; iRowPair ++) {
                let startO = circleQuadO[iRowPair * 2];
                let endO = circleQuadO[iRowPair * 2 + 1];
                if (startO !== -1) { // -1 means empty row
                    // these are flipped so trace from end to start
                    for(let iCol = endO; iCol >= startO; iCol --) {
                        // let the caller translate from quad to actual
                        let tPair = translate(iCol, iRowPair);

                        let pt = new Point2D(tPair[0], tPair[1]);
                        let node = addPoint(pt);
                        if (cAdded === iFirst) {
                            firstNode = node;
                        } else if (cAdded === iCenter) {
                            centerNode = node;
                        } 
                        cAdded++;
                        if (cAdded === iLast) {
                            lastNode = node;
                        }
                    }

                }
            }
            
            results(firstNode!, centerNode!, lastNode!);
        }

        // 0 1 2
        // 3 4 5
        // 6 7 8

        // fill each quadrant with the same shape
        let nodeLeftTop: DoublyLinkedPointListNode;
        let nodeLeftBottom: DoublyLinkedPointListNode;
        let nodeRightTop: DoublyLinkedPointListNode;
        let nodeRightBottom: DoublyLinkedPointListNode;

        traceQuad((iX:number, iY:number)=>{
            return [iX, iY];
        }, (pt: Point2D)=>{
            llBuildTop.addFirst(pt);
            return llBuildTop.head!;

        }, (first: DoublyLinkedPointListNode, mid: DoublyLinkedPointListNode, last: DoublyLinkedPointListNode)=>{
            octantEntries[3] = last;
            octantEntries[0] = mid;
            nodeLeftTop = last;
        } );

        traceQuad((iX:number, iY:number)=>{
            return [(FingerTip.maxPrintRadius * 2 -1) - iX , iY];
        }, (pt: Point2D)=>{
            llBuildTop.addLast(pt);
            return llBuildTop.tail!;

        }, (first: DoublyLinkedPointListNode, mid: DoublyLinkedPointListNode, last: DoublyLinkedPointListNode)=>{
            octantEntries[2] = mid;
            octantEntries[1] = first;
            nodeRightTop = last;
        } );

        traceQuad((iX:number, iY:number)=>{
            return [iX, (FingerTip.maxPrintRadius * 2 -1) - iY];
        }, (pt: Point2D)=>{
            llBuildBottom.addLast(pt);
            return llBuildBottom.tail!;

        }, (first: DoublyLinkedPointListNode, mid: DoublyLinkedPointListNode, last: DoublyLinkedPointListNode)=>{
            octantEntries[7] = first;
            octantEntries[6] = mid;
            nodeLeftBottom = last;
        } );

        traceQuad((iX:number, iY:number)=>{
            return [(FingerTip.maxPrintRadius * 2 -1) - iX, (FingerTip.maxPrintRadius * 2 - 1) - iY];
        }, (pt: Point2D)=>{
            llBuildBottom.addFirst(pt);
            return llBuildBottom.head!;

        }, (first: DoublyLinkedPointListNode, mid: DoublyLinkedPointListNode, last: DoublyLinkedPointListNode)=>{
            octantEntries[8] = mid;
            octantEntries[5] = last;
            nodeRightBottom = last;
        } );
        
        // stitch the results together in a loop.
        nodeLeftTop!.prev = nodeLeftBottom!;
        nodeLeftBottom!.next = nodeLeftTop!;
        nodeRightTop!.next = nodeRightBottom!;
        nodeRightBottom!.prev = nodeRightTop!;

        return octantEntries;

    }

    // the known shapes
    // map from radius to (map from octantMove to (map from octantFrom to print))
    // central shapes are dx,dy=0
    static shapes = new Map<string, FingerTip>();

    static getOrMakeShape(radius: number, octantMove: number, octantFrom: number, application: TipApplication, maker: (ft: FingerTip, octantMove: number, octantFrom: number)=>void) : FingerTip {

        let key = `r${radius}-m${octantMove}-f${octantFrom}-${application.instrument}-${application.motion}`;

        if (FingerTip.shapes.has(key)) {
            return FingerTip.shapes.get(key)!;
        } else {
            const ft = new FingerTip(radius, application);
            ft.key = key;
            maker(ft, octantMove, octantFrom);
            FingerTip.shapes.set(key, ft);
            return ft;
        }
    }

    static getCircleShape(radius: number) : FingerTip {
        return FingerTip.getOrMakeShape(radius, 4, 4, 
            {instrument: TipInstrument.finger, motion: TipMotion.press, action: TipAction.unknown, modifier: 0},
            (ft: FingerTip, octantMove: number, octantFrom: number)=>{
            ft.makeCircleShape();
        });
    }

    static getScrapeShape(radius: number, octantMove: number, octantFrom: number) : FingerTip {
        return FingerTip.getOrMakeShape(radius, octantMove, octantMove, 
            {instrument: TipInstrument.finger, motion: TipMotion.drag, action: TipAction.unknown, modifier: 0},
            (ft: FingerTip, octantMove: number, octantFrom: number)=>{
            ft.makeScrapeShape(octantMove);
        });
    }
    static getKnifeShape(radius: number, octantMove: number, octantFrom: number) : FingerTip {
        return FingerTip.getOrMakeShape(radius, octantMove, octantFrom, 
            {instrument: TipInstrument.knife, motion: TipMotion.unknown, action: TipAction.unknown, modifier: 0},
             (ft: FingerTip, octantMove: number, octantFrom: number)=>{
            ft.makeKnifeShape(octantMove, octantFrom);
        });
    }
    
    
    static smashRadiusOf(radius: number): number {
        if (radius < FingerTip.minFingerRadius) {
            return 0;
        }
        return Math.min(radius - 1, Math.round(radius * (radius-FingerTip.minFingerRadius) / (FingerTip.maxFingerRadius - FingerTip.minFingerRadius)));
    }

    application: TipApplication;
    radius: number;
    smashRadius: number;
    effectRadius: number;
    octFront: number;
    key: string = '';
    fCellsMeniscusScrape: Array<FlowCell>; 
    fCellsSmashScrape: Array<FlowCell>; 
    fCellsMeniscusBlend: Array<FlowCell>;
    fCellsCentralRings: Array<Array<FlowCell>>;
    fCellsKnifeEdge: Array<FlowCell> | undefined = undefined;
    fCellsKnifeBack: Array<FlowCell> | undefined = undefined;
    fCellMap: Map<number, Map<number, FlowCell>>;
    allFlowCells:Array<FlowCell>;
    allCellData: Uint8Array | undefined = undefined;
    cellDataRows: Array<CellDataRow> | undefined = undefined;
    tipKnifeBlade: FingerTip | undefined;
    minX: number = 0;
    minY: number = 0;
    maxX: number = 0;
    maxY: number = 0;

    constructor(radius: number, application: TipApplication) {
        this.radius = Math.max(Math.min(Math.floor(radius), FingerTip.maxFingerRadius), FingerTip.minFingerRadius);
        this.smashRadius = FingerTip.smashRadiusOf(this.radius)
        this.application = {instrument: application.instrument, action: application.action, motion: TipMotion.press, modifier: application.modifier};
        this.octFront = 4;
        this.effectRadius = this.radius + FingerTip.maxCoronaWidth;
        this.fCellsMeniscusScrape = new Array<FlowCell>(); 
        this.fCellsSmashScrape = new Array<FlowCell>(); 
        this.fCellsMeniscusBlend = new Array<FlowCell>(); 
        this.fCellsCentralRings = new Array<Array<FlowCell>>(); 
        this.allFlowCells = new Array<FlowCell>();
        this.fCellMap = new Map<number, Map<number, FlowCell>>();
    
    }

    public getFlowCell(x: number, y: number, newKind: CellKind, alterKind: CellKind) : FlowCell {
        // ensures that we only have one description for each point in the cell
        if (x >= FingerTip.cellsWidth || y >= FingerTip.cellsWidth || x < 0 || y < 0) {
            console.log('out of bounds');
            return undefined as any as FlowCell;
        }
        let col = this.fCellMap.get(x);
        if (col === undefined) {
            col = new Map<number, FlowCell>();
            this.fCellMap.set(x, col);
        }
        let cell = col.get(y);
        

        if (cell === undefined) {
            cell = {ptOnPrint:new Point2D(x, y)} as FlowCell;
            cell.finalKind = newKind;
            col.set(y, cell);
            this.allFlowCells.push(cell);
            FingerTip.setCellDepths(cell);
        } else if (cell.finalKind !== alterKind && alterKind !== CellKind.background) {
            cell.finalKind = alterKind
            FingerTip.setCellDepths(cell);
        }
        return cell;
    }

    public getSingleChangeFlowCell(pt: Point2D, depth: number, colorRGB: tinycolor.ColorFormats.RGB) : FlowCell {
        let fCellPage = this.getFlowCell(pt.x, pt.y, CellKind.background, CellKind.background);
        let fCellTip = {
            ptOnPrint: fCellPage.ptOnPrint,
            finalKind: fCellPage.finalKind,
            finalDepth: depth,
            stagedDepth: depth,
            modified: false,
            inBounds: fCellPage.inBounds === undefined ? true : fCellPage.inBounds,
            maxScrapeDepth: fCellPage.maxScrapeDepth,
            maxKindPushDepth: fCellPage.maxKindPushDepth,
            maxTouchDepth: fCellPage.maxTouchDepth,
            preferredScrapeDepth: fCellPage.preferredScrapeDepth,
            minScrapeDepth: fCellPage.minScrapeDepth,
            dvTipCells: new Uint8Array(11),
        } as FlowCell;


        fCellTip.dvTipCells[Page.offWetRed] = colorRGB.r;
        fCellTip.dvTipCells[Page.offWetGreen] = colorRGB.g;
        fCellTip.dvTipCells[Page.offWetBlue] = colorRGB.b;
        fCellTip.move1 = {fCellDest: fCellPage, fraction: 1} as FlowCellMove;
        
        return fCellTip;

    }

    private finalizeFlowCells() {
        // get the first row used and the height used also get range of columns used per row
        this.minY = FingerTip.cellsWidth;
        this.maxY = 0;
        this.minX = FingerTip.cellsWidth;
        this.maxX = 0;
        let colRangePerRow = new Map<number, [number, number]>();
        this.fCellMap.forEach((col, x)=>{
            this.minX = Math.min(this.minX, x);
            this.maxX = Math.max(this.maxX, x);
            col.forEach((cell, y)=>{
                this.minY = Math.min(this.minY, y);
                this.maxY = Math.max(this.maxY, y);
                let range = colRangePerRow.get(y);
                if (range === undefined) {
                    colRangePerRow.set(y, [x, x]);
                } else {
                    range[0] = Math.min(range[0], x);
                    range[1] = Math.max(range[1], x);
                }
            });
        });
        this.cellDataRows = new Array<CellDataRow>();
        let rowOffset = 0;
        for (let iRow = this.minY; iRow <= this.maxY; iRow ++) {
            let range = colRangePerRow.get(iRow)!;
            let row: CellDataRow = {
                row: iRow,
                firstCell: range[0],
                printRelativeStart: rowOffset,
                length: (range[1] - range[0] + 1) * 11,
            } as CellDataRow;
            this.cellDataRows.push(row);
            rowOffset += row.length;
        }
        this.allCellData = new Uint8Array(rowOffset);

        for (const fCell of this.allFlowCells) {
            let row = this.cellDataRows[fCell.ptOnPrint.y - this.minY];
            let col = fCell.ptOnPrint.x - row.firstCell;
            fCell.dvTipCells = new Uint8Array(this.allCellData.buffer, row.printRelativeStart + col * 11, 11);
        }
    }

    private makeCircleShape() {
        // flow goes from circle 1 to circle 2 etc, like a wave.
        // to keep it simple, each cell will feed into any adjacent next level
        // first run through all circle shapes and fill these helper grids with source descriptions
        // then run through the helper grids and wire adjacent ones together
        // the circle function gives coordinates relative to the tip with no corona, so need to adjust

        const sourceGrids = new Array<Array<undefined | FlowCell>>();
        for (let iStep = 1; iStep < this.radius+FingerTip.maxCoronaWidth; iStep ++) {
            // the helper to stash the results
            const sourceGrid = new Array<undefined | FlowCell>(FingerTip.cellsWidth * FingerTip.cellsWidth);
            sourceGrids.push(sourceGrid);
            const flowCells = new Array<FlowCell>();
            this.fCellsCentralRings.push(flowCells);

            // process all cells of the circle 
            FingerTip.doFilledCircle(iStep, iStep, (i: number, pt: Point2D)=>{
                // make a source at that point and put into the grid too (adjusting for other coord system)
                let finalKind = CellKind.background;
                if (iStep <= this.smashRadius) {
                    finalKind = CellKind.smash;
                } else if (iStep <= this.radius) {
                    finalKind = CellKind.meniscus;
                } else {
                    finalKind = CellKind.corona;
                }
                const src = this.getFlowCell(pt.x, pt.y, finalKind, finalKind);
                
                flowCells.push(src);
                sourceGrid[(pt.y) * FingerTip.cellsWidth + (pt.x)] = src;
            })
        }

        let octLooks = [[new Point2D(-1,1), new Point2D(-1,0), new Point2D(-1,-1), new Point2D(0, -1), new Point2D(1, -1)],
                        [new Point2D(-1,0), new Point2D(-1,-1), new Point2D(0,-1), new Point2D(1, -1), new Point2D(1, 0)],
                        [new Point2D(-1,-1), new Point2D(0,-1), new Point2D(1, -1), new Point2D(1, 0), new Point2D(1, 1)],
                        [new Point2D(0,1), new Point2D(-1,1), new Point2D(-1, 0), new Point2D(-1, -1), new Point2D(0, -1)],
                        [],
                        [new Point2D(0,-1), new Point2D(1,-1), new Point2D(1,0), new Point2D(1, 1), new Point2D(0, 1)],
                        [new Point2D(1,1), new Point2D(0,1), new Point2D(-1,1), new Point2D(-1, 0), new Point2D(-1, -1)],
                        [new Point2D(1,0), new Point2D(1,1), new Point2D(0,1), new Point2D(-1, 1), new Point2D(-1, 0)],
                        [new Point2D(1,-1), new Point2D(1,0), new Point2D(1,1), new Point2D(0, 1), new Point2D(-1, 1)]];

        const diag90Frac = .19;
        const diag45Frac = .205; 
        const diag0Frac = .21; 
        const direct90Frac = .21;
        const direct45Frac = .22;
        const direct0Frac = .12;
        let octFracs = [[diag90Frac, diag45Frac, diag0Frac, diag45Frac, diag90Frac],
                        [direct90Frac, direct45Frac, direct0Frac, direct45Frac, direct90Frac],
                        [diag90Frac, diag45Frac, diag0Frac, diag45Frac, diag90Frac],
                        [direct90Frac, direct45Frac, direct0Frac, direct45Frac, direct90Frac],
                        [],
                        [direct90Frac, direct45Frac, direct0Frac, direct45Frac, direct90Frac],
                        [diag90Frac, diag45Frac, diag0Frac, diag45Frac, diag90Frac],
                        [direct90Frac, direct45Frac, direct0Frac, direct45Frac, direct90Frac],
                        [diag90Frac, diag45Frac, diag0Frac, diag45Frac, diag90Frac]];


        for (let iStep = 0; iStep < this.radius+FingerTip.maxCoronaWidth - 2; iStep ++) {
            // the next one too
            const thisGrid = sourceGrids[iStep];
            const nextGrid = sourceGrids[iStep + 1];
            const nextNextGrid = sourceGrids[iStep + 2];
            // the equivalent list of just the sources added
            const ringCells = this.fCellsCentralRings[iStep];
            // for each of the sources, send things to the adjacent cells on the next level
            for (const fCell of ringCells) {
                let oct = fCell.ptOnPrint.moveOctantFrom(new Point2D(34.5,34.5));
                let sX = fCell.ptOnPrint.x;
                let sY = fCell.ptOnPrint.y;
                // look around, look around
                let look = octLooks[oct];
                let fracs = octFracs[oct];
                for (let iLook = 0; iLook < 5; iLook ++) {
                    let iLookX = look[iLook].x;
                    let iLookY = look[iLook].y;
                    // is there a source (target) at that spot
                    let lookIdx = (sY + iLookY) * FingerTip.cellsWidth + (sX + iLookX);
                    let targ = nextGrid[lookIdx];
                    let priority = 2;

                    if (targ === undefined && nextNextGrid !== undefined) {
                        // no target, so look at the next level
                        targ = nextNextGrid[lookIdx];
                    }
                    if (targ === undefined) {
                        // target what about this same level
                        targ = thisGrid[lookIdx];
                        priority = 1;
                    }
                    if (targ !== undefined) {
                        let move : FlowCellMove = {fCellDest:targ, priority:priority, fraction: fracs[iLook]} as FlowCellMove;
                        if (fCell.move1 === undefined) {
                            fCell.move1 = move;
                        }  else if (fCell.move2 === undefined) {
                            fCell.move2 = move;
                        } else if (fCell.move3 === undefined) {
                            fCell.move3 = move;
                        } else if (fCell.move4 === undefined) {
                            fCell.move4 = move;
                        } else if (fCell.move5 === undefined) {
                            fCell.move5 = move;
                        }
                    } 
                }
                // pri 1 moves happen first with set fraction, then pri 2 moves 
                // pri 2 move fractions must add up to 1

                let p2Frac = 0;
                if (fCell.move1 !== undefined && fCell.move1.priority === 2) {
                    p2Frac += fCell.move1.fraction;
                }
                if (fCell.move2 !== undefined && fCell.move2.priority === 2) {
                    p2Frac += fCell.move2.fraction;
                }
                if (fCell.move3 !== undefined && fCell.move3.priority === 2) {
                    p2Frac += fCell.move3.fraction;
                }
                if (fCell.move4 !== undefined && fCell.move4.priority === 2) {
                    p2Frac += fCell.move4.fraction;
                }
                if (fCell.move5 !== undefined && fCell.move5.priority === 2) {
                    p2Frac += fCell.move5.fraction;
                }

                // scale the p2 moves to add up to 1
                if (p2Frac < 1){
                    let scale = 1 / p2Frac;
                    if (fCell.move1 !== undefined && fCell.move1.priority === 2) {
                        fCell.move1.fraction *= scale;
                    }
                    if (fCell.move2 !== undefined && fCell.move2.priority === 2) {
                        fCell.move2.fraction *= scale;
                    }
                    if (fCell.move3 !== undefined && fCell.move3.priority === 2) {
                        fCell.move3.fraction *= scale;
                    }
                    if (fCell.move4 !== undefined && fCell.move4.priority === 2) {
                        fCell.move4.fraction *= scale;
                    }
                    if (fCell.move5 !== undefined && fCell.move5.priority === 2) {
                        fCell.move5.fraction *= scale;
                    }

                }



            }

        }
        this.finalizeFlowCells();
    }
    
    static tipLoc2TextureLoc(pt: Point2D, textWidth: number, textHeight:number) : number {
        const textureCorner = new Point2D(Math.floor((FingerTip.cellsWidth - textWidth) / 2), Math.floor((FingerTip.cellsWidth - textHeight) / 2));
        const ptOnTexture = new Point2D(pt.x - textureCorner.x, pt.y - textureCorner.y);
        return ptOnTexture.y * textWidth + ptOnTexture.x;
    }

    mapTextureLocation(loc: number, w: number, h: number) : number {
        let srcCx = this.effectRadius;
        let srcCy = this.effectRadius;
        let srcDx =  srcCx - (loc % (this.effectRadius * 2));
        let srcDy = srcCy - Math.floor(loc / (this.effectRadius * 2));
        let destX = w/2 - srcDx;
        let destY = h/2 - srcDy;
        return destY * w + destX;
    }


    static addCommitTexture(sourceCellTakes: Map<number, number>, srcFinger: FingerTip, w:number, h:number, cts: ChangeTextureSet, offset: number) {

        sourceCellTakes.forEach((frac, srcLoc)=>{
            srcLoc = srcFinger.mapTextureLocation(srcLoc, w, h);

            cts.texCommit![offset + srcLoc] = frac;
        });

    }

    private makeCircleChangeTextureIndexes(application: TipApplication) : ChangeTextureIndexes {
        let indexes = {
            application: application,
            width: FingerTip.cellsWidth,
            height: FingerTip.cellsWidth,
            idxsAction: new Array<number>(),
            idxMax: this.radius - FingerTip.minFingerRadius,
        } as ChangeTextureIndexes;
        let smashOnly = application.modifier === 1;

        for (let iRing = 0; iRing < this.fCellsCentralRings.length - 1; iRing ++) {
            if (smashOnly && iRing + 1 === this.smashRadius) {
                // the p2 pulls from that last ring into accumulator
                indexes.idxsAction.push(iRing * 2);
                indexes.idxsAction.push(-1); // signal to discard the accumulator and stop
                break;
            }
            // the p2 pulls from inner ring
            indexes.idxsAction.push(iRing * 2);

            // the p1 pulls from same ring
            indexes.idxsAction.push(iRing * 2 + 1);

        }
        return indexes;
       
    }

    static cachedCircleChangeTextureSet: ChangeTextureSet | undefined = undefined;

    static makeCircleChangeTextureSet(radius: number) {
        if (FingerTip.cachedCircleChangeTextureSet !== undefined) {
            return FingerTip.cachedCircleChangeTextureSet;
        }

        const entries = FingerTip.maxFingerRadius + FingerTip.maxCoronaWidth;
        let tipChangeTextures = {width: FingerTip.cellsWidth, height: FingerTip.cellsWidth, 
            maxEntries:FingerTip.maxFingerRadius - FingerTip.minFingerRadius + 1, moveEntries:entries*2} as ChangeTextureSet;

        tipChangeTextures.texMax = new Uint16Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.maxEntries);
        tipChangeTextures.texFrom = new Uint16Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.moveEntries * 4);
        tipChangeTextures.texFrac = new Float32Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.moveEntries * 4);
        tipChangeTextures.texCommit = new Float32Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.moveEntries);

        // the largest circle shape for the movement instructions
        const fatFinger = FingerTip.getCircleShape(FingerTip.maxFingerRadius);
        const wFat = FingerTip.cellsWidth;
        const hFat = wFat;

        tipChangeTextures.texMax.fill(Page.maxCoronaDepth);
        let fillFromMax = 0;
        let fillFromMov4 = 0;
        let fillFromMov1 = 0;

        // the first textures are the max depth textures for different circles. make one for each circle from min to max
        for (let radius = FingerTip.minFingerRadius; radius <= FingerTip.maxFingerRadius; radius ++) {
            // assume anything beyond the radius is corona
            let smashRadius = FingerTip.smashRadiusOf(radius);

            for (let iStep = 1; iStep < radius+FingerTip.maxCoronaWidth; iStep ++) {
                // process all cells of the circle 
                FingerTip.doFilledCircle(iStep, iStep, (i: number, pt: Point2D)=>{
                    let finalKind = CellKind.corona;
                    if (iStep <= smashRadius) {
                        finalKind = CellKind.smash;
                    } else if (iStep <= radius) {
                        finalKind = CellKind.meniscus;
                    } 
                    let tempCell = {ptOnPrint:pt, finalKind: finalKind} as FlowCell;
                    FingerTip.setCellDepths(tempCell);
                    let maxDepth = tempCell.maxTouchDepth;
                    if (finalKind === CellKind.smash) {
                        maxDepth = tempCell.preferredScrapeDepth;
                    }
                    tipChangeTextures.texMax![fillFromMax + FingerTip.tipLoc2TextureLoc(pt, wFat, hFat)] = maxDepth;
                })
            }
            fillFromMax += wFat * hFat;
        }

        // invert the direction of the move, we need to pull to destination cells from sources
        // per dest ring per dest cell location the list of [source cell location, priority, fraction]
        let inverted = new Array<Map<number, Array<[number, number, number]>>>(fatFinger.fCellsCentralRings.length + 1);
        let srcRing = 0;
        let destRing = 1;
        const addInverted = (srcLoc: number, move: FlowCellMove) => {
            let pri = move.priority;
            let destRingCell = destRing;
            if (pri === 1) {
                destRingCell = srcRing;
            }
            let textLocDest = FingerTip.tipLoc2TextureLoc(move.fCellDest.ptOnPrint, wFat, hFat);
            let destMap = inverted[destRingCell];
            if (destMap === undefined) {
                destMap = new Map<number, Array<[number, number, number]>>();
                inverted[destRingCell] = destMap;
            }
            let destList = destMap.get(textLocDest);
            if (destList === undefined) {
                destList = new Array<[number, number, number]>();
                destMap.set(textLocDest, destList);
            }
            destList.push([srcLoc, pri, move.fraction]);
        }

        for (const fCellsRing of fatFinger.fCellsCentralRings) {
            for (const fCell of fCellsRing) {
                let textLocSrc = FingerTip.tipLoc2TextureLoc(fCell.ptOnPrint, wFat, hFat);
                if (fCell.move1 !== undefined) {
                    addInverted(textLocSrc, fCell.move1);
                }
                if (fCell.move2 !== undefined) {
                    addInverted(textLocSrc, fCell.move2);
                }
                if (fCell.move3 !== undefined) {
                    addInverted(textLocSrc, fCell.move3);
                }
                if (fCell.move4 !== undefined) {
                    addInverted(textLocSrc, fCell.move4);
                }
                if (fCell.move5 !== undefined) {
                    addInverted(textLocSrc, fCell.move5);
                }
            }

            srcRing++;
            destRing++;
        }

        const sourceCellTakesP1 = new Map<number, number>();
        const sourceCellTakesP2 = new Map<number, number>();
        let ring = 0;
        fillFromMax = 0;
        fillFromMov1 = 0;
        fillFromMov4 = 0;
        for (const invertedMap of inverted) {
            //console.log('ring', ring);
            //const w = 2 * (ring + 1);
            const w = FingerTip.cellsWidth;
            const h = w;
            let texFromP1: Uint16Array | undefined = undefined;
            let texFracP1: Float32Array | undefined = undefined;
            let texFromP2: Uint16Array | undefined = undefined;
            let texFracP2: Float32Array | undefined = undefined;

            if (invertedMap !== undefined && invertedMap.size !== 0) {
                invertedMap.forEach((sources, destLoc)=>{
                    let p1 = 0;
                    let p2 = 0;
                    let texFromDest: Uint16Array | undefined = undefined;
                    let texFracDest: Float32Array | undefined = undefined;
                    for (const src of sources) {
                        let srcLoc = src[0];
                        let srcFrac = src[2];
                        let pos = 0;

                        if (src[1] === 1) {
                            let totalFrac = srcFrac;
                            if (sourceCellTakesP1.has(srcLoc)) {
                                totalFrac += sourceCellTakesP1.get(srcLoc)!;
                            }
                            sourceCellTakesP1.set(srcLoc, totalFrac);

                            if (p1 === 0 && texFromP1 === undefined) {
                                texFromP1 = new Uint16Array(w*h*4);
                                texFracP1 = new Float32Array(w*h*4);
                            }
                            texFromDest = texFromP1;
                            texFracDest = texFracP1;
                            pos = p1;
                            p1 ++;
                        } else {
                            let totalFrac = srcFrac;
                            if (sourceCellTakesP2.has(srcLoc)) {
                                totalFrac += sourceCellTakesP2.get(srcLoc)!;
                            }
                            sourceCellTakesP2.set(srcLoc, totalFrac);

                            if (p2 === 0 && texFromP2 === undefined) {
                                texFromP2 = new Uint16Array(w*h*4);
                                texFracP2 = new Float32Array(w*h*4);
                            }
                            texFromDest = texFromP2;
                            texFracDest = texFracP2;
                            pos = p2;
                            p2 ++;
                        }

                        // set the texture part
                        // convert from fat finger positions to texture positions
                        const srcTextureLoc = fatFinger.mapTextureLocation(srcLoc, w, h);
                        const destTextureLoc = fatFinger.mapTextureLocation(destLoc, w, h);
                        let offText = destTextureLoc * 4 + pos;
                        texFromDest![offText] = srcTextureLoc;
                        texFracDest![offText] = srcFrac;

                        //console.log(`at: ${wFat}x${hFat} ${}`);
                        //console.log(`dest ${destLoc%w},${Math.floor(destLoc/w)} src ${srcLoc%w},${Math.floor(srcLoc/w)} frac ${srcFrac} p ${src[1]}`);
                    }
                });
            }
            if (texFromP2 !== undefined) {
                tipChangeTextures.texFrom.set(texFromP2, fillFromMov4);
                tipChangeTextures.texFrac.set(texFracP2!, fillFromMov4);
                FingerTip.addCommitTexture(sourceCellTakesP2, fatFinger, w, h, tipChangeTextures, fillFromMov1);
                sourceCellTakesP2.clear();
                fillFromMov1 += w * h;
                fillFromMov4 += w * h * 4;

            } else {
                if (ring > 0) {
                    // need an empty texture and commit texture so indexes match
                    fillFromMov4 += w * h * 4;
                    fillFromMov1 += w * h;
                }
            }
            if (texFromP1 !== undefined) {
                tipChangeTextures.texFrom.set(texFromP1, fillFromMov4);
                tipChangeTextures.texFrac.set(texFracP1!, fillFromMov4);
                FingerTip.addCommitTexture(sourceCellTakesP1, fatFinger, w, h, tipChangeTextures, fillFromMov1);
                sourceCellTakesP1.clear();
                fillFromMov4 += w * h * 4;
                fillFromMov1 += w * h;

            }
            ring ++;

        }
        FingerTip.cachedCircleChangeTextureSet = tipChangeTextures;
        return tipChangeTextures;

    }

    static makeSmearChangeTextureSet(radius: number) {

        const entries =  8;
        const moveActions = 2;
        let tipChangeTextures = {width: FingerTip.cellsWidth, height: FingerTip.cellsWidth, 
            maxEntries:entries, moveEntries:entries * moveActions} as ChangeTextureSet;

        tipChangeTextures.texMax = new Uint16Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.maxEntries * 1);
        tipChangeTextures.texFrom = new Uint16Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.moveEntries * 4);
        tipChangeTextures.texFrac = new Float32Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.moveEntries * 4);
        tipChangeTextures.texCommit = new Float32Array(tipChangeTextures.width * tipChangeTextures.height * tipChangeTextures.moveEntries * 1);

        // the largest circle shape for the movement instructions
        const wFat = FingerTip.cellsWidth;
        const hFat = wFat;

        tipChangeTextures.texMax.fill(0);
        let fillFromMax = 0;
        let fillFromMov4 = 0;
        let fillFromMov1 = 0;

        for (let iOctMove = 0; iOctMove < 9; iOctMove++) {
            if (iOctMove === 4) {
                continue
            }
            let tip = FingerTip.getScrapeShape(radius, iOctMove, iOctMove);
            // the first textures are the max depth textures for different circles. make one for each circle from min to max
            for (const cell of tip.allFlowCells) {
                let maxDepth = cell.maxTouchDepth;
                tipChangeTextures.texMax![fillFromMax + FingerTip.tipLoc2TextureLoc(cell.ptOnPrint, wFat, hFat)] = maxDepth;
            }

            // the smash and meniscus cells have move1 set to the destination. we must pull, so map the destination to the source
            let inverted = new Map<FlowCell, FlowCell>();
            for (const cell of tip.allFlowCells) {
                if (cell.finalKind === CellKind.smash || cell.finalKind === CellKind.meniscus) {
                    let dest = cell.move1!.fCellDest;
                    inverted.set(dest, cell);
                }
            }

            // a gradient of pressure over surface aligned with the direction of movement
            let pressureMax = 10;
            let pressureMin = .5;
            let gradientStarts = -radius;
            let gradientEnds = radius;
            let gradientStep = (pressureMax - pressureMin) / (gradientEnds - gradientStarts);
            let ptCenter = new Point2D(34.5, 34.5);
            

            // first the meniscus move sources as a texture that fills accumulator with amount to take
            // so, not a list of moves
            for (const src of tip.fCellsMeniscusScrape.concat(tip.fCellsSmashScrape)) {
                let dest = src.move1!.fCellDest;
                let srcLoc = FingerTip.tipLoc2TextureLoc(src.ptOnPrint, wFat, hFat);
                tipChangeTextures.texFrac![fillFromMov4 + srcLoc * 4] = src.move1!.fraction;
                tipChangeTextures.texCommit![fillFromMov1 + srcLoc] = 1.0;
                // special info about the source is held in the other parts of the move texture
                tipChangeTextures.texFrom![fillFromMov4 + srcLoc * 4] = src.finalKind === dest.finalKind ? 1 : 0;
                tipChangeTextures.texFrom![fillFromMov4 + srcLoc * 4 + 1] = src.preferredScrapeDepth;
                tipChangeTextures.texFrom![fillFromMov4 + srcLoc * 4 + 2] = src.maxKindPushDepth;
                tipChangeTextures.texFrom![fillFromMov4 + srcLoc * 4 + 3] = src.minScrapeDepth;

                let pressureMax = src.preferredScrapeDepth;
                //let gradEffect = 1.0 + ptCenter.componentDistanceWithMoveOctantTo(src.ptOnPrint, iOctMove).dx * gradientStep;
                //pressureMax *= gradEffect;
                //pressureMax = Math.round(Math.max(3, pressureMax));
                let comps = ptCenter.componentDistanceWithMoveOctantTo(src.ptOnPrint, iOctMove);
                if (Math.abs(comps.dy) < radius/2 && radius - comps.dx < 6) {
                    pressureMax *= 2;

                }
                if (src.finalKind === CellKind.smash) {
                    pressureMax = pressureMax * 0.5;
                }


                tipChangeTextures.texMax![fillFromMax + srcLoc] = pressureMax;
            }
            fillFromMov4 += wFat * hFat * 4;
            fillFromMov1 += wFat * hFat;

            // then the destinations that pull from these sources
            inverted.forEach((src, dest)=>{
                if (src.finalKind === CellKind.meniscus || src.finalKind === CellKind.smash) {
                    let srcLoc = FingerTip.tipLoc2TextureLoc(src.ptOnPrint, wFat, hFat);
                    let destLoc = FingerTip.tipLoc2TextureLoc(dest.ptOnPrint, wFat, hFat);
                    tipChangeTextures.texFrom![fillFromMov4 + destLoc * 4] = srcLoc;
                    tipChangeTextures.texFrac![fillFromMov4 + destLoc * 4] = src.move1!.fraction;
                    tipChangeTextures.texCommit![fillFromMov1 + destLoc] = 1.0;
                }
            });
            fillFromMov4 += wFat * hFat * 4;
            fillFromMov1 += wFat * hFat;

            fillFromMax += wFat * hFat;
        }

        return tipChangeTextures;

    }

    private makeSmearChangeTextureIndexes(application: TipApplication)  : ChangeTextureIndexes {
        let octIdx = FingerTip.allOctantIndex[this.octFront];
        let tipIdx = octIdx;

        let indexes = {
            application: application,
            width: FingerTip.cellsWidth,
            height: FingerTip.cellsWidth,
            idxsAction: new Array<number>(),
            idxMax: tipIdx,
        } as ChangeTextureIndexes;

        const moveActions = 2; 
        for (let i = 0; i < moveActions; i++) {
            indexes.idxsAction.push(tipIdx * moveActions + i);
        }

        return indexes;
        
    }


    // move octants
    // 0 1 2
    // 3 4 5
    // 6 7 8
    // paint octants
    //   1 2
    // 0  4  5
    // 3  4  8
    //   6 7 
    static allOctants = new Set<number>([0,1,2,3,4,5,6,7,8]);
    static allOctantIndex = new Array<number>(0,1,2,3,-1,4,5,6,7);
    static moveOctantLeft = new Array<number>(3, 0, 1, 6, 4, 2, 7, 8, 5);
    static moveOctantRight = new Array<number>(1, 2, 5, 0, 4, 8, 3, 6, 7);
    static semi05Octants = new Set<number>([0,1,2,5]);
    static semi08Octants = new Set<number>([0,1,2,5,8]);
    static semi18Octants = new Set<number>([1,2,5,8]);
    static semi17Octants = new Set<number>([1,2,5,8,7]);
    static semi27Octants = new Set<number>([2,5,8,7]);
    static semi26Octants = new Set<number>([2,5,8,7,6]);
    static semi56Octants = new Set<number>([5,8,7,6]);
    static semi53Octants = new Set<number>([5,8,7,6,3]);
    static semi83Octants = new Set<number>([8,7,6,3]);
    static semi80Octants = new Set<number>([8,7,6,3,0]);
    static semi70Octants = new Set<number>([7,6,3,0]);
    static semi71Octants = new Set<number>([7,6,3,0,1]);
    static semi61Octants = new Set<number>([6,3,0,1]);
    static semi62Octants = new Set<number>([6,3,0,1,2]);
    static semi32Octants = new Set<number>([3,0,1,2]);
    static semi35Octants = new Set<number>([3,0,1,2,5]);
    static moveFromSmashOctants = [
        [FingerTip.semi32Octants,FingerTip.semi32Octants,FingerTip.semi05Octants,FingerTip.semi32Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi61Octants,FingerTip.allOctants,FingerTip.allOctants],
        [FingerTip.semi05Octants,FingerTip.semi05Octants,FingerTip.semi05Octants,FingerTip.semi32Octants,FingerTip.allOctants,FingerTip.semi18Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.allOctants],
        [FingerTip.semi05Octants,FingerTip.semi05Octants,FingerTip.semi18Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi18Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi27Octants],
        [FingerTip.semi61Octants,FingerTip.semi32Octants,FingerTip.allOctants,FingerTip.semi61Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi61Octants,FingerTip.semi70Octants,FingerTip.allOctants],
        [],
        [FingerTip.allOctants,FingerTip.semi18Octants,FingerTip.semi27Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi27Octants,FingerTip.allOctants,FingerTip.semi56Octants,FingerTip.semi27Octants],
        [FingerTip.semi61Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi70Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi70Octants,FingerTip.semi70Octants,FingerTip.semi83Octants],
        [FingerTip.allOctants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi70Octants,FingerTip.allOctants,FingerTip.semi56Octants,FingerTip.semi83Octants,FingerTip.semi83Octants,FingerTip.semi83Octants],
        [FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi27Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi56Octants,FingerTip.semi83Octants,FingerTip.semi56Octants,FingerTip.semi56Octants],
    ];
    
    static moveFromMeniscusOctants = [
        [FingerTip.semi32Octants,new Set<number>([0,1,2]),new Set<number>([1,2]),new Set<number>([3,0,1]),FingerTip.semi32Octants,new Set<number>([2]),new Set<number>([3,0]),new Set<number>([3]),FingerTip.semi32Octants],
        [new Set<number>([0,1,2]),FingerTip.semi05Octants,new Set<number>([1,2,5]),new Set<number>([0,1]),FingerTip.semi05Octants,new Set<number>([2,5]),new Set<number>([0]),FingerTip.semi05Octants,new Set<number>([5])],
        [new Set<number>([1,2]),new Set<number>([1,2,5]),FingerTip.semi18Octants,new Set<number>([1]),FingerTip.semi18Octants,new Set<number>([2,5,8]),FingerTip.semi18Octants,new Set<number>([8]),new Set<number>([5,8])],
        [new Set<number>([3,0,2]),new Set<number>([0,1]),new Set<number>([1]),FingerTip.semi61Octants,FingerTip.semi61Octants,FingerTip.semi61Octants,new Set<number>([6,3,0]),new Set<number>([6,3]),new Set<number>([6])],
        [],
        [new Set<number>([7]),new Set<number>([7,8,5]),new Set<number>([7,8,5]),FingerTip.semi27Octants,FingerTip.semi27Octants,FingerTip.semi27Octants,new Set<number>([2]),new Set<number>([2,5]),new Set<number>([2,5,8])],
        [new Set<number>([0,1]),new Set<number>([0]),FingerTip.semi70Octants,new Set<number>([6,3,0]),FingerTip.semi70Octants,new Set<number>([8]),FingerTip.semi70Octants,new Set<number>([3,6,7]),new Set<number>([6,7])],
        [new Set<number>([3]),FingerTip.semi83Octants,new Set<number>([8]),new Set<number>([6,3]),FingerTip.semi83Octants,new Set<number>([7,8]),new Set<number>([7,6,3]),FingerTip.semi83Octants,new Set<number>([8,7,6])],
        [FingerTip.semi56Octants,new Set<number>([2]),new Set<number>([8,5]),new Set<number>([6]),FingerTip.semi56Octants,new Set<number>([5,8,7]),new Set<number>([6,7]),new Set<number>([6,7,8]),FingerTip.semi56Octants],
    ];
    static moveFlowOctants = [
        [FingerTip.semi32Octants,FingerTip.semi62Octants,FingerTip.semi62Octants,FingerTip.semi62Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi35Octants,FingerTip.allOctants,FingerTip.allOctants],
        [FingerTip.semi35Octants,FingerTip.semi05Octants,FingerTip.semi35Octants,FingerTip.semi08Octants,FingerTip.allOctants,FingerTip.semi35Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.allOctants],
        [FingerTip.semi17Octants,FingerTip.semi08Octants,FingerTip.semi18Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi08Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi08Octants],
        [FingerTip.semi62Octants,FingerTip.semi61Octants,FingerTip.allOctants,FingerTip.semi61Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi71Octants,FingerTip.semi62Octants,FingerTip.allOctants],
        [],
        [FingerTip.allOctants,FingerTip.semi26Octants,FingerTip.semi17Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi27Octants,FingerTip.allOctants,FingerTip.semi17Octants,FingerTip.semi17Octants],
        [FingerTip.semi80Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi71Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi70Octants,FingerTip.semi80Octants,FingerTip.semi71Octants],
        [FingerTip.allOctants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi80Octants,FingerTip.allOctants,FingerTip.semi80Octants,FingerTip.semi80Octants,FingerTip.semi83Octants,FingerTip.semi53Octants],
        [FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi53Octants,FingerTip.allOctants,FingerTip.allOctants,FingerTip.semi53Octants,FingerTip.semi53Octants,FingerTip.semi53Octants,FingerTip.semi56Octants],
    ];
    static fromMoveScrapeOctants = [
        [new Set<number>([]),new Set<number>([3]),new Set<number>([3,0]),new Set<number>([2]),new Set<number>([]),new Set<number>([]),new Set<number>([1,2]),new Set<number>([]),new Set<number>([])],
        [new Set<number>([5]),new Set<number>([]),new Set<number>([0]),new Set<number>([2,5]),new Set<number>([]),new Set<number>([0,1]),new Set<number>([]),new Set<number>([]),new Set<number>([])],
        [new Set<number>([5,8]),new Set<number>([7]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([1]),new Set<number>([]),new Set<number>([]),new Set<number>([1,2])],
        [new Set<number>([6]),new Set<number>([6,3]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([1]),new Set<number>([0,1]),new Set<number>([])],

        [new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([])],

        [new Set<number>([]),new Set<number>([8,7]),new Set<number>([7]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([2,5]),new Set<number>([2])],
        [new Set<number>([7,6]),new Set<number>([]),new Set<number>([]),new Set<number>([7]),new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([0]),new Set<number>([3,0])],
        [new Set<number>([]),new Set<number>([]),new Set<number>([]),new Set<number>([8,7]),new Set<number>([]),new Set<number>([6,3]),new Set<number>([8]),new Set<number>([]),new Set<number>([3])],
        [new Set<number>([]),new Set<number>([]),new Set<number>([7,6]),new Set<number>([]),new Set<number>([]),new Set<number>([6]),new Set<number>([5,8]),new Set<number>([5]),new Set<number>([])]
    ];
    static moveFrontOctants = [FingerTip.semi32Octants,FingerTip.semi05Octants,FingerTip.semi18Octants,FingerTip.semi61Octants,FingerTip.allOctants,FingerTip.semi27Octants,FingerTip.semi70Octants,FingerTip.semi83Octants,FingerTip.semi56Octants];

    
    static moveOctantMidlineLookups = new Array<Map<number,Map<number, number>>>(9);
    static moveOctantMidlineParams = [[11,56,56,11,1,-1,Math.SQRT2],[3,33,66,33,1,0,1],[13,11,58,56,1,1,Math.SQRT2],[33,66,33,2,0,-1,1],[0,0],[36,3,36,66,0,1,1],[56,58,11,13,-1,-1,Math.SQRT2],[66,36,2,36,-1,0,1],[58,13,13,58,-1,1,Math.SQRT2]]; // where to start trace from just off center
    static getMoveOctantMidlineLookup(octant: number) : Map<number,Map<number, number>> {
        if (FingerTip.moveOctantMidlineLookups[octant] === undefined) {
            // make a set of all target points just after the midline, for diagonals there are two lines
            let targetPoints = new Map<number,Map<number, number>>();
            // pointed up move to the right
            const p= FingerTip.moveOctantMidlineParams[octant];
            const xStart1 = p[0];
            const yStart1 = p[1];
            const xEnd1 = p[2];
            const yEnd1 = p[3];
            const dx = p[4];
            const dy = p[5];
            const dI = p[6]
            let diag = dx !== 0 && dy !== 0;
            let offsetsDiag = Point2D.offsetsFromOctant(FingerTip.moveOctantRight[octant]);

            // one or two start and end x and y positions that span the whole print and trace the midline
            let xStart2 = xStart1 + offsetsDiag.x;
            let yStart2 = yStart1 + offsetsDiag.y;

            let x1 = xStart1;
            let y1 = yStart1;
            let x2 = xStart2;
            let y2 = yStart2;

            // do the trace, add the points to the set
            let idx1=0;
            let idx2=dI/2;
            while (!(x1 === xEnd1 && y1 === yEnd1)) {
                let ySet: Map<number, number>;
                if (targetPoints.has(x1)) {
                    ySet = targetPoints.get(x1)!;
                } else {
                    ySet = new Map<number, number>();
                    targetPoints.set(x1, ySet);
                }
                ySet.set(y1, Math.round(idx1));
                if (diag) {
                    if (targetPoints.has(x2)) {
                        ySet = targetPoints.get(x2)!;
                    } else {
                        ySet = new Map<number, number>();
                        targetPoints.set(x2, ySet);
                    }
                    ySet.set(y2, Math.round(idx2));
                }
                x1 += dx;
                y1 += dy;
                x2 += dx;
                y2 += dy;
                idx1+=dI;
                idx2+=dI;
            }
            FingerTip.moveOctantMidlineLookups[octant] = targetPoints;
        }
        return FingerTip.moveOctantMidlineLookups[octant];
    }


    private makeScrapeShape(octFront: number){

        this.octFront = octFront;
        let offsetsFront = Point2D.offsetsFromOctant(octFront);
        let octRight = FingerTip.moveOctantRight[FingerTip.moveOctantRight[octFront]];
        let octBack = FingerTip.moveOctantRight[FingerTip.moveOctantRight[octRight]];
        let octLeft = FingerTip.moveOctantRight[FingerTip.moveOctantRight[octBack]];

        let octL0 = octFront;
        let octR0 = FingerTip.moveOctantRight[octFront];
        let octL1 = FingerTip.moveOctantLeft[octL0];
        let octR1 = FingerTip.moveOctantRight[octR0];
        let octL2 = FingerTip.moveOctantLeft[octL1];
        let octR2 = FingerTip.moveOctantRight[octR1];
        let octL3 = FingerTip.moveOctantLeft[octL2];
        let octR3 = FingerTip.moveOctantRight[octR2];
        let ptCenter = new Point2D(FingerTip.cellsWidth/2, FingerTip.cellsWidth/2);
        

        // mark the meniscus area
        FingerTip.doFilledCircle(this.radius, this.smashRadius, (i: number, pt: Point2D)=>{
            let fCellSrc = this.getFlowCell(pt.x, pt.y, CellKind.meniscus, CellKind.meniscus);
        });
        
        // mark the the smash area
        if (this.smashRadius > 0) {
            FingerTip.doFilledCircle(this.smashRadius, 0, (i: number, pt: Point2D)=>{
                let fCellSrc = this.getFlowCell(pt.x, pt.y, CellKind.smash, CellKind.smash);
            });
        }

        // set the scrapes
        FingerTip.doFilledCircle(this.radius, this.smashRadius, (i: number, pt: Point2D)=>{
            let fCellSrc = this.getFlowCell(pt.x, pt.y, CellKind.meniscus, CellKind.meniscus);
            fCellSrc.move1 = {
                fCellDest:this.getFlowCell(fCellSrc.ptOnPrint.x + offsetsFront.x, fCellSrc.ptOnPrint.y + offsetsFront.y,
                        CellKind.corona, CellKind.background), fraction:1} as FlowCellMove;
            
        });
        if (this.smashRadius > 0) {
            FingerTip.doFilledCircle(this.smashRadius, 0, (i: number, pt: Point2D)=>{
                let fCellSrc = this.getFlowCell(pt.x, pt.y, CellKind.smash, CellKind.smash);
                fCellSrc.move1 = {
                    fCellDest:this.getFlowCell(fCellSrc.ptOnPrint.x + offsetsFront.x, fCellSrc.ptOnPrint.y + offsetsFront.y,
                            CellKind.corona, CellKind.background), fraction:1} as FlowCellMove;
                });
        }

        this.fCellMap.forEach((v,k) => v.forEach(fCell => {
            if (fCell.finalKind === CellKind.meniscus) {
                this.fCellsMeniscusScrape.push(fCell);
            } else if (fCell.finalKind === CellKind.smash) {
                this.fCellsSmashScrape.push(fCell);
            } else {
                return;
            }
            let pt = fCell.ptOnPrint;
            let o = pt.octantFrom(ptCenter);
            let offsetsFill : Point2D;
            let offsetsTrace : Point2D;
            if (o === octL0 || o === octL1 || o === octL2 || o === octL3) {
                offsetsFill = Point2D.offsetsFromOctant(octRight);
                offsetsTrace = Point2D.offsetsFromOctant(octLeft);
            } else {
                offsetsFill = Point2D.offsetsFromOctant(octLeft);
                offsetsTrace = Point2D.offsetsFromOctant(octRight);
            }
            fCell.move2 = {fCellDest:this.getFlowCell(pt.x + offsetsFill.x, pt.y + offsetsFill.y,
                        CellKind.corona, CellKind.background), fraction:1} as FlowCellMove;
            fCell.move3 = {fCellDest:this.getFlowCell(pt.x + offsetsTrace.x, pt.y + offsetsTrace.y,
                        CellKind.corona, CellKind.background), fraction:1} as FlowCellMove;


        } ));
        
        if (this.smashRadius >=  2) {
            // smooth the front
            let rCirc = FingerTip.getConnectedCircle(this.smashRadius+1);
            let clockBackFirst = rCirc[octRight]!;
            let counterBackFirst = rCirc[octLeft]!.prev!;
            let clockFrontFirst = counterBackFirst.prev!;
            let clockFrontLast = clockBackFirst.next!;

            let traceArc = clockFrontFirst;
            let processedLastArc = false;
            while (processedLastArc === false) {
                let fCell = this.getFlowCell(traceArc.value.x, traceArc.value.y, CellKind.background, CellKind.background);
                let tracePrev = traceArc.prev!;
                let fCellPrev = this.getFlowCell(tracePrev.value.x, tracePrev.value.y, CellKind.background, CellKind.background);
                let traceNext = traceArc.next!;
                let fCellNext = this.getFlowCell(traceNext.value.x, traceNext.value.y, CellKind.background, CellKind.background);
                fCell.move4 = {fCellDest:fCellPrev, fraction:1} as FlowCellMove;
                fCell.move5 = {fCellDest:fCellNext, fraction:1} as FlowCellMove;
                this.fCellsMeniscusBlend.push(fCell);

                if (traceNext === undefined || traceNext === clockFrontLast) {
                    processedLastArc = true;
                }

                traceArc = traceNext!
            }
            
            // make the scrape channels
            rCirc = FingerTip.getConnectedCircle(this.smashRadius);
            clockBackFirst = rCirc[octRight]!;
            counterBackFirst = rCirc[octLeft]!.prev!;

            let traceArcFirst = clockBackFirst;
            let traceArcLast = counterBackFirst;

            traceArc = traceArcFirst;
            processedLastArc = false;
            while (processedLastArc === false) {
                let fCellTraceArc = this.getFlowCell(traceArc.value.x, traceArc.value.y, CellKind.background, CellKind.background);
                let traceNext = traceArc.next!;
                if (traceNext === undefined || traceNext === traceArcLast) {
                    processedLastArc = true;
                }
                let d = Math.abs(FingerTip.cellsWidth/2 - traceArc.value.x) - .5;

                let fCellTraceChannel = fCellTraceArc;
                let processedLastChannel = false;
                let channelMax = d < 1 ? FingerTip.preferredSmashScrapeDepth: fCellTraceChannel.preferredScrapeDepth;
                if (channelMax < 2) {
                    channelMax = 2;
                }
                //channelMax = w;
                while (processedLastChannel === false) {
                    let fCellNextChannel =  fCellTraceChannel.move1 === undefined ? undefined : fCellTraceChannel.move1!.fCellDest;
                    if (fCellNextChannel === undefined || fCellNextChannel.finalKind !== CellKind.smash) {
                        processedLastChannel = true;
                    }
                    fCellTraceChannel.preferredScrapeDepth = channelMax;
                    fCellTraceChannel.maxScrapeDepth = channelMax + 1;
                    fCellTraceChannel = fCellNextChannel!
                }
                traceArc = traceNext!
            }
        }

        // now places for overflow corona to go
        for (let r = this.radius + 1; r < this.radius + FingerTip.maxCoronaWidth; r++) {
            let rCirc = FingerTip.getConnectedCircle(r);
            let clockFrontFirst = rCirc[octFront]!;

            let trace = clockFrontFirst;
            do {
                let fCell = this.getFlowCell(trace.value.x, trace.value.y, CellKind.corona, CellKind.background);
                let o = fCell.ptOnPrint.octantFrom(ptCenter);
                let offsetsFront = Point2D.offsetsFromOctant(o);
                let fCellFront = this.getFlowCell(fCell.ptOnPrint.x + offsetsFront.x, fCell.ptOnPrint.y + offsetsFront.y, CellKind.corona, CellKind.background);
                let offsetsRight = Point2D.offsetsFromOctant(FingerTip.moveOctantRight[o]);
                let fCellRight = this.getFlowCell(fCell.ptOnPrint.x + offsetsRight.x, fCell.ptOnPrint.y + offsetsRight.y, CellKind.corona, CellKind.background);
                let offsetsLeft = Point2D.offsetsFromOctant(FingerTip.moveOctantRight[o]);
                let fCellLeft = this.getFlowCell(fCell.ptOnPrint.x + offsetsLeft.x, fCell.ptOnPrint.y + offsetsLeft.y, CellKind.corona, CellKind.background);
                fCell.move1 = {fCellDest:fCellFront, fraction:.5} as FlowCellMove;
                fCell.move4 = {fCellDest:fCellRight, fraction:.25} as FlowCellMove;
                fCell.move5 = {fCellDest:fCellLeft, fraction:.25} as FlowCellMove;


                trace = trace.next!;
            } while (trace !== clockFrontFirst);
        }
        this.finalizeFlowCells();

    }

    private makeKnifeShape(octantMove: number, octantFrom: number){
        const filledScrapeOctant = FingerTip.fromMoveScrapeOctants[octantFrom][octantMove];

        // knife has two straight line fingertips that share the same locations
        // on regular fingertip is bound to the page and it has the job of lifting paint up and placing it
        // onto the other fingertip, this one is bound to the 'blade' buffer that is like a 1D page to hold paint
        // the blade tip moves paint back onto the page when there is a gap or edge in the pattern
        // patterns are set at drag time based on the paint volume selected, so customization must happen later
        // it goes like this: regular tip moves paint off the page onto the blade. the blade tip smooths out accumulated paint on the 
        // knife. then it deposits back onto the page in the gaps.
        // scrape tip uses move1 to lift paint to blade cells. blade cells use move2 and 3 for smoothing and 1 for depositing
        // scrape cells with move1 NOT set are the gaps or edges. they get paint from the blade cells
        // blade cells with move1 NOT set are the smooth areas.

        this.fCellsKnifeBack = new Array<FlowCell>();
        this.fCellsKnifeEdge = new Array<FlowCell>();
        this.tipKnifeBlade = new FingerTip(this.radius, this.application);
        this.tipKnifeBlade.fCellsKnifeEdge = new Array<FlowCell>();


        let offsetsMove = Point2D.offsetsFromOctant(octantMove);
        let ptCenter = new Point2D(FingerTip.tipCenter.x + offsetsMove.x, FingerTip.tipCenter.y + offsetsMove.y);

        let backX = offsetsMove.x * -1;
        let backY = offsetsMove.y * -1;
        // points just behind the knife since knife clears behind
        let midlinePoints = FingerTip.getMoveOctantMidlineLookup(FingerTip.moveOctantRight[FingerTip.moveOctantRight[FingerTip.moveOctantRight[FingerTip.moveOctantRight[octantMove]]]]);

        const makeScrapeCell = (pt: Point2D, edge: boolean)=>{
            let fCellSrc = this.getFlowCell(pt.x, pt.y, CellKind.knife, CellKind.knife);
            if (edge) {
                this.fCellsKnifeEdge!.push(fCellSrc);
                let fCellDst = this.tipKnifeBlade!.getFlowCell(pt.x, pt.y, CellKind.knife, CellKind.knife);
                fCellSrc.move1 = {fCellDest:fCellDst, fraction:1} as FlowCellMove;
            } else {
                this.fCellsKnifeBack!.push(fCellSrc);
                let fCellDst = this.getSingleChangeFlowCell(pt, 0, {r:1, g:1, b:1});
                fCellSrc.move1 = {fCellDest:fCellDst, fraction:1} as FlowCellMove;
            }
        }

        // make the blade cells first so we can chain them together for smoothing
        let fCellBlade = new Array<FlowCell>();
        let fCellBladeFront = new Array<FlowCell>();

        midlinePoints.forEach((ySet, x) => {
            ySet.forEach((i, y) => {
                let pt = new Point2D(x, y);
                let ptFront = new Point2D(pt.x + offsetsMove.x, pt.y + offsetsMove.y);
                let dPt = ptFront.distanceTo(ptCenter);

                if (dPt <= this.radius) {
                    let fCell = this.tipKnifeBlade!.getFlowCell(pt.x, pt.y, CellKind.knife, CellKind.knife);
                    fCellBlade.push(fCell);
                    this.tipKnifeBlade!.fCellsKnifeEdge!.push(fCell);
                    fCell = this.tipKnifeBlade!.getFlowCell(ptFront.x, ptFront.y, CellKind.knife, CellKind.knife);
                    fCellBladeFront.push(fCell);
                    this.tipKnifeBlade!.fCellsKnifeEdge!.push(fCell);
                } 
            })
        });

        const chainBladeCells = (fCells: Array<FlowCell>)=>{
            for (let i = 2; i < fCells.length-1; i++) {
                let fCell = fCells[i];
                let fCellNext = fCells[i+1];
                let fCellPrev = fCells[i-1];
                fCell.move2 = {fCellDest:fCellPrev, fraction:1} as FlowCellMove;
                fCell.move3 = {fCellDest:fCellNext, fraction:1} as FlowCellMove;
            }
        }
        chainBladeCells(fCellBlade);
        chainBladeCells(fCellBladeFront);


        midlinePoints.forEach((ySet, x) => {
            ySet.forEach((i, y) => {
                let pt = new Point2D(x, y);
                let octPt = pt.octantFrom(ptCenter);
                let ptFront = new Point2D(pt.x + offsetsMove.x, pt.y + offsetsMove.y);
                let dPt = ptFront.distanceTo(ptCenter);

                if (dPt <= this.radius) {
                    makeScrapeCell(pt, true);
                    makeScrapeCell(ptFront, true);
                } 
               
                pt = new Point2D(pt.x + backX, pt.y + backY);
                while((filledScrapeOctant.has(octPt)) && pt.distanceTo(ptCenter) <= this.radius) {
                    makeScrapeCell(pt, false);
                    pt = new Point2D(pt.x + backX, pt.y + backY);
                    octPt = pt.octantFrom(ptCenter);
                }

            })
        });

        this.finalizeFlowCells();
        this.tipKnifeBlade!.finalizeFlowCells();
        //console.log(s);

    }
    customizeKnifePattern(paintRadius : number, octMove: number) {
        // tooth gap is based on the specific depth
        // paint radius goes from 5 to 30.
        // we want a flat blade with the outer edges flowing over,
        // a fine comb that is regular in shape, at the end of this is a H shaped knife that is 1/3 open in the middle
        let toothStart = 0;
        let toothWidth = 0;
        let gapWidth = 0;
        let doBack = false;
        if (paintRadius === FingerTip.maxFingerRadius) {
            toothStart = 10;
            toothWidth = 19;
            gapWidth = 10;
        } else {
            let divs = (FingerTip.maxFingerRadius - FingerTip.minFingerRadius) / 5;
            paintRadius = paintRadius - FingerTip.minFingerRadius;
            if (paintRadius < divs) {
                toothStart = 0;
                toothWidth = this.radius - 1;
                gapWidth = 10;
                doBack = true;
            } else if (paintRadius < 2 * divs) {
                toothStart = 0;
                toothWidth = 1;
                gapWidth = 1;
            } else if (paintRadius < 3 * divs) {
                toothStart = 1;
                toothWidth = 2;
                gapWidth = 2;
            } else if (paintRadius < 4 * divs) {
                toothStart = 3;
                toothWidth = 6;
                gapWidth = 6;
            }
            else {
                toothStart = 6;
                toothWidth = 12;
                gapWidth = 12;
            }
        }
        let pattern = new Array<boolean>();
        let teethLeft = toothWidth;
        let gapsLeft = gapWidth;
        for (let i = 0; i < this.radius; i++) {
            if (i < toothStart) {
                pattern.push(false);
            } else {
                if (teethLeft > 0) {
                    pattern.push(true);
                    teethLeft--;
                } else {
                    if (gapsLeft > 0) {
                        pattern.push(false);
                        gapsLeft--;
                    } else {
                        pattern.push(true);
                        teethLeft = toothWidth - 1;
                        gapsLeft = gapWidth;
                    }
                }
            }
        }

        let offsetsMove = Point2D.offsetsFromOctant(octMove);
        // expand the space we use to measure distance so that the two lines centered on the middle actually seem to be
        // the midline without any jaggy funny business. all so we can index into the pattern array
        let offsetsExpand = Point2D.offsetsFromOctant(FingerTip.moveOctantLeft[FingerTip.moveOctantLeft[octMove]]);
        let expandX = offsetsExpand.x * 100;
        let expandY = offsetsExpand.y * 100;

        let ptCenter = new Point2D((FingerTip.tipCenter.x + offsetsMove.x), (FingerTip.tipCenter.y + offsetsMove.y));
        let ptCenterExpand = new Point2D(ptCenter.x + ptCenter.x * expandX, ptCenter.y + ptCenter.y * expandY);
        for (const fCell of this.fCellsKnifeEdge!) {
            let fCellBlade = this.tipKnifeBlade!.getFlowCell(fCell.ptOnPrint.x, fCell.ptOnPrint.y, CellKind.knife, CellKind.knife)!;
            let ptCellExpand = new Point2D(fCell.ptOnPrint.x + fCell.ptOnPrint.x * expandX, fCell.ptOnPrint.y + fCell.ptOnPrint.y * expandY);
            let dPt = Math.floor(ptCellExpand.distanceTo(ptCenterExpand)/100);
            let isScrape = pattern[dPt];
            if (isScrape) {
                fCell.move1 = {fCellDest:fCellBlade, fraction:1} as FlowCellMove;
                fCellBlade.move1 = {fraction: 0} as FlowCellMove;
            } else {
                fCell.move1 = {fraction: 0} as FlowCellMove;
                fCellBlade.move1 = {fCellDest:fCell, fraction:1} as FlowCellMove;
            }
        }
        for (const fCell of this.fCellsKnifeBack!) {
            fCell.move1.fraction = doBack ? 1 : 0;
        }
    }
    private makeKnifeChangeTextureIndexes(application: TipApplication) : ChangeTextureIndexes {
        return {} as ChangeTextureIndexes;

        
    }


    public getChangeTextureIndexes(application: TipApplication) : ChangeTextureIndexes {
        if (application.instrument === TipInstrument.finger && application.motion === TipMotion.drag) {
            return this.makeSmearChangeTextureIndexes(application);
        } else if (application.instrument === TipInstrument.knife) {
            return this.makeKnifeChangeTextureIndexes(application);
        } else if (application.instrument === TipInstrument.finger && (application.motion === TipMotion.press || application.motion === TipMotion.lift)) {
            return this.makeCircleChangeTextureIndexes(application);
        } else if (application.instrument === TipInstrument.blow) {
        }
        return {} as ChangeTextureIndexes;
    }
}

export default FingerTip;