import React, { Component } from 'react';
import { App } from '../App'
import FingerTip from './smash/fingertip';
import Toggle from 'react-toggle'
import { Link, createSearchParams } from 'react-router-dom';
import Smessage from './smessage';
import { STrace, SmesshyCommon, SmesshyCommonProps, SmesshyCommonState } from '../smesshyCommon';
import { ArtistProfile, PaintingInfo, SmesshyUserPreferences } from '../storageManager';
import { UserPreferences } from 'typescript';
import { SmesshyMainButton, SmesshySubButton } from './smesshyButton';
import GamePage from './gamePage';
import Env from '../envInst';
import { IconSelect, IconSelected, ScaledIcon } from './iconButton';
import SettingsPage from './settingsPage';

export interface SetupPageState extends SmesshyCommonState {

    showAuth: boolean;
    accountName: string;
    notificationsSetup: boolean;
    notificationsPossible: boolean;
    cookiesSetup: boolean;
    notifications: Map<string, boolean>;
    installSignaled: boolean;
    calibrated: boolean;

}
export interface SetupPageProps extends SmesshyCommonProps {
    InApp: boolean;
}

class SetupPage extends SmesshyCommon(Component<SetupPageProps, SetupPageState>) {

    listRef : React.RefObject<HTMLTextAreaElement> | null = null;

    constructor(props: SetupPageProps) {
        super(props);
        try {
            this.initCommon(props.AppObject);

            this.listRef = React.createRef();

            this.state = {
                showAuth: false,
                authenticated: false,
                accountName: 'new user',
                installSignaled: false,
                showWaitSpin: false,
                cookiesSetup: false,
                notificationsSetup: false,
                notificationsPossible: false,
                notifications: new Map<string, boolean>(),
                calibrated: false,
            };
        } catch (e: any) {
            props.AppObject.reportException(`Setup, constructor`, 'ex', '', e)
        }
    }

    componentDidMount() {
        STrace.addStep('setup', 'didMound', '');

        let env = Env;
        const controlThis = this;

        if (env.inPWA && env.setupComplete() === true) {
            this.setState({navigateTo: {To:{pathname:'/'}}})
        }

        env.afterInstall = async () => {
            controlThis.setState({installSignaled: true});
        }

        let cookiesSetup = env.setupCookies();
        let notificationsPossible = false;
        let notificationsSetup = env.setupNotifications();
        if (notificationsSetup === false) {
            notificationsPossible = this._app!.notificationsPossible();
        }

        const calibratedTo = this.GetAppState('calibrationVersion', false) as number;
        const calibrated = !(Number.isNaN(calibratedTo) || calibratedTo < App.calibrationVersion) 

        this.setState({cookiesSetup: cookiesSetup, notificationsSetup: notificationsSetup, notificationsPossible: notificationsPossible, calibrated: calibrated}); 
    }

    async onPopulateAuthenticationState(authenticated: boolean) {
        STrace.addStep('setup', 'populateAuth', authenticated.toString());
        try {
            let accountName = 'whodis? new phone';
            let notifications = new Map<string, boolean>();
            let notificationsSetup = this.state.notificationsSetup;
            if (authenticated) {
                const user = await this.getUser();
                if (user !== null && user !== undefined && user.profile !== null && user.profile !== undefined ) {
                    
                    accountName = user.profile.preferred_username!;
                    STrace.addStep('setup', 'getUserArtistProfileAsync', '');
                    const prof = await this.storageManager!.getUserArtistProfileAsync();
                    if (prof !== null && prof !== undefined) {
                        if (prof.notifications !== undefined) {
                            notifications = SettingsPage.stringToNotificationMap(prof.notifications);
                        }
                    }

                    if (this.state.notificationsPossible === true) {
                        notificationsSetup = (await this._app!.getNotificationSubscription()) !== null;
                    }
                }
            }
            this.setState({showAuth:true, authenticated: authenticated, accountName: accountName, notifications: notifications});
        } catch (e: any) {
            this.props.AppObject.reportException(`Setup, check authentication`, 'ex', '', e)
        }

    }

    render() {
        try {
            return this.babyRender();
        } catch (e: any) {
            this.props.AppObject.reportException(`setup, render`, 'ex', '', e)
            return <div>?!?!</div>;
        }
    }
    babyRender() {
        let controlThis = this;
        let env = Env;


        const termsAndPrivacy = <>
            <SmesshySubButton AppObject={this._app!}
                Display={<span className='h-padding-large text-small'>Terms</span>}
                Haptic = {this.doButtonHaptic()}
                StartPushedState = {false}
                OnPressFinish={async (): Promise<boolean>=>{
                    controlThis.setState({ navigateTo: {To:{pathname:'/termsOfServiceApp'}}});
                    return true;
                }}
            />
            <SmesshySubButton AppObject={this._app!}
                Display={<span className='h-padding-large text-small'>Privacy</span>}
                Haptic = {this.doButtonHaptic()}
                StartPushedState = {false}
                OnPressFinish={async (): Promise<boolean>=>{
                    controlThis.setState({ navigateTo: {To:{pathname:'/privPolApp'}}});
                    return true;
                }}
            />
        </>;

        const cookieAsk = <div className='v-items'>
            <div>{`Smesshy uses a bare minimum set of browser cookies to help us remember that you are you.`}</div>
            <div>{`It REALLY can't work without them.`}</div>
            <SmesshyMainButton AppObject={this._app!}
                    Display={<span>I Accept these minimal cookies</span>}
                    Haptic = {this.doButtonHaptic()}
                    StartPushedState = {false}
                    OnPressFinish={async (): Promise<boolean>=>{
                        env.finishCookiesSetup();
                        controlThis.setState({ cookiesSetup: true});
                        return true;
                    }}
            />
        </div>;

        const partingWishes = <div className='v-items v-medium-gap'>
            <div>{`That's it, done. Some thing you may want to do next:`}</div>
            <li className='text-small h-padding-large'>{`Go to the settings page and review notification details and other choices.`}</li>
            <li className='text-small h-padding-large'>{`Paint a self portrait, then go to the settings page and make it your avatar.`}</li>
            <li className='text-small h-padding-large'>{`Check out the current competition, maybe enter it or vote on it!`}</li>
            <li className='text-small h-padding-large'>{`Go to your feed and see what's good. Find and follow some pals`}</li>
            <li className='text-small h-padding-large'>{`Paint a masterpiece and hide it in a pal's lunchbox. (direct messages)`}</li>
            <SmesshyMainButton AppObject={this._app!}
                    Display={<span>Take me to Smesshy</span>}
                    Haptic = {this.doButtonHaptic()}
                    StartPushedState = {false}
                    OnPressFinish={async (): Promise<boolean>=>{
                        env.finishSetup();
                        controlThis.setState({navigateTo: {To:{pathname:'/'}}});
                        return true;
                    }}
            />
        </div>;

        const iconHeight = this._app!.GetScaledPxHeight(31)
        let welcome = <></>;
        let finishedSteps = new Array<string>();
        let currentStep = 'none';
        let currentContent = <></>;
        let remainingSteps = new Array<string>();
        
        remainingSteps.push('Install Smessy as a Web App');
        remainingSteps.push('Continue from the Web App');
        remainingSteps.push('Accept the bare minimum cookies');
        remainingSteps.push('Make your profile');
        remainingSteps.push('How do you feel about notifications?');
        remainingSteps.push('Measure your fingertip and touch screen');

        if (!this.props.InApp) {
            welcome = <div className='h-padding-large text-large'>{`Hello there, we'll step you through this:`}</div>;
            currentStep = remainingSteps.shift()!;
                        
            let explain1 ='';
            let explain2 ='';
            let explain3 ='';
            let explain4 ='';
            let promptButton = <></>;
            let helpImage1 = <></>;
            let helpImage2 = <></>;
    
            // not inApp means we might need to install the app and certainly need to switch over to it
            // env.installed = 'unknown';
            // env.browserName = 'Other';
            // env.deferredInstallPrompt = undefined;
            // env.deviceName = 'Other';

            let installed = env.installed;
            let dip = env.deferredInstallPrompt;

            if (installed === 'unknown' || installed === 'false') {
                if (dip !== undefined) {
                    // got a prompt, so we can install
                    promptButton = <SmesshyMainButton AppObject={this._app!}
                                        Display={<span>OK, let's do that now!</span>}
                                        Haptic={this.doButtonHaptic()} 
                                        StartPushedState={false}
                                        ExtraStyleClass=''
                                        OnClick={async ()=>{
                                            try {
                                                const result = await (dip as any).prompt();
                                            } catch (e: any){
                                                console.error(e);
                                                controlThis.setState({doRefresh:true});
                                            }

                                        }}/>;

                } else {
                    // don't know and no prompt was sent
                    // why don't we know? maybe wrong browser?
                    if (env.deviceName === 'Other') {
                        // assume desktop, only edge or chrome can install
                        if (env.browserName === 'Chrome') {
                            explain1 = `Using the Chrome browser on a desktop/laptop computer.`;
                            explain2 = `If you already installed Smesshy as a browser app, you will see the 'switch' icon in the Chrome address bar.`;
                            helpImage1 = <img src='/chrome-switch-desktop.png' alt='Chrome switch icon' style={{objectFit:'cover'}}/>;
                            explain3 = `If not, you need to install Smesshy by clicking on the 'install' icon in the Chrome address bar.`;
                            helpImage2 = <img src='/chrome-install-desktop.png' alt='Chrome install icon' style={{objectFit:'cover'}}/>;
                            explain4 = `Either way, click one of those icons to get started.`;
                        } else if (env.browserName === 'Edge') {
                            explain1 = `Using the Edge browser on a desktop/laptop computer.`;
                            explain2 = `If you already installed Smesshy as a browser app, you will see the 'switch' icon in the Edge address bar.`;
                            helpImage1 = <img src='/edge-switch-desktop.png' alt='Edge switch icon' style={{objectFit:'cover'}}/>;
                            explain3 = `If not, you need to install Smesshy by clicking on the 'install' icon in the Edge address bar.`;
                            helpImage2 = <img src='/edge-install-desktop.png' alt='Edge install icon' style={{objectFit:'cover'}}/>;
                            explain4 = `Either way, click one of those icons to get started.`;
                        } else {
                            explain1 = `It seem that you are using a desktop/laptop browser.`;
                            explain2 = `Only the Chrome or Edge browsers can install Smesshy as an app onto desktops. Please try one of those.`;
                        }

                    } else if (env.deviceName === 'iOS') {
                        if (env.browserName !== 'Safari') {
                            explain1 = `On iOs, you can only install Smesshy as a browser app using the Safari browser.`;
                            explain2 = `Run this thing, and point it at https://smesshy.com`;
                            helpImage1 = <img src='/safari-icon-ios.png' alt='Safari on iOs icon' style={{objectFit:'cover'}}/>;
                            explain3 = `See you in a minute from Safari...`;

                        } else {
                            explain1 = `On iOS, you can install Smesshy as a browser app.`;
                            explain2 = `Tap the Action button (often called the Share button, often at the bottom of the screen.).`;
                            helpImage1 = <img src='/safari-action-ios.png' alt='Safari action icon' style={{objectFit:'cover'}}/>;
                            explain3 = `Scroll down the share sheet past the rows of contacts and apps, then select 'Add to Home Screen'`;
                            helpImage2 = <img src='/safari-add-to-home-ios.png' alt='Safari add to home icon' style={{objectFit:'cover'}}/>;
                            explain4 = `Open Smesshy from that fancy new Home Screen App icon.`;
                        }
                    } else if (env.deviceName === 'Android') {
                        if (env.browserName === 'Chrome') {
                            explain1 = `While using Chrome on Android, you can install Smesshy as a browser app.`;
                            explain2 = `Tap the three dots in the upper right corner.`;
                            helpImage1 = <img src='/chrome-dots-android.png' alt='Chrome dots icon' style={{objectFit:'cover'}}/>;
                            explain3 = `Select 'Install app'`;
                            helpImage2 = <img src='/chrome-install-android.png' alt='Chrome install icon' style={{objectFit:'cover'}}/>;
                            explain4 = `Open Smesshy from that fancy new Home Screen App icon.`;
                        } else if (env.browserName === 'Firefox') {
                            explain1 = `While using Firefox on Android, you can install Smesshy as a browser app.`;
                            explain2 = `Tap the three dots in the upper right corner.`;
                            helpImage1 = <img src='/firefox-dots-android.png' alt='Firfox dots icon' style={{objectFit:'cover'}}/>;
                            explain3 = `Select either 'Add to Home screen' or 'Install'`;
                            helpImage2 = <img src='/firefox-install-android.png' alt='Firfox install icon' style={{objectFit:'cover'}}/>;
                            explain4 = `Open Smesshy from that fancy new Home Screen App icon.`;

                        } else {
                            explain1 = `On Android, you can install Smesshy as a browser app.`;
                            explain2 = `But ... Try using the Chrome browser.`;
                        }
                    }
                }

            } else if (installed === 'true') {
                // just need to swap to it, didn't happen automatically from OS
                explain1 = `Good news! Smesshy is already installed as a browser app.`;
                explain2 = `So, go find the Smesshy app icon on your home screen and start from there.`;
                explain3 = `See you on the other side ...`;
            }
            currentContent = <div className='v-items v-medium-gap height-100p'>
                    <div className=''>
                        {explain1}
                    </div>
                    <div className=''>
                        {explain2}
                    </div>
                    <div className='self-cross-center'>
                        {promptButton}
                    </div>
                    <div className='self-cross-center'>
                        {helpImage1}
                    </div>
                    <div className=''>
                        {explain3}
                    </div>
                    <div className='self-cross-center'>
                        {helpImage2}
                    </div>
                    <div className=''>
                        {explain4}
                    </div>

                </div>
        } else {
            welcome = <div className='h-padding-large text-large'>{`Hello there, let's continue setting up Smeshhy!`}</div>;
            finishedSteps.push(remainingSteps.shift()!);
            finishedSteps.push(remainingSteps.shift()!);
            currentStep = remainingSteps.shift()!;

            // inApp means app is installed and running, so take over setup with account/auth/notifications/calibration
            if (this.state.showAuth === false) {
                currentContent = <div>{`Looking for you ...`}</div>
            } else {
                if (this.state.authenticated === false) {
                    if (this.state.cookiesSetup === false) {
                        currentContent = cookieAsk;
                    } else {
                        finishedSteps.push(currentStep);
                        currentStep = remainingSteps.shift()!;
            
                        currentContent = <div className='v-items v-gap-medium v-padding-small h-padding-large'>
                            <SmesshyMainButton AppObject={this._app!}
                                Display={
                                    <div className='v-items'>
                                        <span>Push this button to:</span>
                                        <li>Create a new Smesshy account*</li>
                                        <li>Sign-in with an existing one.</li>
                                        <br/>
                                        <span className='text-small'>* We get it, you're like 'sigh... another App specific account, yuck.' But trust us, this one is worth it because it's ... you know ... Smesshy!</span>
                                    </div>}
                                Haptic = {this.doButtonHaptic()}
                                StartPushedState = {false}
                                OnPressFinish={async (): Promise<boolean>=>{
                                    try {
                                        controlThis.setState({ navigateTo: controlThis.getAuthenticationNavigate('/setup', 'login')});
                                    } catch (e: any) {
                                        this.props.AppObject.reportException(`Setup,sign in`, 'ex', '', e)
                                    }
                                    return true;
                                }}
                            />
                        </div>
                    }
                } else {
                    welcome = <div className='h-padding-large text-large'>{`Hello there ${this.state.accountName}, let's continue setting up Smesshy!`}</div>

                    if (this.state.cookiesSetup === false) {
                        currentContent = cookieAsk;
                    } else {
                        finishedSteps.push(currentStep);
                        currentStep = remainingSteps.shift()!;
                        finishedSteps.push(currentStep);
                        currentStep = remainingSteps.shift()!;

                        if (this.state.notificationsSetup === false) {

                            let notiAsk = <div className='v-items v-medium-gap'>
                                <div>{`Shall we try to turn on notifications?`}</div>
                                <div className='h-items h-large-gap width-100p h-padding-large '>
                                    <SmesshySubButton AppObject={this._app!}
                                            Display={<span>Sounds Good!</span>}
                                            Haptic = {this.doButtonHaptic()}
                                            StartPushedState = {false}
                                            Disabled={this.state.notificationsPossible === false}
                                            OnPressFinish={async (): Promise<boolean>=>{
                                                let allowed = await controlThis._app!.getNotificationPermission(true);

                                                if (allowed) {
                                                    controlThis.pushWaitingFrame(controlThis);
                                                    const sub = await controlThis._app!.getNotificationSubscription();
                                                    if (sub === null) {
                                                        STrace.addStep('setup', 'createNotificationRegistration', '');
                                                        if (await controlThis._app!.createNotificationRegistration() === true) {
                                                            controlThis.state.notifications.set('any', true);
                                                            STrace.addStep('setup', 'putUserPreferencesAsync', 'any true');
                                                            await controlThis.storageManager!.putUserPreferencesAsync({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                                        }
                                                    } else {
                                                        controlThis.state.notifications.set('any', true);
                                                        STrace.addStep('setup', 'putUserPreferencesAsync', 'any true');
                                                        await this.storageManager!.putUserPreferencesAsync({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});
                                                    }
                                                    controlThis.popWaitingFrame();

                                                }
                                                env.finishNotificationsSetup();
                                                controlThis.setState({ notificationsSetup: true});
                                                return true;
                                            }}
                                    />
                                    <SmesshySubButton AppObject={this._app!}
                                        Display={<span>No Thanks</span>}
                                        Haptic = {this.doButtonHaptic()}
                                        StartPushedState = {false}
                                        OnPressFinish={async ()=>{
                                            controlThis.pushWaitingFrame(controlThis);
                                            controlThis.state.notifications.set('any', false);
                                            STrace.addStep('setup', 'putUserPreferencesAsync', 'any false');
                                            await this.storageManager!.putUserPreferencesAsync({notifications: SettingsPage.notificationMapToString(controlThis.state.notifications)});

                                            env.finishNotificationsSetup();
                                            controlThis.setState({ notificationsSetup: true});
                                            controlThis.popWaitingFrame();
                                            return true;
                                        }}
                                    />
                                </div>
                            </div>

                            let iOSNote = <></>;
                            if (env.getDeviceName() === 'iOS') {
                                if (this.state.notificationsPossible === false) {
                                    iOSNote = <span className='text-small'>{`Notifications look impossible. If you are using iOS 16.4 or higher, please report this as a problem`}</span>
                                } else {
                                    iOSNote = <span className='text-small'>{`On iOS you can get notifications if you turn them on here but you might also need to turn them on in the system settings.
                                                                        Go to Settings > Smesshy > Notifications and turn on 'Allow Notifications'`} </span>
                                }
                            }
                            currentContent = <div className='v-items v-medium-gap'>
                                <div>{`You can get notifications from Smesshy when`}</div>
                                <div className='h-padding-large'>
                                    <li>{`Someone favors your painting`}</li>
                                    <li>{`A friend sends you a picture`}</li>
                                    <li>{`A new challenges starts`}</li>
                                    <li>{`etc.`}</li>
                                </div>
                                {notiAsk}
                                {iOSNote}
                            </div>;
                        } else {
                            finishedSteps.push(currentStep);
                            currentStep = remainingSteps.shift()!;
                            if (this.state.calibrated === false) {
                                currentContent = <div className='h-items h-large-gap'>
                                    <SmesshyMainButton AppObject={this._app!}
                                            Display={<span>Calibrate my fingertip</span>}
                                            Haptic = {this.doButtonHaptic()}
                                            StartPushedState = {false}
                                            OnPressFinish={async (): Promise<boolean>=>{
                                                controlThis.setState({navigateTo: {To:{pathname:'/calibrate'}}});
                                                return true;
                                            }}
                                    />
                                    <SmesshyMainButton AppObject={this._app!}
                                            Display={<span className='text-small'>I will do this later.</span>}
                                            Haptic = {this.doButtonHaptic()}
                                            StartPushedState = {false}
                                            OnPressFinish={async (): Promise<boolean>=>{
                                                controlThis.setState({calibrated: true});
                                                return true;
                                            }}
                                    />

                                </div>;

                            } else {
                                finishedSteps.push(currentStep);
                                currentStep = remainingSteps.shift()!;
                                currentContent = partingWishes;
                            }
                        }
                    }
                }
            }
        }

        return this.renderFramework(
            <GamePage
                AppObject={this._app!}
                AppShape={this._appShape!}
                ShowFooter={true}
                ShowHeader={true}
                ShowRefresh={true}
                RequireAuth={false}
                OnPopulateAuthenticationState={this._inPWA ? async (authenticated: boolean) => { await controlThis.onPopulateAuthenticationState(authenticated) } : undefined}
                Title = {<span>Smesshy - Setup</span>}
                CloseNav = {env.inPWA ? undefined : -1}
                Body={
                    <div className='game-page-mid v-items text-medium text-black' >
                        {welcome}
                        {finishedSteps.map((step, index) => {
                            return <div key={index} className='h-items h-medium-gap h-padding-large'>{ScaledIcon(IconSelected(undefined), iconHeight)}<span>{step}</span></div>;
                        })
                        }
                        <div className='smesshy-group v-items v-medium-gap'>
                            <div className='h-items h-medium-gap h-padding-medium text-large'>{ScaledIcon(IconSelect(undefined), iconHeight)}<span>{currentStep}</span></div>
                            {currentContent}
                        </div>
                        {remainingSteps.map((step, index) => {
                            return <div key={index + 10} className='h-items h-medium-gap h-padding-large'>{ScaledIcon(IconSelect(undefined), iconHeight)}<span>{step}</span></div>;
                        })
                        }
                        <div className='h-items h-medium-gap width-100p v-padding-large group-center'>
                            {termsAndPrivacy}
                        </div>
                        
                    </div>
                }
            />, this.state
        );
    }
}

export default SetupPage;